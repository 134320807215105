import { useReactiveVar } from '@apollo/client';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { GrClose } from 'react-icons/gr';
import {
  ITableSetupUpdate,
  TABLE_SETUP_UPDATE,
} from '../../../../common/api/mutations/tableSetup_mutation';
import {
  IBetLimitResponse,
  LIMITS_GET_QUERY,
  USER_TLIMITS_GET_QUERY,
} from '../../../../common/api/queries/betlimit_query';
import {
  isUpdatedVar,
  tBetLimitList,
  tableData,
  uTBetLimitList,
} from '../../../../common/apollo';
import { ButtonCustom } from '../../../../components/button';
import { useTranslation } from 'react-i18next';

interface IModalProps {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TableSetupModal: React.FC<IModalProps> = ({ setModal }) => {
  const {
    register,
    getValues,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm<ITableSetupUpdate>({ mode: 'onChange' });
  const { isSubmitting } = useFormState({ control });

  const { t } = useTranslation(['page']);
  const selectedTableData = useReactiveVar(tableData);

  const isUpdated = useReactiveVar(isUpdatedVar);
  const tLimitData = useReactiveVar(tBetLimitList);
  const userTLimitData = useReactiveVar(uTBetLimitList);

  const [selectedTableType, setSelectedTableType] = useState(
    selectedTableData?.type
  );
  const [limitType, setLimitType] = useState(selectedTableData?.limit_type);
  // const [ulimitId, setUlimitID] = useState(selectedTableData?.ulimit_id);
  const [limitId, setLimitId] = useState(selectedTableData?.limit_id);
  const [uTData, setUTData] = useState<IBetLimitResponse[]>([]);
  const [data, setData] = useState<(IBetLimitResponse | undefined)[]>([]);

  const handleOnClickLimitType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLimitType(e.currentTarget.value);
  };

  const clickModalClose = () => {
    setModal((current) => !current);
  };

  const handleOnClickLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLimitId(e.currentTarget.value);
  };
  // const handleOnClickUTLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setUlimitID(e.currentTarget.value);
  // };

  // const getLimitList = () => {
  // 	LIMITS_GET_QUERY().then((res: AxiosResponse | any) => {
  // 		if (res.data.data[0].code === 1) {
  // 			setData(res.data.data[0].data.list);
  // 		}
  // 	});
  // };

  // const getULimitList = () => {
  // 	USER_TLIMITS_GET_QUERY().then((res: AxiosResponse | any) => {
  // 		if (res.data.data[0].code === 1) {
  // 			setUTData(res.data.data[0].data.list);
  // 		}
  // 	});
  // };

  const getLimitList = () => {
    LIMITS_GET_QUERY()
      .then((res: AxiosResponse | any) => {
        if (res.data.data[0].code === 1) {
          tBetLimitList(res.data.data[0].data.list);
          const fetchedTLimitData: IBetLimitResponse[] =
            res.data.data[0].data.list;
          return fetchedTLimitData;
        }
      })
      .then((fetchedTLimitData) => {
        if (fetchedTLimitData && fetchedTLimitData.length > 0) {
          USER_TLIMITS_GET_QUERY()
            .then((res: AxiosResponse | any) => {
              if (res.data.data[0].code === 1) {
                uTBetLimitList(res.data.data[0].data.list);
                const fetchedUTLimitData: IBetLimitResponse[] =
                  res.data.data[0].data.list;
                return { fetchedUTLimitData, fetchedTLimitData };
              }
            })
            .then((fetchedData) => {
              if (
                fetchedData?.fetchedUTLimitData &&
                fetchedData?.fetchedUTLimitData.length > 0
              ) {
                const processedTBetLimistList =
                  fetchedData.fetchedTLimitData.map((item) => {
                    for (let elem of fetchedData.fetchedUTLimitData) {
                      if (elem.id === item.id) {
                        item.ut_bp_min = elem.bp_min;
                        item.ut_pair_min = elem.pair_min;
                        item.ut_tie_min = elem.tie_min;
                        return item;
                      }
                    }
                  });
                if (processedTBetLimistList.length > 0) {
                  setData(processedTBetLimistList);
                }
              }
            });
        }
      });
  };

  const columnHelper = createColumnHelper<IBetLimitResponse | undefined>();
  const columns = [
    columnHelper.accessor('name', {
      header: String(t('Name')),
    }),
    columnHelper.accessor('bp_min', {
      header: 'BP(min/Max)',
    }),
    columnHelper.accessor('tie_min', {
      header: 'TIE(min/Max)',
    }),
    columnHelper.accessor('pair_min', {
      header: 'PAIR(min/Max)',
    }),
  ];

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const uTable = useReactTable({
    data: uTData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const tableType = [
    <option key='P' value='P'>
      {t('PhoneBet')}
    </option>,
    <option key='S' value='S'>
      {t('Speed')}
    </option>,
  ];

  const tableSubType = [
    <option key='PB' value='PB'>
      {t('PhoneBet')}
    </option>,
    <option key='SB' value='SB'>
      {t('Speed')}
    </option>,
    <option key='IB' value='IB'>
      {t('Insurance Bet')}
    </option>,
    <option key='SS' value='SS'>
      {t('Super Speed')}
    </option>,
  ];

  const tableStatus = [
    <option key='C' value='C'>
      {t('Closed')}
    </option>,
    <option key='O' value='O'>
      {t('Open')}
    </option>,
    <option key='H' value='H'>
      {t('Hide')}
    </option>,
    <option key='M' value='M'>
      {t('Maintenance')}
    </option>,
  ];

  const onSubmit = () => {
    const {
      table_code,
      type,
      sub_type,
      stat,
      timer,
      free_range1,
      free_range2,
      free_range_yn,
      bal_pbet,
      bal_bbet,
      robot_bet_min,
      rolling_cut,
      need_confirm,
    } = getValues();

    const data = {
      id: String(selectedTableData?.id),
      category_code: String(selectedTableData?.category_code),
      table_code: table_code,
      type: type,
      sub_type: sub_type,
      stat: stat,
      limit_id: limitId,
      limit_type: limitType,
      timer: timer,
      free_range1: free_range1,
      free_range2: free_range2,
      free_range_yn: free_range_yn,
      bal_pbet: bal_pbet,
      bal_bbet: bal_bbet,
      robot_bet_min: robot_bet_min,
      rolling_cut: rolling_cut,
      need_confirm: need_confirm,
    };

    let isValid = true;

    if (
      selectedTableData?.category_code === '' ||
      table_code === '' ||
      type === '' ||
      sub_type === '' ||
      stat === '' ||
      limitType === '' ||
      limitId === ''
    ) {
      isValid = false;
    }

    if (!isValid) {
      alert(t('Required Fields Are Not Filled In.'));
    } else {
      TABLE_SETUP_UPDATE(data)
        .then((res: AxiosResponse | any) => {
          if (res.data.data.code === 1) {
            alert(t('Registration Success'));
            isUpdatedVar(!isUpdated);
          } else {
            alert(t('Registration Failed'));
            setModal(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getLimitList();
  }, []);

  return (
    <div className='box-border fixed top-0 right-0 bottom-0 left-0 z-40 overflow-auto outline-0'>
      <div className='fixed w-full h-full backdrop-blur-sm'>
        <div className='box-border relative shadow-lg m-24 bg-white top-12'>
          <div>
            <div className='border rounded-md lg:col-span-2'>
              <div className='font-medium text-lg px-6 py-3 border-b flex flex-row justify-between'>
                <div className='text-blue-500'>{t('Table Setting')}</div>
                <GrClose
                  className='mt-1 cursor-pointer'
                  onClick={clickModalClose}
                />
              </div>
              <div className='p-6 rounded-lg shadow-md bg-white'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input
                    type='text'
                    name='username'
                    className='w-0 h-0 border-0 block'
                  />
                  <div className='grid grid-cols-2 gap-4'>
                    <div className='col-span-2'>
                      <div className='grid grid-cols-2 gap-4'>
                        <div className='form-group mb-3'>
                          <div className='flex flex-row'>
                            <div className='mb-2 text-gray-500'>
                              {t('Junket Name')}
                            </div>
                          </div>
                          <input
                            pattern='^[a-zA-Z0-9]*$'
                            type='text'
                            className='forminput bg-gray-100'
                            disabled
                            defaultValue={selectedTableData?.category_code}
                          />
                        </div>
                        <div className='form-group mb-3'>
                          <div className='flex flex-row'>
                            <div className='mb-2 text-gray-500'>
                              {t('Table Number')}
                            </div>
                          </div>
                          <input
                            {...register('table_code')}
                            type='text'
                            className='forminput'
                            defaultValue={selectedTableData?.table_code}
                          />
                        </div>
                      </div>
                      <div className='grid grid-cols-2 gap-4'>
                        <div className='form-group mb-3'>
                          <div className='flex flex-row'>
                            <div className='mb-2 text-gray-500'>
                              {t('Table Type')}
                            </div>
                          </div>
                          <div className='flex gap-2'>
                            <select
                              {...register('type')}
                              className='forminput'
                              defaultValue={selectedTableData?.type}
                              onChange={(event) => {
                                const selTableType = event.target.value;
                                if (selTableType === 'P') {
                                  setValue('sub_type', 'PB');
                                } else {
                                  setValue('sub_type', 'SB');
                                }
                                setValue('type', selTableType);
                                setSelectedTableType(selTableType);
                              }}
                            >
                              {tableType}
                            </select>

                            <select
                              {...register('sub_type')}
                              className='forminput'
                              defaultValue={selectedTableData?.sub_type}
                            >
                              {tableSubType.filter((elem) => {
                                if (selectedTableType === 'P') {
                                  return elem.key !== 'SB' && elem.key !== 'SS';
                                } else if (selectedTableType === 'S') {
                                  return elem.key !== 'PB' && elem.key !== 'IB';
                                } else {
                                  return elem;
                                }
                              })}
                            </select>

                            <div className='form-group flex items-center'>
                              <p className='whitespace-nowrap'>
                                {t('Need Confirm')}
                              </p>
                              <input
                                {...register('need_confirm')}
                                type='checkbox'
                                className='mx-2'
                                defaultChecked={selectedTableData?.need_confirm}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='grid grid-cols-3 gap-4'>
                          <div className='form-group mb-3'>
                            <div className='flex flex-row'>
                              <div className='mb-2 text-gray-500'>
                                {t('Table State')}
                              </div>
                            </div>
                            <select
                              {...register('stat')}
                              className='forminput'
                              defaultValue={selectedTableData?.stat}
                            >
                              <option value={selectedTableData?.stat}>
                                {selectedTableData?.stat === 'C'
                                  ? t('Closed')
                                  : selectedTableData?.stat === 'O'
                                  ? t('Open')
                                  : selectedTableData?.stat === 'H'
                                  ? t('Hide')
                                  : t('Maintenance')}
                              </option>
                              {tableStatus.filter((elem) => {
                                return elem.key !== selectedTableData?.stat;
                              })}
                            </select>
                          </div>
                          <div className='form-group'>
                            <div className='mb-2 text-gray-500'>
                              {t('Robot Bet')}
                            </div>
                            <div className='flex flex-row'>
                              <input
                                {...register('robot_bet_min')}
                                type='number'
                                className='forminput'
                                required
                                defaultValue={selectedTableData?.robot_bet_min}
                              />
                            </div>
                          </div>
                          <div className='form-group'>
                            <div className='mb-2 text-gray-500'>
                              {t('Rolling Cut')}
                            </div>
                            <div className='flex flex-row'>
                              <input
                                {...register('rolling_cut')}
                                type='number'
                                className='forminput'
                                required
                                defaultValue={selectedTableData?.rolling_cut}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='grid grid-cols-2 gap-4'>
                        <div className='grid grid-cols-3 gap-4'>
                          <div className='form-group mb-3'>
                            <div className='text-gray-500'>
                              {t('Limit Type')}
                            </div>
                            <label className='flex flex-row gap-2'>
                              <input
                                type='radio'
                                name='limitTypeCheckbox'
                                value='T'
                                checked={
                                  selectedTableData?.limit_type === 'T'
                                    ? true
                                    : undefined
                                }
                                onChange={(e) => handleOnClickLimitType(e)}
                              />
                              <div className='text-base'>{t('Pair Sum')}</div>
                            </label>
                            <label className='flex flex-row gap-2'>
                              <input
                                type='radio'
                                name='limitTypeCheckbox'
                                value='E'
                                checked={
                                  selectedTableData?.limit_type === 'E'
                                    ? true
                                    : undefined
                                }
                                onChange={(e) => handleOnClickLimitType(e)}
                              />
                              <div className='text-base'>
                                {t('Pair Separately')}
                              </div>
                            </label>
                          </div>
                          <div className='form-group'>
                            <div className='mb-2 text-gray-500'>
                              {t('SuperSpeed Player Bet')}
                            </div>
                            <div className='flex flex-row'>
                              <input
                                {...register('bal_pbet')}
                                type='number'
                                className='forminput'
                                required
                                defaultValue={selectedTableData?.bal_pbet}
                              />
                            </div>
                          </div>
                          <div className='form-group'>
                            <div className='mb-2 text-gray-500'>
                              {t('SuperSpeed Banker Bet')}
                            </div>
                            <div className='flex flex-row'>
                              <input
                                {...register('bal_bbet')}
                                type='number'
                                className='forminput'
                                required
                                defaultValue={selectedTableData?.bal_bbet}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='flex gap-4'>
                          <div className='form-group'>
                            <div className='mb-2 text-gray-500'>
                              {t('Timer')}
                            </div>
                            <div className='flex flex-row'>
                              <input
                                {...register('timer')}
                                type='number'
                                className='forminput'
                                required
                                defaultValue={selectedTableData?.timer}
                              />
                              <div className='text-base font-semibold m-2'>
                                초
                              </div>
                            </div>
                          </div>
                          <div className='form-group'>
                            <div className='mb-2 text-gray-500'>
                              {t('Free Range 1')}
                            </div>
                            <div className='flex flex-row'>
                              <input
                                {...register('free_range1')}
                                type='number'
                                className='forminput'
                                required
                                defaultValue={selectedTableData?.free_range1}
                              />
                            </div>
                          </div>
                          <div className='form-group'>
                            <div className='mb-2 text-gray-500'>
                              {t('Free Range 2')}
                            </div>
                            <div className='flex flex-row'>
                              <input
                                {...register('free_range2')}
                                type='number'
                                className='forminput'
                                required
                                defaultValue={selectedTableData?.free_range2}
                              />
                              <input
                                {...register('free_range_yn')}
                                type='checkbox'
                                className='mx-2'
                                defaultChecked={
                                  selectedTableData?.free_range_yn
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className=''>
											<div className='form-group mb-6'>
												<div className='flex flex-row'>
													<div className='mb-2 text-gray-500'>
														{t('Table Limit')}
													</div>
												</div>
												<div className='ml-2 mt-1 pr-3 h-52 overflow-auto'>
													<table className='w-full'>
														<thead className='border-b w-full'>
															{table.getHeaderGroups().map((headerGroup) => (
																<tr key={headerGroup.id} className=''>
																	<th
																		style={{ cursor: 'default' }}
																		className='text-xs font-bold text-gray-600 px-2 py-1 text-center pb-2'
																	>
																		{t('Select')}
																	</th>
																	{headerGroup.headers.map((header) => (
																		<th
																			key={header.id}
																			onClick={header.column.getToggleSortingHandler()}
																			className='text-xs text-left font-bold text-gray-600 px-2 pb-2'
																		>
																			<div className='text-left'>
																				{header.isPlaceholder
																					? null
																					: flexRender(
																							header.column.columnDef.header,
																							header.getContext()
																					  )}
																			</div>
																		</th>
																	))}
																</tr>
															))}
														</thead>
														<tbody>
															{table.getRowModel().rows.map((row) => (
																<tr
																	key={row.id}
																	className='bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100'
																>
																	<td className='px-2 mt-3 flex justify-center'>
																		<input
																			type='radio'
																			name='limitCheckBox'
																			checked={
																				limitId === row.original.id
																					? true
																					: false
																			}
																			value={row.original.id}
																			onChange={(e) => handleOnClickLimit(e)}
																		/>
																	</td>
																	{row.getVisibleCells().map((cell) => {
																		if (
																			cell.column.columnDef.header ===
																			'BP(min/Max)'
																		) {
																			return (
																				<td
																					key={`cell_${cell.id}`}
																					className='px-2 py-2 text-xs text-left text-gray-900'
																				>
																					{`${row.original.bp_min / 10000}만/${
																						row.original.bp_max / 10000
																					}만`}
																				</td>
																			);
																		} else if (
																			cell.column.columnDef.header ===
																			'TIE(min/Max)'
																		) {
																			return (
																				<td
																					key={`cell_${cell.id}`}
																					className='px-2 py-2 text-xs text-left text-gray-900'
																				>
																					{`${row.original.tie_min / 10000}만/${
																						row.original.tie_max / 10000
																					}만`}
																				</td>
																			);
																		} else if (
																			cell.column.columnDef.header ===
																			'PAIR(min/Max)'
																		) {
																			return (
																				<td
																					key={`cell_${cell.id}`}
																					className='px-2 py-2 text-xs text-left text-gray-900'
																				>
																					{`${
																						row.original.pair_min / 10000
																					}만/${
																						row.original.pair_max / 10000
																					}만`}
																				</td>
																			);
																		} else {
																			return (
																				<td
																					key={cell.id}
																					className='px-2 py-2 text-xs text-left font-medium text-gray-900'
																				>
																					{flexRender(
																						cell.column.columnDef.cell,
																						cell.getContext()
																					)}
																				</td>
																			);
																		}
																	})}
																</tr>
															))}
														</tbody>
													</table>
												</div>
											</div>
										</div> */}
                    {/* <div className=''>
                      <div className='form-group mb-6'>
                        <div className='flex flex-row'>
                          <div className='mb-2 text-gray-500'>
                            {t('User Table Limit')}
                          </div>
                        </div>
                        <div className='ml-2 mt-1 pr-3 h-52 overflow-auto'>
                          <table className='w-full'>
                            <thead className='border-b w-full'>
                              {uTable.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id} className=''>
                                  <th
                                    style={{ cursor: 'default' }}
                                    className='text-xs font-bold text-gray-600 px-2 py-1 text-center pb-2'
                                  >
                                    {t('Select')}
                                  </th>
                                  {headerGroup.headers.map((header) => (
                                    <th
                                      key={header.id}
                                      onClick={header.column.getToggleSortingHandler()}
                                      className='text-xs text-left font-bold text-gray-600 px-2 pb-2'
                                    >
                                      <div className='text-left'>
                                        {header.isPlaceholder
                                          ? null
                                          : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext()
                                            )}
                                      </div>
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </thead>
                            <tbody>
                              {uTable.getRowModel().rows.map((row) => (
                                <tr
                                  key={row.id}
                                  className='bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100'
                                >
                                  <td className='px-2 mt-3 flex justify-center'>
                                    <input
                                      type='radio'
                                      name='UlimitCheckBox'
                                      checked={
                                        ulimitId === row.original.id
                                          ? true
                                          : false
                                      }
                                      value={row.original.id}
                                      onChange={(e) => handleOnClickUTLimit(e)}
                                    />
                                  </td>
                                  {row.getVisibleCells().map((cell) => {
                                    if (
                                      cell.column.columnDef.header ===
                                      'BP(min/Max)'
                                    ) {
                                      return (
                                        <td
                                          key={`cell_${cell.id}`}
                                          className='px-2 py-2 text-xs text-left text-gray-900'
                                        >
                                          {`${row.original.bp_min / 10000}만/${
                                            row.original.bp_max / 10000
                                          }만`}
                                        </td>
                                      );
                                    } else if (
                                      cell.column.columnDef.header ===
                                      'TIE(min/Max)'
                                    ) {
                                      return (
                                        <td
                                          key={`cell_${cell.id}`}
                                          className='px-2 py-2 text-xs text-left text-gray-900'
                                        >
                                          {`${row.original.tie_min / 10000}만/${
                                            row.original.tie_max / 10000
                                          }만`}
                                        </td>
                                      );
                                    } else if (
                                      cell.column.columnDef.header ===
                                      'PAIR(min/Max)'
                                    ) {
                                      return (
                                        <td
                                          key={`cell_${cell.id}`}
                                          className='px-2 py-2 text-xs text-left text-gray-900'
                                        >
                                          {`${
                                            row.original.pair_min / 10000
                                          }만/${
                                            row.original.pair_max / 10000
                                          }만`}
                                        </td>
                                      );
                                    } else {
                                      return (
                                        <td
                                          key={cell.id}
                                          className='px-2 py-2 text-xs text-left font-medium text-gray-900'
                                        >
                                          {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                          )}
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className=''>
                    <div className='form-group mb-6'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Table Limit')}
                        </div>
                      </div>
                      <div className='ml-2 mt-1 pr-3 h-52 overflow-auto'>
                        <table className='w-full'>
                          <thead className='border-b w-full'>
                            {table.getHeaderGroups().map((headerGroup) => (
                              <tr key={headerGroup.id} className=''>
                                <th
                                  style={{ cursor: 'default' }}
                                  className='text-xs font-bold text-gray-600 px-2 py-1 text-center pb-2'
                                >
                                  {t('Select')}
                                </th>
                                {headerGroup.headers.map((header) => (
                                  <th
                                    key={header.id}
                                    onClick={header.column.getToggleSortingHandler()}
                                    className='text-xs text-left font-bold text-gray-600 px-2 pb-2'
                                  >
                                    <div className='text-left'>
                                      {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                          )}
                                    </div>
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>
                          <tbody>
                            {table.getRowModel().rows.map((row) => (
                              <tr
                                key={row.id}
                                className='bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100'
                              >
                                <td className='px-2 mt-3 flex justify-center'>
                                  <input
                                    type='radio'
                                    name='limitCheckBox'
                                    checked={
                                      limitId === row.original?.id
                                        ? true
                                        : false
                                    }
                                    value={row.original?.id}
                                    onChange={(e) => handleOnClickLimit(e)}
                                  />
                                </td>
                                {row.getVisibleCells().map((cell) => {
                                  if (
                                    cell.column.columnDef.header ===
                                    'BP(min/Max)'
                                  ) {
                                    return (
                                      <td
                                        key={`cell_${cell.id}`}
                                        className='px-2 py-2 text-xs text-left text-gray-900'
                                      >
                                        {`${
                                          Number(row.original?.bp_min) / 10000
                                        }만/${
                                          Number(row.original?.bp_max) / 10000
                                        }만`}
                                      </td>
                                    );
                                  } else if (
                                    cell.column.columnDef.header ===
                                    'TIE(min/Max)'
                                  ) {
                                    return (
                                      <td
                                        key={`cell_${cell.id}`}
                                        className='px-2 py-2 text-xs text-left text-gray-900'
                                      >
                                        {`${
                                          Number(row.original?.tie_min) / 10000
                                        }만/${
                                          Number(row.original?.tie_max) / 10000
                                        }만`}
                                      </td>
                                    );
                                  } else if (
                                    cell.column.columnDef.header ===
                                    'PAIR(min/Max)'
                                  ) {
                                    return (
                                      <td
                                        key={`cell_${cell.id}`}
                                        className='px-2 py-2 text-xs text-left text-gray-900'
                                      >
                                        {`${
                                          Number(row.original?.pair_min) / 10000
                                        }만/${
                                          Number(row.original?.pair_max) / 10000
                                        }만`}
                                      </td>
                                    );
                                  } else {
                                    return (
                                      <td
                                        key={cell.id}
                                        className='px-2 py-2 text-xs text-left font-medium text-gray-900'
                                      >
                                        {flexRender(
                                          cell.column.columnDef.cell,
                                          cell.getContext()
                                        )}
                                      </td>
                                    );
                                  }
                                })}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <ButtonCustom
                    canClick={!isSubmitting}
                    actionText={'설정 등록'}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
