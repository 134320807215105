import { ApolloClient, makeVar, InMemoryCache } from '@apollo/client';
import {
  SESSIONSTORAGE_AUTH,
  SESSIONSTORAGE_JUNKET,
  SESSIONSTORAGE_TOKEN,
  SESSIONSTORAGE_LANGUAGE,
} from './constants';
import * as dotenv from 'dotenv';
import { ITableResponse } from './api/queries/table_query';
import { IGameResultRes } from './api/queries/game_query';
import { IBetHistoryResponse } from './api/queries/bethistory_query';
import { IBetLimitResponse } from './api/queries/betlimit_query';
import { IPlayerCardListRes } from './api/queries/playCardList_query';
import { RestLink } from 'apollo-link-rest';
import { IJunketRes } from './api/queries/junket_query';
import { ICurrencyResponse } from './api/queries/currency_query';

const token = sessionStorage.getItem(SESSIONSTORAGE_TOKEN);
const myAuth = sessionStorage.getItem(SESSIONSTORAGE_AUTH);
const myJunket = sessionStorage.getItem(SESSIONSTORAGE_JUNKET);
const mySelectedLang = sessionStorage.getItem(SESSIONSTORAGE_LANGUAGE);

// Authority state management
export const myAuthority = makeVar(myAuth);
export const belongedJunket = makeVar(myJunket);
export const isUpdatedVar = makeVar(false);
export const isLoggedInVar = makeVar(Boolean(token));
export const jwtTokenVar = makeVar(token);
export const proposalNumvar = makeVar('');
export const routeTitleVar = makeVar('');
export const langVar = makeVar(mySelectedLang);

// general state management
export const uBetLimitList = makeVar<IBetLimitResponse[]>([]);
export const tBetLimitList = makeVar<IBetLimitResponse[]>([]);
export const uTBetLimitList = makeVar<IBetLimitResponse[]>([]);
export const currencyList = makeVar<ICurrencyResponse[]>([]);
export const junketList = makeVar<IJunketRes[]>([]);
export const gameTableList = makeVar<ITableResponse[]>([]);
export const settingTableList = makeVar<ITableResponse[]>([]);
export const tableWinLossList = makeVar([]);
export const avatarList = makeVar([]);
export const sAvatarList = makeVar([]);
export const scGameBetList = makeVar([]);
export const soGameBetList = makeVar([]);
export const rGameBetList = makeVar([]);
export const gameResultList = makeVar([]);

// modal data state management
export const tableData = makeVar<ITableResponse | null>(null);
export const gameResultData = makeVar<IGameResultRes | null>(null);
export const limitData = makeVar<IBetLimitResponse | null>(null);
export const betData = makeVar<IBetHistoryResponse | null>(null);
export const playerCardList = makeVar<IPlayerCardListRes | null>(null);

const restLink = new RestLink({
  uri: `${process.env.REACT_APP_URL?.slice(
    0,
    process.env.REACT_APP_URL.length - 1
  )}`,
  customFetch: fetch,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': `${process.env.REACT_CLIENT_URL}`,
    'Access-Control-Allow-Credentials': 'true',
  },
  credentials: 'include',
});

export const client = new ApolloClient({
  link: restLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          isLoggedIn: {
            read() {
              return isLoggedInVar();
            },
          },
          belongedJunket: {
            read() {
              return belongedJunket();
            },
          },
          myAuthority: {
            read() {
              return myAuthority();
            },
          },
          token: {
            read() {
              return jwtTokenVar();
            },
          },
          uBetLimitList: {
            read() {
              return uBetLimitList();
            },
          },
          tBetLimitList: {
            read() {
              return tBetLimitList();
            },
          },
          uTBetLimitList: {
            read() {
              return uTBetLimitList();
            },
          },
          gameTableList: {
            read() {
              return gameTableList();
            },
          },
          settingTableList: {
            read() {
              return settingTableList();
            },
          },
          junketList: {
            read() {
              return junketList();
            },
          },
          currencyList: {
            read() {
              return currencyList();
            },
          },
          tableWinLossList: {
            read() {
              return tableWinLossList();
            },
          },
          avatarList: {
            read() {
              return avatarList();
            },
          },
          sAvatarList: {
            read() {
              return sAvatarList();
            },
          },
          isUpdatedVar: {
            read() {
              return isUpdatedVar();
            },
          },
          scGameBetList: {
            read() {
              return scGameBetList();
            },
          },
          soGameBetList: {
            read() {
              return soGameBetList();
            },
          },
          rGameBetList: {
            read() {
              return rGameBetList();
            },
          },
          gameResultList: {
            read() {
              return gameResultList();
            },
          },
          proposalNumvar: {
            read() {
              return proposalNumvar();
            },
          },
          routeTitleVar: {
            read() {
              return routeTitleVar();
            },
          },
          tableData: {
            read() {
              return tableData();
            },
          },
          betData: {
            read() {
              return betData();
            },
          },
          limitData: {
            read() {
              return limitData();
            },
          },
          gameResultData: {
            read() {
              return gameResultData();
            },
          },
          playerCardList: {
            read() {
              return gameResultData();
            },
          },
          langVar: {
            read() {
              return langVar();
            },
          },
        },
      },
    },
  }),
});
