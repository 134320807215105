import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { AxiosResponse } from 'axios';
import { AiFillEdit } from 'react-icons/ai';
import { useReactiveVar } from '@apollo/client';
import { isUpdatedVar, langVar } from '../../../common/apollo';
import {
  DUMMY_USER_GET_QUERY,
  IDummyUserRes,
} from '../../../common/api/queries/dmmyuser_query';
import { FaRegTrashAlt } from 'react-icons/fa';
import { DummyUserReg } from './modal/dmmyUserReg';
import { DummyUserMod } from './modal/dmmyUserMod';
import { DUMMY_USER_DEL_POST } from '../../../common/api/mutations/dmmyuser_mutation';
import { Popconfirm, Space } from 'antd';
import { useForm, useFormState } from 'react-hook-form';

export const DummyUserList = () => {
  const { t } = useTranslation(['page']);
  const [data, setData] = useState<IDummyUserRes[]>([]);
  const [openReg, setOpenReg] = useState(false);
  const [openSetup, setOpenSetup] = useState(false);
  const [selectData, setSelectData] = useState<IDummyUserRes | null>(null);

  const isUpdated = useReactiveVar(isUpdatedVar);

  const onClickModalSetup = (data: IDummyUserRes) => {
    setOpenSetup(!openSetup);
    setSelectData(data);
  };

  const onClickModalReg = () => {
    setOpenReg(!openReg);
  };

  const onClickModalDel = async (user_id: string) => {
    const data = {
      user_id: user_id,
    };

    await DUMMY_USER_DEL_POST(data).then((res: AxiosResponse | any) => {
      if (res.data) {
        if (res.data.data.code === 1) {
          alert(t('Deletion Success'));
          isUpdatedVar(!isUpdated);
        } else {
          alert(t('Request Failed'));
        }
      }
    });
  };

  const getNoticeList = () => {
    DUMMY_USER_GET_QUERY().then((res: AxiosResponse | any) => {
      if (res.data) {
        if (res.data.data.code === 1) {
          setData(res.data.data.data.list);
        }
      }
    });
  };

  const columnHelper = createColumnHelper<IDummyUserRes>();
  const columns = [
    columnHelper.accessor('user_id', {
      header: String(t('User ID')),
    }),
    columnHelper.accessor('ref', {
      header: String(t('Reference')),
    }),
    columnHelper.accessor('range_step', {
      header: String(t('Range Step')),
    }),

    columnHelper.accessor('range_from', {
      header: String(t('Range From')),
    }),
    columnHelper.accessor('range_to', {
      header: String(t('Range To')),
    }),
  ];

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    setOpenSetup(false);
    getNoticeList();
  }, [isUpdated]);

  return (
    <div className='border-r border-l rounded-md shadow-lg mt-5'>
      <div className='flex flex-row justify-between items-center border-b'>
        <div className='py-3 px-6'>
          <div className='text-gray-500 font-semibold text-lg'>
            {t('Dummy User')}
          </div>
          <div className='text-gray-400'>{t('Inquiry & Settings')}</div>
        </div>
        <div className='py-3 px-6'>
          <button
            className='text-white w-full rounded text-lg px-6 py-1.5 focus:outline-none font-semibold whitespace-nowrap transition-colors bg-gray-500 hover:bg-gray-600'
            onClick={() => onClickModalReg()}
          >
            {t('Dummy User List')}
          </button>
        </div>
      </div>
      <div className='flex flex-col bg-white'>
        <div className='overflow-x-auto'>
          <div className='min-w-full'>
            <table className='w-full'>
              <thead className='border-b bg-[#245c81]'>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className=''>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className='text-sm font-bold text-white px-2 py-2 text-left whitespace-nowrap'
                      >
                        <div className='text-center'>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </div>
                      </th>
                    ))}
                    <th
                      style={{ cursor: 'default' }}
                      className='text-sm font-bold text-white px-2 py-2 text-center'
                    >
                      {t('Edit')}
                    </th>
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row, index) => (
                  <tr
                    key={row.id}
                    className={`bg-white border-b transition duration-300 ease-in-out hover:bg-gray-300 whitespace-nowrap`}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className='px-4 py-2 text-xs text-center font-medium text-gray-900'
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                    <td className='flex px-4 py-2 gap-2 items-center justify-center'>
                      <div
                        onClick={() => onClickModalSetup(row.original)}
                        className='p-1 rounded-sm hover:bg-gray-100 cursor-pointer text-base'
                      >
                        <AiFillEdit />
                      </div>

                      <Space>
                        <Popconfirm
                          title={String(t('History Cancellation'))}
                          description={String(
                            t(
                              'Are You Sure You Want To Cancel Your Game History?'
                            )
                          )}
                          onConfirm={() =>
                            onClickModalDel(row.original.user_id)
                          }
                          okText='Yes'
                          cancelText='No'
                        >
                          <div className='p-1 rounded-sm hover:bg-gray-100 cursor-pointer text-base text-red-400'>
                            <FaRegTrashAlt />
                          </div>
                        </Popconfirm>
                      </Space>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='flex flex-wrap items-center justify-center py-4 gap-2'>
          <button
            className='border rounded p-1'
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className='border rounded p-1'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className='border rounded p-1'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className='border rounded p-1'
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <span className='flex items-center gap-1'>
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </strong>
          </span>
          <span className='flex items-center gap-1'>
            | Go to page:
            <input
              type='number'
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className='border p-1 rounded w-16'
            />
          </span>
          <select
            className='border p-1 rounded'
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
      {openReg ? <DummyUserReg setOpenReg={setOpenReg} /> : null}
      {openSetup ? (
        <DummyUserMod setOpenSetup={setOpenSetup} rowData={selectData} />
      ) : null}
    </div>
  );
};
