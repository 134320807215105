import { AxiosResponse } from 'axios';
import { apiClient } from '../apiConfig';

export interface ITableSearchForm {
  category_code: string | null;
  type: string | null;
  stat: string | null;
}

export interface ITableWinLossSearchForm {
  junket_code: string;
  table_code: string;
  from: string;
  to: string;
}

export interface ITableResponse {
  id: string;
  category_code: string;
  table_code: string;
  type: string;
  sub_type: string;
  stat: string;
  ulimit_id: string;
  limit_id: string;
  limit_type: string;
  timer: number;
  free_range1: number;
  free_range2: number;
  free_range_yn: boolean;
  bal_bet: number;
  bal_pbet: number;
  bal_bbet: number;
  robot_bet_min: number;
  rolling_cut: number;
  need_confirm: boolean;
}

export interface ITableWinLossRes {
  junket_code: string;
  junket_name: string;
  table_num: string;
  winloss: number;
  rolling: number;
  tip: number;
}

export const TABLES_GET_QUERY = async () => {
  try {
    const response: AxiosResponse = await apiClient.get('api/TABLES_GET_URI');
    return response;
  } catch (err) {
    return err;
  }
};

export const TABLES_GET_SEARCH_QUERY = async (data: ITableSearchForm) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'api/search-table',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};
