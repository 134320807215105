import React from 'react';

interface IButtonProps {
  canClick: boolean;
  actionText: string;
}

export const ButtonCustom: React.FC<IButtonProps> = ({
  canClick,
  actionText,
}) => {
  return (
    <button
      role='button'
      disabled={!canClick}
      className={`text-white w-full rounded text-lg px-6 py-1.5 focus:outline-none font-semibold whitespace-nowrap transition-colors ${
        canClick
          ? ' bg-gray-500 hover:bg-gray-600'
          : 'bg-gray-500 hover:bg-gray-600 pointer-events-none'
      }`}
    >
      {actionText}
    </button>
  );
};
