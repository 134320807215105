import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  INewNoticeResponse,
  NEW_NOTICE_GET_QUERY,
} from '../../../common/api/queries/notice_query';
import { AxiosResponse } from 'axios';
import { AiFillEdit } from 'react-icons/ai';
// import { ScrollRegModal } from './modal/scrollRegModal';
import { useReactiveVar } from '@apollo/client';
import { isUpdatedVar, langVar } from '../../../common/apollo';
import { NewScrollRegModal } from './modal/newScrollRegModal';
import { NewScrollSetupModal } from './modal/newScrollSetupModal';
import { SCROLL_NOTICE_GET } from '../../../common/api/mutations/notice_mutation';
// import { ScrollSetupModal } from './modal/scrollSetupModal';

export const NewScrollNoticeList = () => {
  const { t } = useTranslation(['page']);
  const [data, setData] = useState<INewNoticeResponse[]>([]);
  const [openReg, setOpenReg] = useState(false);
  const [openSetup, setOpenSetup] = useState(false);
  const [selectData, setSelectData] = useState<INewNoticeResponse | null>(null);

  const languageVar = useReactiveVar(langVar);
  const isUpdated = useReactiveVar(isUpdatedVar);

  const onClickModalSetup = (data: INewNoticeResponse) => {
    setOpenSetup(!openSetup);
    setSelectData(data);
  };

  const onClickModalReg = () => {
    setOpenReg(!openReg);
  };

  const getNoticeList = () => {
    SCROLL_NOTICE_GET().then((res: AxiosResponse | any) => {
      if (res.data) {
        if (res.data.data.code === 1) {
          setData(res.data.data.data.list);
        }
      }
    });
  };

  const columnHelper = createColumnHelper<INewNoticeResponse>();
  const columns = [
    columnHelper.accessor('lang', {
      header: String(t('Language')),
    }),
    columnHelper.accessor('text', {
      header: String(t('Data Text')),
    }),
    columnHelper.accessor('disp_yn', {
      header: String(t('Display')),
    }),
  ];

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    getNoticeList();
  }, []);

  useEffect(() => {
    setOpenSetup(false);
    getNoticeList();
  }, [isUpdated]);

  return (
    <div className='border-r border-l rounded-md shadow-lg mt-5'>
      <div className='flex flex-row justify-between items-center border-b'>
        <div className='py-3 px-6'>
          <div className='text-gray-500 font-semibold text-lg'>
            {t('Notice List')}
          </div>
          <div className='text-gray-400'>{t('Inquiry & Settings')}</div>
        </div>
        <div className='py-3 px-6'>
          <button
            className='text-white w-full rounded text-lg px-6 py-1.5 focus:outline-none font-semibold whitespace-nowrap transition-colors bg-gray-500 hover:bg-gray-600'
            onClick={() => onClickModalReg()}
          >
            {t('Create Notice')}
          </button>
        </div>
      </div>
      <div className='flex flex-col bg-white'>
        <div className='overflow-x-auto'>
          <div className='min-w-full'>
            <table className='w-full'>
              <thead className='border-b bg-[#245c81]'>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className=''>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className='text-sm font-bold text-white px-2 py-2 text-left whitespace-nowrap'
                      >
                        <div className='text-center'>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </div>
                      </th>
                    ))}
                    <th
                      style={{ cursor: 'default' }}
                      className='text-sm font-bold text-white px-2 py-2 text-center'
                    >
                      {t('Edit')}
                    </th>
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row, index) => (
                  <tr
                    key={row.id}
                    className={`bg-white border-b transition duration-300 ease-in-out hover:bg-gray-300 whitespace-nowrap`}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className='px-4 py-4 text-xs text-center font-medium text-gray-900'
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                    <td className='p-2 flex items-center justify-center'>
                      <div
                        className='p-2'
                        onClick={() => onClickModalSetup(row.original)}
                      >
                        <AiFillEdit className='text-base' />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {openReg ? <NewScrollRegModal setOpenReg={setOpenReg} /> : null}
      {openSetup ? (
        <NewScrollSetupModal setOpenSetup={setOpenSetup} rowData={selectData} />
      ) : null}
    </div>
  );
};
