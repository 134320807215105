import { useReactiveVar } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { AxiosResponse } from 'axios';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  ITableResponse,
  ITableSearchForm,
  TABLES_GET_SEARCH_QUERY,
} from '../../../common/api/queries/table_query';
import {
  settingTableList,
  isUpdatedVar,
  junketList,
  langVar,
  routeTitleVar,
} from '../../../common/apollo';
import { ButtonCustom } from '../../../components/button';
import { useTranslation } from 'react-i18next';

export const TableSetupSearch = () => {
  const { t } = useTranslation(['page']);
  const { register, getValues, handleSubmit } = useForm<ITableSearchForm>({
    mode: 'onChange',
  });

  const selectedLang = useReactiveVar(langVar);
  const isUpdated = useReactiveVar(isUpdatedVar);
  const junketCode = useReactiveVar(junketList);
  useReactiveVar(routeTitleVar);
  useReactiveVar(settingTableList);

  const onSubmit = () => {
    const { category_code, type, stat } = getValues();

    const data = {
      category_code: category_code || null,
      type: type || null,
      stat: stat || null,
    };

    TABLES_GET_SEARCH_QUERY(data).then((res: AxiosResponse | any) => {
      if (res.data.data.code === 1) {
        settingTableList(res.data.data.data.list);
      }
    });
  };

  const onGetTableData = async () => {
    const { category_code, type, stat } = getValues();
    const data = {
      category_code: category_code || null,
      type: type || null,
      stat: stat || null,
    };

    await TABLES_GET_SEARCH_QUERY(data).then((res: AxiosResponse | any) => {
      if (res.data.data.code === 1) {
        settingTableList(res.data.data.data.list);
      }
    });
  };

  useEffect(() => {
    onGetTableData();
  }, [isUpdated]);

  useEffect(() => {
    routeTitleVar(String(t('Table Setting')));
  }, [selectedLang]);
  return (
    <div className='p-6 rounded-lg shadow-lg bg-white'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-wrap justify-end items-center gap-3 mb-5'>
          <div className='flex flex-row gap-3 w-full sm:w-auto justify-between'>
            <div className='form-group w-full'>
              <div className='mb-2 text-gray-500 whitespace-nowrap'>
                {t('Junket Name')}
              </div>
              <select
                {...register('category_code')}
                className='forminput'
                placeholder={String(t('Enter User Account'))}
              >
                <option value=''>{t('Entire')}</option>
                {junketCode?.map((elem) => {
                  return (
                    <option key={elem.id} value={elem.id}>
                      {elem.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className='form-group w-full'>
              <div className='mb-2 text-gray-500 whitespace-nowrap'>
                {t('Table Type')}
              </div>
              <select
                {...register('type')}
                className='forminput'
                placeholder={String(t('Enter User Account'))}
              >
                <option value=''>{t('Entire')}</option>
                <option value='P'>{t('Phone Betting')}</option>
                <option value='S'>{t('Speed')}</option>
              </select>
            </div>
            <div className='form-group w-full'>
              <div className='mb-2 text-gray-500 whitespace-nowrap'>
                {t('Status')}
              </div>
              <select
                {...register('stat')}
                className='forminput'
                placeholder={String(t('Table State'))}
              >
                <option value=''>{t('Entire')}</option>
                <option value='O'>{t('Open')}</option>
                <option value='C'>{t('Close')}</option>
                <option value='H'>{t('Hide')}</option>
                <option value='I'>{t('Maintenance')}</option>
              </select>
            </div>
          </div>
          <div className='flex flex-row gap-3 w-full sm:w-auto pt-7'>
            <div className='flex'>
              <ButtonCustom canClick={true} actionText={t('Search')} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
