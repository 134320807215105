import React from 'react';
import { SaGameBetSearch } from './saGameBetSearch';

export const SaGameBet = () => {
  return (
    <div>
      <SaGameBetSearch />
    </div>
  );
};
