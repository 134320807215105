import { AxiosResponse } from 'axios';
import { apiClient } from '../apiConfig';

export interface ITableSetupUpdate {
  id: string;
  category_code: string;
  table_code: string;
  type: string | undefined;
  sub_type: string | undefined;
  stat: string | undefined;
  // ulimit_id: string | undefined;
  limit_id: string | undefined;
  limit_type: string | undefined;
  timer: number;
  free_range1: number;
  free_range2: number;
  free_range_yn: boolean;
  bal_pbet: number;
  bal_bbet: number;
  robot_bet_min: number;
  rolling_cut: number;
  need_confirm: boolean;
}

export interface ITableRegisterUpdate {
  category_code: string;
  table_code: string;
  type: string | undefined;
  sub_type: string | undefined;
  game_type: string | undefined;
  stat: string | undefined;
  ulimit_id: string | undefined;
  limit_id: string | undefined;
  limit_type: string | undefined;
  timer: number;
  free_range1: number;
  free_range2: number;
  free_range_yn: boolean;
  rolling_cut: number;
  need_confirm: boolean;
}

export interface ITableSelectMultiple {
  table_ids: string[];
  table_stat: string;
}

export const TABLE_SETUP_UPDATE = async (data: ITableSetupUpdate) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'api/tablesetup-mutation/TABLE_EDIT_URI',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const TABLE_REGISTER_UPDATE = async (data: ITableRegisterUpdate) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'api/tablesetup-mutation/TABLE_CREATE_URI',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const TABLE_MULTIPLE_SETUP_UPDATE = async (
  data: ITableSelectMultiple
) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'api/edit-tables/TABLES_EDIT_URI',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};
