import React from 'react';
import { PhoneTipSearch } from './phoneTipSearch';

export const PhoneTip = () => {
  return (
    <div>
      <PhoneTipSearch />
    </div>
  );
};
