import { AxiosResponse } from 'axios';
import { apiClient } from '../apiConfig';

export interface IPlayerSearchForm {
  agent_id: string;
  disp_id: string | null;
  connected: string | null;
  block_yn: string | null;
  unit_page: number | undefined;
  cur_page: number | undefined;
}

export interface IPlayerListRes {
  id: string;
  agent_id: string;
  disp_id: string;
  name: string;
  connected: string;
  cur_currency: string;
  block_yn: boolean;
  login_date: string;
  money: number;
}

export interface ITableStatusRes {
  agent_id: string;
  user_id: string;
  balance: number;
  disp_id: string;
}

export interface ITableStatusDummyRes {
  user_id: string;
  balance: number;
  user_type: string;
  login_session: string;
}

// export interface ITableStatusRes {
//   agent_id: string;
//   user_id: string;
//   disp_id: string;
//   balance: number;
//   login_session: string;
// }

export interface ITableStatusResArr {
  pmembers: ITableStatusRes[];
  smembers: ITableStatusRes[];
  amembers: ITableStatusRes[];
  dummies: ITableStatusDummyRes[];
}

export const PLAYER_LIST_SEARCH_QUERY = async (data: IPlayerSearchForm) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'api/get-user-list',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const PLAYER_TABLE_SEARCH_QUERY = async (id: string) => {
  try {
    const payload = {
      id: id,
    };
    const response: AxiosResponse = await apiClient.post(
      'api/get-user-tables',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const TABLE_STATUS_SEARCH_QUERY = async (id: string) => {
  try {
    const payload = {
      id: id,
    };
    const response: AxiosResponse = await apiClient.post(
      'api/get-table-status',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};
