import { AxiosResponse } from 'axios';
import { apiClient } from '../apiConfig';

export interface ICurrencySearchForm {
  currency: string | undefined;
  rate: number;
}
export const CURRENCY_SETUP_POST = async (data: ICurrencySearchForm) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'api/add-exchange',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const CURRENCY_SETUP_PATCH = async (data: ICurrencySearchForm) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.patch(
      'api/edit-exchange',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};
