import React, { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { AxiosResponse } from 'axios';
import {
  ITableRegisterUpdate,
  TABLE_REGISTER_UPDATE,
} from '../../../../common/api/mutations/tableSetup_mutation';
import {
  IBetLimitResponse,
  LIMITS_GET_QUERY,
  USER_TLIMITS_GET_QUERY,
} from '../../../../common/api/queries/betlimit_query';
import { isUpdatedVar, tableData } from '../../../../common/apollo';

import { ButtonCustom } from '../../../../components/button';
import { GrClose } from 'react-icons/gr';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import {
  IJunketRes,
  JUNKET_GET_QUERY,
} from '../../../../common/api/queries/junket_query';

interface IModalProps {
  setModalReg: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TableRegModal: React.FC<IModalProps> = ({ setModalReg }) => {
  const {
    register,
    getValues,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm<ITableRegisterUpdate>({ mode: 'onChange' });
  const { isSubmitting } = useFormState({ control });

  const { t } = useTranslation(['page']);
  const isUpdated = useReactiveVar(isUpdatedVar);

  const [isTableType, setIsTableType] = useState('');
  const [limitType, setLimitType] = useState('');
  const [ulimitId, setUlimitID] = useState('');
  const [limitId, setLimitId] = useState('');
  const [data, setData] = useState<IBetLimitResponse[]>([]);
  const [uTData, setUTData] = useState<IBetLimitResponse[]>([]);
  const [junketList, setJunketList] = useState(Array<IJunketRes>);

  const handleOnClickLimitType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLimitType(e.currentTarget.value);
  };

  const clickModalClose = () => {
    setModalReg((current) => !current);
  };

  const handleOnClickLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLimitId(e.currentTarget.value);
  };

  const handleOnClickUTLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUlimitID(e.currentTarget.value);
  };

  const getJunketList = () => {
    JUNKET_GET_QUERY().then((res: AxiosResponse | any) => {
      if (res.data.data.code === 1) {
        setJunketList(res.data.data.data.list);
      }
    });
  };

  const getLimitList = () => {
    LIMITS_GET_QUERY().then((res: AxiosResponse | any) => {
      if (res.data.data[0].code === 1) {
        setData(res.data.data[0].data.list);
      }
    });
  };

  const getULimitList = () => {
    USER_TLIMITS_GET_QUERY().then((res: AxiosResponse | any) => {
      if (res.data.data[0].code === 1) {
        setUTData(res.data.data[0].data.list);
      }
    });
  };

  const columnHelper = createColumnHelper<IBetLimitResponse>();
  const columns = [
    columnHelper.accessor('name', {
      header: String(t('Name')),
    }),
    columnHelper.accessor('bp_min', {
      header: 'BP(min/Max)',
    }),
    columnHelper.accessor('tie_min', {
      header: 'TIE(min/Max)',
    }),
    columnHelper.accessor('pair_min', {
      header: 'PAIR(min/Max)',
    }),
  ];

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const uTable = useReactTable({
    data: uTData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const tableType = [
    { key: '', value: '', label: t('Select') },
    { key: 'P', value: 'P', label: t('PhoneBet') },
    { key: 'S', value: 'S', label: t('Speed') },
  ];

  const tableSubType = [
    { key: '', value: '', label: t('Select') },
    { key: 'PB', value: 'PB', label: t('PhoneBet') },
    { key: 'SB', value: 'SB', label: t('Speed') },
    { key: 'IB', value: 'IB', label: t('Insurance Bet') },
    { key: 'SS', value: 'SS', label: t('Super Speed') },
  ];

  const tableStatus = [
    { key: '', value: '', label: t('Select') },
    { key: 'O', value: 'O', label: t('Open') },
    { key: 'C', value: 'C', label: t('Closed') },
    { key: 'M', value: 'M', label: t('Maintenance') },
  ];

  const gameStypes = [
    { key: '', value: '', label: t('Select') },
    { key: 'BK', value: 'BK', label: t('Baccarat') },
    { key: 'WB', value: 'WB', label: t('Winnball') },
  ];

  const onSubmit = () => {
    const {
      category_code,
      table_code,
      type,
      sub_type,
      game_type,
      stat,
      timer,
      free_range1,
      free_range2,
      free_range_yn,
      rolling_cut,
      need_confirm,
    } = getValues();

    const data = {
      category_code: category_code,
      table_code: table_code,
      game_type: game_type,
      type: type,
      sub_type: sub_type,
      stat: stat,
      ulimit_id: ulimitId,
      limit_id: limitId,
      limit_type: limitType,
      timer: timer,
      free_range1: free_range1,
      free_range2: free_range2,
      free_range_yn: free_range_yn,
      rolling_cut: rolling_cut,
      need_confirm: need_confirm,
    };

    let isValid = true;
    if (
      category_code === '' ||
      stat === '' ||
      sub_type === '' ||
      type === '' ||
      limitType === '' ||
      ulimitId === '' ||
      limitId === ''
    ) {
      isValid = false;
    }

    if (!isValid) {
      alert(t('Required Fields Are Not Filled In.'));
    } else {
      TABLE_REGISTER_UPDATE(data)
        .then((res: AxiosResponse | any) => {
          if (res.data.data.code === 1) {
            alert(t('Registration Success'));
            isUpdatedVar(!isUpdated);
          } else {
            alert(t('Registration Failed'));
            setModalReg(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getJunketList();
    getLimitList();
    getULimitList();
  }, []);

  return (
    <div className='box-border fixed top-0 right-0 bottom-0 left-0 z-40 overflow-auto outline-0'>
      <div className='fixed w-full h-full backdrop-blur-sm'>
        <div className='box-border relative shadow-lg m-24 bg-white top-12'>
          <div>
            <div className='border rounded-md lg:col-span-2'>
              <div className='font-medium text-lg px-6 py-3 border-b flex flex-row justify-between'>
                <div className='text-blue-500'>{t('Create Table')}</div>
                <GrClose
                  className='mt-1 cursor-pointer'
                  onClick={clickModalClose}
                />
              </div>
              <div className='p-6 rounded-lg shadow-md bg-whit '>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input
                    type='text'
                    name='username'
                    className='w-0 h-0 border-0 block'
                  />
                  <div className='grid grid-cols-2 gap-4'>
                    <div className='col-span-2'>
                      <div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
                        <div className='form-group mb-3'>
                          <div className='flex flex-row'>
                            <div className='mb-2 text-gray-500'>
                              {t('Junket Name')}
                            </div>
                            <code className='text-red-600'> *</code>
                          </div>
                          <select
                            {...register('category_code')}
                            className='forminput'
                          >
                            <option value=''>{t('Select')}</option>
                            {junketList?.map((elem, index) => {
                              const formattedName =
                                elem.name.charAt(0).toUpperCase() +
                                elem.name.slice(1).toLowerCase();

                              return (
                                <option
                                  key={`junketoption_${index}`}
                                  value={String(elem.id)}
                                >
                                  {formattedName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className='form-group mb-3'>
                          <div className='flex flex-row'>
                            <div className='mb-2 text-gray-500'>
                              {t('Table Number')}
                              <code className='text-red-600'> *</code>
                            </div>
                          </div>
                          <input
                            {...register('table_code')}
                            type='text'
                            required
                            className='forminput'
                          />
                        </div>
                      </div>
                      <div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
                        <div className='form-group mb-3'>
                          <div className='flex flex-row'>
                            <div className='mb-2 text-gray-500'>
                              {t('Table Type')}
                              <code className='text-red-600'> *</code>
                            </div>
                          </div>
                          <div className='flex gap-2'>
                            <select
                              {...register('type')}
                              className='forminput'
                              onChange={(event) => {
                                const selTableType = event.target.value;
                                if (selTableType === 'P') {
                                  setValue('sub_type', '');
                                } else {
                                  setValue('sub_type', '');
                                }
                                setValue('type', selTableType);
                                setIsTableType(selTableType);
                              }}
                            >
                              {tableType.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            <select
                              {...register('sub_type')}
                              className='forminput'
                            >
                              {tableSubType
                                .filter((option) => {
                                  if (isTableType === 'P') {
                                    return (
                                      option.key !== 'SB' && option.key !== 'SS'
                                    );
                                  } else if (isTableType === 'S') {
                                    return (
                                      option.key !== 'PB' && option.key !== 'IB'
                                    );
                                  } else {
                                    return option;
                                  }
                                })
                                .map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                            </select>
                            <div className='form-group flex items-center'>
                              <p className='whitespace-nowrap'>
                                {t('Need Confirm')}
                              </p>
                              <input
                                {...register('need_confirm')}
                                type='checkbox'
                                className='mx-2'
                              />
                            </div>
                          </div>
                        </div>
                        <div className='grid grid-cols-1 gap-4 sm:grid-cols-3'>
                          <div className='form-group mb-3'>
                            <div className='flex flex-row'>
                              <div className='mb-2 text-gray-500'>
                                {t('Table State')}
                                <code className='text-red-600'> *</code>
                              </div>
                            </div>
                            <select {...register('stat')} className='forminput'>
                              {tableStatus.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className='form-group mb-3'>
                            <div className='flex flex-row'>
                              <div className='mb-2 text-gray-500'>
                                {t('Game Type')}
                                <code className='text-red-600'> *</code>
                              </div>
                            </div>
                            <select
                              {...register('game_type')}
                              className='forminput'
                            >
                              {gameStypes.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className='form-group mb-3'>
                            <div className='flex flex-row'>
                              <div className='mb-2 text-gray-500'>
                                {t('Rolling Cut')}
                                <code className='text-red-600'> *</code>
                              </div>
                            </div>
                            <input
                              {...register('rolling_cut')}
                              type='number'
                              className='forminput'
                              required
                              defaultValue={1000}
                              min={0}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='grid grid-cols-2 gap-4'>
                        <div className='grid grid-cols-2 gap-4'>
                          <div className='form-group mb-3'>
                            <div className='mb-2 text-gray-500'>
                              {t('Limit Type')}
                              <code className='text-red-600'> *</code>
                            </div>
                            <label className='flex flex-row gap-2'>
                              <input
                                type='radio'
                                name='limitTypeCheckbox'
                                value='T'
                                checked={limitType === 'T' ? true : false}
                                onChange={(e) => handleOnClickLimitType(e)}
                              />
                              <div className='text-base'>{t('Pair Sum')}</div>
                            </label>
                          </div>
                          <div className='form-group mb-3'>
                            <div className='mb-7'></div>
                            <label className='flex flex-row gap-2'>
                              <input
                                type='radio'
                                name='limitSubTypeCheckbox'
                                value='E'
                                checked={limitType === 'E' ? true : false}
                                onChange={(e) => handleOnClickLimitType(e)}
                              />
                              <div className='text-base'>
                                {t('Pair Separately')}
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className='flex gap-4'>
                          <div className='form-group'>
                            <div className='mb-2 text-gray-500'>
                              {t('Timer')}
                            </div>
                            <div className='flex flex-row'>
                              <input
                                {...register('timer')}
                                type='number'
                                className='forminput'
                                required
                              />
                              <div className='text-base font-semibold m-2'>
                                초
                              </div>
                            </div>
                          </div>
                          <div className='form-group'>
                            <div className='mb-2 text-gray-500'>
                              {t('Free Range 1')}
                            </div>
                            <div className='flex flex-row'>
                              <input
                                {...register('free_range1')}
                                type='number'
                                className='forminput'
                                required
                              />
                            </div>
                          </div>
                          <div className='form-group'>
                            <div className='mb-2 text-gray-500'>
                              {t('Free Range 2')}
                            </div>
                            <div className='flex flex-row'>
                              <input
                                {...register('free_range2')}
                                type='number'
                                className='forminput'
                                required
                              />
                              <input
                                {...register('free_range_yn')}
                                type='checkbox'
                                className='mx-2'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='grid grid-cols-2 gap-4'></div>
                    </div>

                    <div className=''>
                      <div className='form-group mb-6'>
                        <div className='flex flex-row'>
                          <div className='mb-2 text-gray-500'>
                            {t('Table Limit')}
                            <code className='text-red-600'> *</code>
                          </div>
                        </div>
                        <div className='ml-2 mt-1 pr-3 h-52 overflow-auto'>
                          <table className='w-full'>
                            <thead className='border-b w-full'>
                              {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id} className=''>
                                  <th
                                    style={{ cursor: 'default' }}
                                    className='text-xs font-bold text-gray-600 px-2 py-1 text-center pb-2'
                                  >
                                    {t('Select')}
                                  </th>
                                  {headerGroup.headers.map((header) => (
                                    <th
                                      key={header.id}
                                      onClick={header.column.getToggleSortingHandler()}
                                      className='text-xs text-left font-bold text-gray-600 px-2 pb-2'
                                    >
                                      <div className='text-left'>
                                        {header.isPlaceholder
                                          ? null
                                          : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext()
                                            )}
                                      </div>
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </thead>
                            <tbody>
                              {table.getRowModel().rows.map((row) => (
                                <tr
                                  key={row.id}
                                  className='bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100'
                                >
                                  <td className='px-2 mt-3 flex justify-center'>
                                    <input
                                      type='radio'
                                      name='limitCheckBox'
                                      checked={
                                        limitId === row.original.id
                                          ? true
                                          : false
                                      }
                                      value={row.original.id}
                                      onChange={(e) => handleOnClickLimit(e)}
                                    />
                                  </td>
                                  {row.getVisibleCells().map((cell) => {
                                    if (
                                      cell.column.columnDef.header ===
                                      'BP(min/Max)'
                                    ) {
                                      return (
                                        <td
                                          key={`cell_${cell.id}`}
                                          className='px-2 py-2 text-xs text-left text-gray-900'
                                        >
                                          {`${row.original.bp_min / 10000}만/${
                                            row.original.bp_max / 10000
                                          }만`}
                                        </td>
                                      );
                                    } else if (
                                      cell.column.columnDef.header ===
                                      'TIE(min/Max)'
                                    ) {
                                      return (
                                        <td
                                          key={`cell_${cell.id}`}
                                          className='px-2 py-2 text-xs text-left text-gray-900'
                                        >
                                          {`${row.original.tie_min / 10000}만/${
                                            row.original.tie_max / 10000
                                          }만`}
                                        </td>
                                      );
                                    } else if (
                                      cell.column.columnDef.header ===
                                      'PAIR(min/Max)'
                                    ) {
                                      return (
                                        <td
                                          key={`cell_${cell.id}`}
                                          className='px-2 py-2 text-xs text-left text-gray-900'
                                        >
                                          {`${
                                            row.original.pair_min / 10000
                                          }만/${
                                            row.original.pair_max / 10000
                                          }만`}
                                        </td>
                                      );
                                    } else {
                                      return (
                                        <td
                                          key={cell.id}
                                          className='px-2 py-2 text-xs text-left font-medium text-gray-900'
                                        >
                                          {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                          )}
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className=''>
                      <div className='form-group mb-6'>
                        <div className='flex flex-row'>
                          <div className='mb-2 text-gray-500'>
                            {t('User Table Limit')}
                            <code className='text-red-600'> *</code>
                          </div>
                        </div>
                        <div className='ml-2 mt-1 pr-3 h-52 overflow-auto'>
                          <table className='w-full'>
                            <thead className='border-b w-full'>
                              {uTable.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id} className=''>
                                  <th
                                    style={{ cursor: 'default' }}
                                    className='text-xs font-bold text-gray-600 px-2 py-1 text-center pb-2'
                                  >
                                    {t('Select')}
                                  </th>
                                  {headerGroup.headers.map((header) => (
                                    <th
                                      key={header.id}
                                      onClick={header.column.getToggleSortingHandler()}
                                      className='text-xs text-left font-bold text-gray-600 px-2 pb-2'
                                    >
                                      <div className='text-left'>
                                        {header.isPlaceholder
                                          ? null
                                          : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext()
                                            )}
                                      </div>
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </thead>
                            <tbody>
                              {uTable.getRowModel().rows.map((row) => (
                                <tr
                                  key={row.id}
                                  className='bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100'
                                >
                                  <td className='px-2 mt-3 flex justify-center'>
                                    <input
                                      type='radio'
                                      name='UlimitCheckBox'
                                      checked={
                                        ulimitId === row.original.id
                                          ? true
                                          : false
                                      }
                                      value={row.original.id}
                                      onChange={(e) => handleOnClickUTLimit(e)}
                                    />
                                  </td>
                                  {row.getVisibleCells().map((cell) => {
                                    if (
                                      cell.column.columnDef.header ===
                                      'BP(min/Max)'
                                    ) {
                                      return (
                                        <td
                                          key={`cell_${cell.id}`}
                                          className='px-2 py-2 text-xs text-left text-gray-900'
                                        >
                                          {`${row.original.bp_min / 10000}만/${
                                            row.original.bp_max / 10000
                                          }만`}
                                        </td>
                                      );
                                    } else if (
                                      cell.column.columnDef.header ===
                                      'TIE(min/Max)'
                                    ) {
                                      return (
                                        <td
                                          key={`cell_${cell.id}`}
                                          className='px-2 py-2 text-xs text-left text-gray-900'
                                        >
                                          {`${row.original.tie_min / 10000}만/${
                                            row.original.tie_max / 10000
                                          }만`}
                                        </td>
                                      );
                                    } else if (
                                      cell.column.columnDef.header ===
                                      'PAIR(min/Max)'
                                    ) {
                                      return (
                                        <td
                                          key={`cell_${cell.id}`}
                                          className='px-2 py-2 text-xs text-left text-gray-900'
                                        >
                                          {`${
                                            row.original.pair_min / 10000
                                          }만/${
                                            row.original.pair_max / 10000
                                          }만`}
                                        </td>
                                      );
                                    } else {
                                      return (
                                        <td
                                          key={cell.id}
                                          className='px-2 py-2 text-xs text-left font-medium text-gray-900'
                                        >
                                          {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                          )}
                                        </td>
                                      );
                                    }
                                  })}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ButtonCustom
                    canClick={!isSubmitting}
                    actionText={'설정 등록'}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
