import React from 'react';
import { SpeedTipSearch } from './speedTipSearch';

export const SpeedTip = () => {
  return (
    <div>
      <SpeedTipSearch />
    </div>
  );
};
