import { AxiosResponse } from 'axios';
import { apiClient } from '../apiConfig';
import qs from 'qs';

export interface IGameSearchForm {
  junket_code: string;
  table_code: string;
  edit_stat: string;
  proposal_num: string;
  game_no: string;
  date_type: string;
  from: string;
  to: string;
}

export interface IGameResponse {
  junket_code: string;
  junket_name: string;
  table_code: string;
  game_type: string;
  game_num: string;
  currency_code: string;
  proposal_num: string;
  created_at: string;
  end_at: string;
  mod_type: string;
  game_result: string; // confirm later on
}

export interface IGameResponseData {
  list: IGameResultRes[];
  cur_page: string;
  tot_page: string;
}

export interface IGameResultRes {
  id: number;
  category_code: string;
  table_code: string;
  proposal_num: string;
  game_no: string;
  game_group: string;
  game_type: string;
  start_date: string;
  end_date: string;
  stat: string;
  edit_stat: string;
  bet: string;
  shoe_no: string;
  game_seq: number;
}

export const GAME_RESULT_GET_QUERY = async (
  curlpage: number | undefined,
  unitpage: number | undefined,
  category_code: string,
  table_code: string,
  stat: string,
  game_no: string,
  from_time: string,
  to_time: string,
  extra_yn: string
) => {
  try {
    const queryParams = qs.stringify({
      cur_page: curlpage,
      unit_page: unitpage,
      category_code,
      table_code,
      stat,
      game_no,
      from_time,
      to_time,
      extra_yn,
    });
    const response: AxiosResponse = await apiClient.get(
      `api/game-result?${queryParams}`
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const GAME_RESULT_SEARCH_GET_QUERY = async (
  curlpage: number | undefined,
  unitpage: number | undefined,
  edit_stat?: string,
  category_code?: string,
  table_code?: string,
  game_no?: string,
  from_time?: number | null,
  to_time?: number | null
) => {
  try {
    const queryParams = qs.stringify({
      cur_page: curlpage,
      unit_page: unitpage,
      edit_stat: edit_stat,
      category_code,
      table_code,
      game_no,
      from_time,
      to_time,
    });
    const response: AxiosResponse = await apiClient.get(
      `api/game-result?${queryParams}`
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const GAME_PROPOSALNUM_GET_QUERY = async (gameId: string) => {
  try {
    const response: AxiosResponse = await apiClient.get(
      `/game/get-proposal-num/${gameId}`
    );
    return response;
  } catch (err) {
    return err;
  }
};
