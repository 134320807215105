import React from 'react';
import { TableSetupList } from './tableSetupList';
import { TableSetupSearch } from './tableSetupSearch';

export const TableSetup = () => {
  return (
    <div>
      <TableSetupSearch />
      <TableSetupList />
    </div>
  );
};
