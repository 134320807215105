import axios, { AxiosResponse } from 'axios';
import { apiClient } from '../apiConfig';
import qs from 'qs';

export interface IBetHistorySearchForm {
  junket_code: string;
  table_code: string;
  gamer_id: string;
  ava_id: string;
  currency_code: string;
  proposal_num: string;
  game_num: string;
  date_type: string;
  from: string;
  to: string;
}

// export interface IBetHistoryResponse {
//   junket_code: string;
//   junket_name: string;
//   bet_id: string;
//   ava_id: string;
//   currency_code: string;
//   game_num: string;
//   table_code: string;
//   gamer_id: string;
//   proposal_num: string;
//   order_num: string;
//   created_at: string;
//   bet_at: string;
//   end_at: string;
//   bet_type: string;
//   bet_amount: number;
//   winloss_amount: number;
//   rolling: number;
//   game_result: string;
// }

export interface IBetHistorySearchFormSample {
  seq: string;
  stat: string;
  bet_type: string;
  bet_amount: number;
  crt_date: string;
}

export interface IBetHistoryResponse {
  game_no: string;
  table_code: string;
  user_id: string;
  parent_id: string;
  feedback_data1: string;
  feedback_data2: string;
  bet_index: string;
  start_date: string;
  bet_date: string;
  end_date: string;
  bet_type: string;
  bet_currency: string;
  bet_amount: number;
  stat: string;
  pcards: string;
  bcards: string;
  category_code: string;
  user_type: string;
  proposal_num: string;
  winlose: number;
  ret_type: string;
  pscore: string;
  bscore: string;
  ppair: number;
  bpair: number;
  balance_before: number;
  balance_after: number;
  edit_type: string;
  junket_code: string; //delete once confirm
  junket_name: string; //delete once confirm
  game_num: string; //delete once confirm
  order_num: string; //delete once confirm
  gamer_id: string; //delete once confirm
}

export interface IBetGetHistory {
  cur_page: number | undefined;
  unit_page: number | undefined;
  category_code: string;
  user_type: string;
  table_code: string;
  user_id: string;
  proposal_num: string;
  feedback_data1: string;
  feedback_data2: string;
  game_no: string;
  stats: number;
  from_time: number | null;
  to_time: number | null;
}

export const BET_HISTORY_POST_QUERY = async (data: IBetGetHistory) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      '/api/ngame-bet-result',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

// OLD GET TABLE DATA
// export const BET_HISTORY_GET_QUERY = async (
//   cur_page: number | undefined,
//   unit_page: number | undefined,
//   category_code: string,
//   table_code: string,
//   game_no: string,
//   from_time: string,
//   to_time: string,
//   extra_yn: string,
//   user_type: string,
//   user_id: string,
//   feedback_data1: string,
//   feedback_data2: string
// ) => {
//   try {
//     const queryParams = qs.stringify({
//       cur_page: cur_page,
//       unit_page: unit_page,
//       category_code,
//       table_code,
//       game_no,
//       from_time,
//       to_time,
//       extra_yn,
//       user_type,
//       user_id,
//       feedback_data1,
//       feedback_data2,
//     });
//     const response: AxiosResponse = await apiClient.get(
//       `api/ngame-bet-result?${queryParams}`
//     );
//     return response;
//   } catch (err) {
//     return err;
//   }
// };

export const BET_HISTORY_SEARCH_GET = async (bet_index: string) => {
  try {
    const queryParams = qs.stringify({
      bet_index: bet_index,
    });
    const response: AxiosResponse = await apiClient.get(
      `/api/ngame-bet-result-detail?${queryParams}`
    );
    return response;
  } catch (err) {
    return err;
  }
};

// ? BetHistory Speed Game Avatar
export interface IBetHistoryAvaResponse {
  bet_index: number;
  table_code: string;
  end_date: string;
  bet_type: string;
  bet_amount: number;
  winlose: number;
  stat: string;
  category_code: string;
  avatar_id: string;
  proposal_num: string;
  game_no: string;
}

export interface IBetHistoryAvaSearch {
  cur_page: number | undefined;
  unit_page: number | undefined;
  category_code: string;
  table_code: string;
  proposal_num: string;
  game_no: string;
  stats: string[];
  from_time: number | null;
  to_time: number | null;
}

export const BET_HISTORY_AVA_POST_QUERY = async (
  data: IBetHistoryAvaSearch
) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      '/api/get-avatar-bet-list',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};
