import { AxiosResponse } from 'axios';
import { apiClient } from '../apiConfig';

export interface IJunketResponse {
  junket_code: string;
  junket_name: string;
}

export interface IJunketRes {
  id: string;
  name: string;
  sort_idx: string;
}

export const JUNKET_GET_QUERY = async () => {
  try {
    const response: AxiosResponse = await apiClient.get('api/bac-categories');
    return response;
  } catch (err) {
    return err;
  }
};
