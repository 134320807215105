import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-hook-form';
import {
  IAvatarTipsMutationHistory,
  ITipsResponse,
  TIPS_EDIT_HISTORY_QUERY,
} from '../../../../common/api/queries/tips_query';

import { GrClose } from 'react-icons/gr';
import { ButtonCustom } from '../../../../components/button';
import { ITIPS_FORM_PATCH } from '../../../../common/api/mutations/tips_mutation';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useReactiveVar } from '@apollo/client';
import { isUpdatedVar } from '../../../../common/apollo';

interface IModalProps {
  setModModal: React.Dispatch<React.SetStateAction<boolean>>;
  selPhoneData: ITipsResponse | null;
}

export const PhoneTipSetupModal: React.FC<IModalProps> = ({
  setModModal,
  selPhoneData,
}) => {
  const { register, getValues, handleSubmit, control } = useForm<ITipsResponse>(
    {
      mode: 'onChange',
    }
  );
  const { isSubmitting } = useFormState({ control });
  const { t } = useTranslation(['page']);
  const [getMutationHistory, setGetMutationHistory] = useState<
    IAvatarTipsMutationHistory[]
  >([]);
  const clickModalClose = () => {
    setModModal((current) => !current);
  };

  const isUpdated = useReactiveVar(isUpdatedVar);

  const columns: ColumnsType<IAvatarTipsMutationHistory> = [
    {
      title: 'No.',
      dataIndex: 'seq',
      fixed: 'left',
      ellipsis: true,
    },
    {
      title: String(t('AMOUNT')),
      dataIndex: 'amount',
      ellipsis: true,
    },
    {
      title: String(t('CREATED AT')),
      dataIndex: 'crt_dt',
      ellipsis: true,
    },
  ];

  const getBetMutationHistory = () => {
    const id = selPhoneData?.id;
    TIPS_EDIT_HISTORY_QUERY(id).then((res: AxiosResponse | any) => {
      if (res.data.data.code === 1) {
        setGetMutationHistory(res.data.data.data.list);
      }
    });
  };

  useEffect(() => {
    getBetMutationHistory();
  }, []);

  const onSubmit = async () => {
    const { amount } = getValues();
    // let alrtPatchRes = false;
    // let alrtPostRes = false;

    const data = {
      id: selPhoneData?.id,
      amount: amount,
      proposal_num: selPhoneData?.proposalNum ?? '',
    };

    // const dataToSend: ITipsUpdate = {
    //   data: [
    //     {
    //       'id': selPhoneData?.id,
    //       'user_id': selPhoneData?.userId,
    //       'parent_id': selPhoneData?.parentId,
    //       'avatar_id': selPhoneData?.avatarId,
    //       'crt_dt': selPhoneData?.crtDt,
    //       'upd_dt': selPhoneData?.updDt,
    //       'tip_type': selPhoneData?.tipType,
    //       'currency': selPhoneData?.currency,
    //       'amount': amount,
    //       'category_code': selPhoneData?.categoryCode,
    //       'table_code': selPhoneData?.tableCode,
    //       'tip_currency': selPhoneData?.tipCurrency,
    //       'proposal_num': selPhoneData?.proposalNum,
    //       'feedBack_data1': selPhoneData?.feedBackData1,
    //       'feedBack_data2': selPhoneData?.feedBackData2,
    //     },
    //   ],
    // };

    await ITIPS_FORM_PATCH(data)
      .then((res: AxiosResponse | any) => {
        if (res.data) {
          if (res.data.data.code === 1) {
            alert(`Updated`);
            isUpdatedVar(!isUpdated);
            setModModal((current) => !current);
          } else if (res.data.data.code === 3) {
            alert(`Insufficient balance.`);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        alert(`Request Failed Please Try Again`);
      });

    // if (alrtPatchRes !== false) {
    //   await ITIPS_FORM_UPDATE(dataToSend)
    //     .then((res: AxiosResponse | any) => {
    //       if (res.data) {
    //         if (res.data.data.data.code === 0) {
    //           alrtPostRes = true;
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       alert(err);
    //     });
    // }
    // if (alrtPatchRes) {
    //   alert(`Updated`);
    //   isUpdatedVar(!isUpdated);
    // } else {
    //   alert(`Request Failed Please Try Again`);
    // }
    // setModModal((current) => !current);
  };

  return (
    <div className='fixed h-auto top-0 right-0 bottom-0 left-0 z-10 backdrop-blur-sm'>
      <div className='box-border block shadow-lg m-5 md:m-24 bg-white top-12'>
        <div className='border rounded-md lg:col-span-2'>
          <div className='font-medium text-lg px-6 py-3 border-b flex flex-row justify-between'>
            <div className='text-blue-500'>{t('Edit Order')}</div>
            <GrClose
              className='mt-1 cursor-pointer'
              onClick={clickModalClose}
            />
          </div>
          <div className='p-6 max-h-[700px] rounded-lg shadow-md bg-white overflow-y-scroll'>
            <Table
              columns={columns}
              rowKey={(record) => record.seq}
              dataSource={getMutationHistory}
              size='small'
              bordered={true}
              pagination={{ pageSize: 5 }}
              scroll={{ x: true }}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='flex flex-wrap justify-end items-center gap-3 mb-5'>
                <div className='grid gap-4 md:grid-cols-2 lg:grid-cols-4 w-full'>
                  <div className='form-group w-full'>
                    <div className='mb-2 text-gray-500 whitespace-nowrap'>
                      {t('ID')}
                    </div>
                    <input
                      type='text'
                      className='forminput'
                      disabled
                      defaultValue={selPhoneData?.id}
                    />
                  </div>
                  <div className='form-group w-full'>
                    <div className='mb-2 text-gray-500 whitespace-nowrap'>
                      {t('Amount')}
                    </div>
                    <input
                      {...register('amount')}
                      type='number'
                      pattern='[0-9]*'
                      className='forminput'
                      defaultValue={selPhoneData?.amount}
                    />
                  </div>
                </div>
              </div>
              <button
                role='button'
                disabled={isSubmitting}
                className='text-white w-full rounded text-lg px-6 py-1.5 focus:outline-none font-semibold whitespace-nowrap transition-colors bg-gray-500 hover:bg-gray-600 cursor-pointer'
              >
                {t('Edit Tip')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
