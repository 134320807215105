import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { tableData } from '../../../../common/apollo';
import { Table } from 'antd';

import { GrClose } from 'react-icons/gr';
import {
  ITableStatusDummyRes,
  ITableStatusRes,
  ITableStatusResArr,
  TABLE_STATUS_SEARCH_QUERY,
} from '../../../../common/api/queries/playerlist_query';
import { ColumnsType } from 'antd/es/table';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';

interface IModalInfoProps {
  setOpenTableInfo: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TableStatusInfo: React.FC<IModalInfoProps> = ({
  setOpenTableInfo: setOpenReg,
}) => {
  const { t } = useTranslation(['page']);
  const selectedTableData = useReactiveVar(tableData);

  const [gamePhoneList, setGamePhoneList] = useState<ITableStatusRes[]>([]);
  const [gameSpeedList, setGameSpeedList] = useState<ITableStatusRes[]>([]);
  const [gameApiList, setGameApiList] = useState<ITableStatusRes[]>([]);
  const [gameDummyList, setGameDummyList] = useState<ITableStatusDummyRes[]>(
    []
  );

  const clickModalClose = () => {
    setOpenReg((current) => !current);
  };

  const columnHelperUpper = createColumnHelper<ITableStatusRes>();
  const columnsTableUpper = [
    columnHelperUpper.accessor('user_id', {
      header: String(t('No.')),
    }),
    columnHelperUpper.accessor('agent_id', {
      header: String(t('Agent ID')),
    }),
    columnHelperUpper.accessor('disp_id', {
      header: String(t('User ID')),
    }),
    columnHelperUpper.accessor('balance', {
      header: String(t('Balance')),
    }),
  ];

  const columnHelperDummy = createColumnHelper<ITableStatusDummyRes>();
  const columnsTableDummy = [
    columnHelperDummy.accessor('user_id', {
      header: String(t('User ID')),
    }),
    columnHelperDummy.accessor('balance', {
      header: String(t('Balance')),
    }),
    columnHelperDummy.accessor('user_type', {
      header: String(t('Type')),
    }),
  ];

  const tablePhone = useReactTable({
    data: gamePhoneList,
    columns: columnsTableUpper,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
  });

  const tableSpeed = useReactTable({
    data: gameSpeedList,
    columns: columnsTableUpper,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
  });

  const tableApi = useReactTable({
    data: gameApiList,
    columns: columnsTableUpper,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
  });

  const tableDummy = useReactTable({
    data: gameDummyList,
    columns: columnsTableDummy,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
  });

  const onRequest = () => {
    TABLE_STATUS_SEARCH_QUERY(String(selectedTableData?.id))
      .then((res: AxiosResponse | any) => {
        if (res.data) {
          if (res.data.data.code === 1) {
            const resdata: ITableStatusResArr = res.data.data.data;
            const { pmembers, smembers, amembers, dummies } = resdata;

            setGamePhoneList(pmembers);
            setGameSpeedList(smembers);
            setGameApiList(amembers);
            setGameDummyList(dummies);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    onRequest();
  }, []);

  return (
    <div
      className='box-border fixed top-0 right-0 bottom-0 left-0 overflow-auto outline-0'
      style={{ zIndex: '910' }}
    >
      <div className='fixed w-full h-full bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center'>
        <div className='border w-full max-w-lg bg-white rounded-md p-5 m-5'>
          <div className='font-medium text-lg px-2 py-3 border-b flex flex-row justify-between'>
            <div className='text-blue-500'>{t('Table User Status')}</div>
            <GrClose
              className='mt-1 cursor-pointer'
              onClick={clickModalClose}
            />
          </div>
          <div className='grid grid-col gap-1'>
            {/* PHONE */}

            <div>
              <p className='text-sm'>{t('Phone')}</p>
              <table className='w-full'>
                <thead className='border-b bg-[#245c81]'>
                  {tablePhone.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className=''>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          className='text-xs text-white px-2 py-1 text-left whitespace-nowrap'
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                {tablePhone.getRowModel().rows.length !== 0 && (
                  <tbody>
                    {tablePhone.getRowModel().rows.map((row, index) => (
                      <tr
                        key={row.id}
                        className={`bg-white border-b border-dotted transition duration-300 ease-in-out hover:bg-gray-300 whitespace-nowrap`}
                      >
                        {row.getVisibleCells().map((cell) => {
                          if (cell.column.columnDef.header === t('Balance')) {
                            return (
                              <td
                                key={cell.id}
                                className='px-2 py-1 text-xs text-left font-xs text-gray-900'
                              >
                                <p>{row.original.balance.toLocaleString()}</p>
                              </td>
                            );
                          } else {
                            return (
                              <td
                                key={cell.id}
                                className='px-2 py-1 text-xs text-left font-xs text-gray-900'
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
              {tablePhone.getRowModel().rows.length === 0 && (
                <p className='w-full text-center'>No Data</p>
              )}
              {tablePhone.getRowModel().rows.length > 10 && (
                <div className='flex flex-wrap items-center justify-center py-2 gap-2 text-xs'>
                  <button
                    className='border rounded p-1'
                    onClick={() => tablePhone.setPageIndex(0)}
                    disabled={!tablePhone.getCanPreviousPage()}
                  >
                    {'<<'}
                  </button>
                  <button
                    className='border rounded p-1'
                    onClick={() => tablePhone.previousPage()}
                    disabled={!tablePhone.getCanPreviousPage()}
                  >
                    {'<'}
                  </button>
                  <button
                    className='border rounded p-1'
                    onClick={() => tablePhone.nextPage()}
                    disabled={!tablePhone.getCanNextPage()}
                  >
                    {'>'}
                  </button>
                  <button
                    className='border rounded p-1'
                    onClick={() =>
                      tablePhone.setPageIndex(tablePhone.getPageCount() - 1)
                    }
                    disabled={!tablePhone.getCanNextPage()}
                  >
                    {'>>'}
                  </button>
                </div>
              )}
            </div>

            {/* SPEED */}

            <div>
              <p className='text-sm'>{t('Speed')}</p>
              <table className='w-full'>
                <thead className='border-b bg-[#245c81]'>
                  {tableSpeed.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className=''>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          className='text-xs text-white px-2 py-1 text-left whitespace-nowrap'
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {tableSpeed.getRowModel().rows.length !== 0 && (
                  <tbody>
                    {tableSpeed.getRowModel().rows.map((row, index) => (
                      <tr
                        key={row.id}
                        className={`bg-white border-b border-dotted transition duration-300 ease-in-out hover:bg-gray-300 whitespace-nowrap`}
                      >
                        {row.getVisibleCells().map((cell) => {
                          if (cell.column.columnDef.header === t('Balance')) {
                            return (
                              <td
                                key={cell.id}
                                className='px-2 py-1 text-xs text-left font-xs text-gray-900'
                              >
                                <p>{row.original.balance.toLocaleString()}</p>
                              </td>
                            );
                          } else {
                            return (
                              <td
                                key={cell.id}
                                className='px-2 py-1 text-xs text-left font-xs text-gray-900'
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
              {tableSpeed.getRowModel().rows.length === 0 && (
                <p className='w-full text-center'>No Data</p>
              )}
              {tableSpeed.getRowModel().rows.length > 10 && (
                <div className='flex flex-wrap items-center justify-center py-2 gap-2 text-xs'>
                  <button
                    className='border rounded p-1'
                    onClick={() => tableSpeed.setPageIndex(0)}
                    disabled={!tableSpeed.getCanPreviousPage()}
                  >
                    {'<<'}
                  </button>
                  <button
                    className='border rounded p-1'
                    onClick={() => tableSpeed.previousPage()}
                    disabled={!tableSpeed.getCanPreviousPage()}
                  >
                    {'<'}
                  </button>
                  <button
                    className='border rounded p-1'
                    onClick={() => tableSpeed.nextPage()}
                    disabled={!tableSpeed.getCanNextPage()}
                  >
                    {'>'}
                  </button>
                  <button
                    className='border rounded p-1'
                    onClick={() =>
                      tableSpeed.setPageIndex(tableSpeed.getPageCount() - 1)
                    }
                    disabled={!tableSpeed.getCanNextPage()}
                  >
                    {'>>'}
                  </button>
                </div>
              )}
            </div>

            {/* API */}

            <div>
              <p className='text-sm'>{t('API')}</p>
              <table className='w-full'>
                <thead className='border-b bg-[#245c81]'>
                  {tableApi.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className=''>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          className='text-xs text-white px-2 py-1 text-left whitespace-nowrap'
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {tableApi.getRowModel().rows.length !== 0 && (
                  <tbody>
                    {tableApi.getRowModel().rows.map((row, index) => (
                      <tr
                        key={row.id}
                        className={`bg-white border-b border-dotted transition duration-300 ease-in-out hover:bg-gray-300 whitespace-nowrap`}
                      >
                        {row.getVisibleCells().map((cell) => {
                          if (cell.column.columnDef.header === t('Balance')) {
                            return (
                              <td
                                key={cell.id}
                                className='px-2 py-1 text-xs text-left font-xs text-gray-900'
                              >
                                <p>{row.original.balance.toLocaleString()}</p>
                              </td>
                            );
                          } else {
                            return (
                              <td
                                key={cell.id}
                                className='px-2 py-1 text-xs text-left font-xs text-gray-900'
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
              {tableApi.getRowModel().rows.length === 0 && (
                <p className='w-full text-center'>No Data</p>
              )}
              {tableApi.getRowModel().rows.length > 10 && (
                <div className='flex flex-wrap items-center justify-center py-2 gap-2 text-xs'>
                  <button
                    className='border rounded p-1'
                    onClick={() => tableApi.setPageIndex(0)}
                    disabled={!tableApi.getCanPreviousPage()}
                  >
                    {'<<'}
                  </button>
                  <button
                    className='border rounded p-1'
                    onClick={() => tableApi.previousPage()}
                    disabled={!tableApi.getCanPreviousPage()}
                  >
                    {'<'}
                  </button>
                  <button
                    className='border rounded p-1'
                    onClick={() => tableApi.nextPage()}
                    disabled={!tableApi.getCanNextPage()}
                  >
                    {'>'}
                  </button>
                  <button
                    className='border rounded p-1'
                    onClick={() =>
                      tableApi.setPageIndex(tableApi.getPageCount() - 1)
                    }
                    disabled={!tableApi.getCanNextPage()}
                  >
                    {'>>'}
                  </button>
                </div>
              )}
            </div>

            {/* DUMMY */}

            <div>
              <p className='text-sm'>{t('Dummy')}</p>
              <table className='w-full'>
                <thead className='border-b bg-[#245c81]'>
                  {tableDummy.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className=''>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          className='text-xs text-white px-2 py-1 text-left whitespace-nowrap'
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {tableDummy.getRowModel().rows.length !== 0 && (
                  <tbody>
                    {tableDummy.getRowModel().rows.map((row, index) => (
                      <tr
                        key={row.id}
                        className={`bg-white border-b border-dotted transition duration-300 ease-in-out hover:bg-gray-300 whitespace-nowrap`}
                      >
                        {row.getVisibleCells().map((cell) => {
                          if (cell.column.columnDef.header === t('Balance')) {
                            return (
                              <td
                                key={cell.id}
                                className='px-2 py-1 text-xs text-left font-xs text-gray-900'
                              >
                                <p>{row.original.balance.toLocaleString()}</p>
                              </td>
                            );
                          } else {
                            return (
                              <td
                                key={cell.id}
                                className='px-2 py-1 text-xs text-left font-xs text-gray-900'
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
              {tableDummy.getRowModel().rows.length === 0 && (
                <p className='w-full text-center'>No Data</p>
              )}
              {tableDummy.getRowModel().rows.length > 10 && (
                <div className='flex flex-wrap items-center justify-center py-2 gap-2 text-xs'>
                  <button
                    className='border rounded p-1'
                    onClick={() => tableDummy.setPageIndex(0)}
                    disabled={!tableDummy.getCanPreviousPage()}
                  >
                    {'<<'}
                  </button>
                  <button
                    className='border rounded p-1'
                    onClick={() => tableDummy.previousPage()}
                    disabled={!tableDummy.getCanPreviousPage()}
                  >
                    {'<'}
                  </button>
                  <button
                    className='border rounded p-1'
                    onClick={() => tableDummy.nextPage()}
                    disabled={!tableDummy.getCanNextPage()}
                  >
                    {'>'}
                  </button>
                  <button
                    className='border rounded p-1'
                    onClick={() =>
                      tableDummy.setPageIndex(tableDummy.getPageCount() - 1)
                    }
                    disabled={!tableDummy.getCanNextPage()}
                  >
                    {'>>'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
