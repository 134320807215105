import React, { useEffect, useState, FocusEvent } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { GrClose } from 'react-icons/gr';
import {
  IBET_FORM_REGISTER_POST,
  IBetRegisterForm,
} from '../../../../common/api/mutations/bet_mutation';
import { ButtonCustom } from '../../../../components/button';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { useReactiveVar } from '@apollo/client';
import { isUpdatedVar } from '../../../../common/apollo';
import {
  GAME_PROPOSALNUM_GET_QUERY,
  GAME_RESULT_SEARCH_GET_QUERY,
  IGameResultRes,
} from '../../../../common/api/queries/game_query';

interface IModalProps {
  setRegModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RGameBetRegModal: React.FC<IModalProps> = ({ setRegModal }) => {
  const { register, getValues, handleSubmit, control } =
    useForm<IBetRegisterForm>({
      mode: 'onChange',
    });

  const { t } = useTranslation(['page']);
  const { isSubmitting } = useFormState({ control });
  const [betData, setBetData] = useState<IGameResultRes[]>([]);
  const [gameProposalData, setGameProposalData] = useState('');

  const isUpdated = useReactiveVar(isUpdatedVar);

  const clickModalClose = () => {
    setRegModal((current) => !current);
  };

  const handleInputGameNo = (event: FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value !== '') {
      GAME_RESULT_SEARCH_GET_QUERY(1, 1, undefined, undefined, undefined, value)
        .then((res: AxiosResponse | any) => {
          if (res.data) {
            if (res.data.data.code === 1) {
              setBetData(res.data.data.data.list);
            } else {
              setBetData([]);
              alert(`Please Input Correct Game Number`);
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const handleInputGameId = (event: FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value !== '') {
      GAME_PROPOSALNUM_GET_QUERY(value)
        .then((res: AxiosResponse | any) => {
          if (res.data) {
            if (res.data.data !== null) {
              setGameProposalData(res.data.data);
            } else {
              setGameProposalData('');
              alert(`Please Input Correct User ID`);
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const onSubmit = () => {
    const { game_no, disp_id, bet_type, bet_amount } = getValues();

    const data = {
      category_code: betData[0]?.category_code,
      table_code: betData[0]?.table_code,
      bet_result_id: betData[0]?.id,
      proposal_num: gameProposalData,
      feedback_data1: gameProposalData,
      game_no: game_no,
      disp_id: disp_id,
      bet_type: bet_type,
      bet_amount: bet_amount,
    };

    if (
      betData[0]?.category_code &&
      betData[0]?.table_code &&
      betData[0]?.id &&
      gameProposalData
    ) {
      IBET_FORM_REGISTER_POST(data)
        .then((res: AxiosResponse | any) => {
          if (res.data) {
            if (res.data.data.code === 1) {
              alert(`REGISTER SUCCESS`);
              isUpdatedVar(!isUpdated);
              setRegModal((current) => !current);
            } else {
              alert(`TRY AGAIN`);
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      alert(`Invalid Game Number or User ID`);
    }
  };

  return (
    <div className='fixed h-auto top-0 right-0 bottom-0 left-0 z-10 backdrop-blur-sm'>
      <div className='box-border block shadow-lg m-5 md:m-24 bg-white top-12'>
        <div>
          <div className='border rounded-md lg:col-span-2'>
            <div className='font-medium text-lg px-6 py-3 border-b flex flex-row justify-between'>
              <div className='text-blue-500'>{t('Create Phone Bet')}</div>
              <GrClose
                className='mt-1 cursor-pointer'
                onClick={clickModalClose}
              />
            </div>
            <div className='p-6 max-h-[700px] rounded-lg shadow-md bg-white overflow-y-scroll'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='flex flex-wrap justify-end items-center gap-3 mb-5'>
                  <div className='grid gap-4 md:grid-cols-2 lg:grid-cols-4 w-full'>
                    <div className='form-group w-full'>
                      <div className='mb-2 text-gray-500 whitespace-nowrap'>
                        {t('Junket Name')}
                      </div>
                      <input
                        type='text'
                        disabled
                        defaultValue={betData[0]?.category_code}
                        className='forminput bg-gray-100'
                      />
                    </div>
                    <div className='form-group w-full'>
                      <div className='mb-2 text-gray-500 whitespace-nowrap'>
                        {t('Table Number')}
                      </div>
                      <input
                        type='text'
                        disabled
                        defaultValue={betData[0]?.table_code}
                        className='forminput bg-gray-100'
                      />
                    </div>
                    <div className='form-group'>
                      <div className='mb-2 text-gray-500 whitespace-nowrap'>
                        {t('Proposal Number')}
                      </div>
                      <input
                        type='text'
                        disabled
                        defaultValue={gameProposalData}
                        className='forminput bg-gray-100'
                      />
                    </div>
                    <div className='form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500 whitespace-nowrap'>
                          {t('Game ID')}
                        </div>
                      </div>
                      <input
                        type='text'
                        disabled
                        defaultValue={betData[0]?.id}
                        className='forminput bg-gray-100'
                      />
                    </div>
                  </div>
                </div>
                <div className='grid md:grid-cols-4 gap-4 mb-6'>
                  <div className='form-group'>
                    <div className='mb-2 text-gray-500 whitespace-nowrap'>
                      {t('Game Number')}
                    </div>
                    <input
                      {...register('game_no')}
                      required
                      type='text'
                      className='forminput'
                      onBlur={handleInputGameNo}
                      placeholder={String(t('Game Number'))}
                    />
                  </div>
                  <div className='form-group'>
                    <div className='flex flex-row'>
                      <div className='mb-2 text-gray-500 whitespace-nowrap'>
                        {t('User ID')}
                      </div>
                    </div>
                    <input
                      {...register('disp_id')}
                      type='text'
                      required
                      className='forminput'
                      onBlur={handleInputGameId}
                      placeholder={String(t('User ID'))}
                    />
                  </div>
                  <div className='form-group'>
                    <div className='flex flex-row'>
                      <div className='mb-2 text-gray-500 whitespace-nowrap'>
                        {t('Bet Type')}
                      </div>
                    </div>
                    <select
                      {...register('bet_type')}
                      required
                      className='forminput'
                    >
                      <option value=''>{t('Select')}</option>
                      <option value='B'>B</option>
                      <option value='P'>P</option>
                      <option value='BP'>BP</option>
                      <option value='PP'>PP</option>
                      <option value='T'>T</option>
                    </select>
                  </div>
                  <div className='form-group'>
                    <div className='flex flex-row'>
                      <div className='mb-2 text-gray-500 whitespace-nowrap'>
                        {t('Bet Amount')}
                      </div>
                    </div>
                    <input
                      {...register('bet_amount')}
                      type='number'
                      required
                      min={0}
                      className='forminput'
                      placeholder={String(t('Bet Amount'))}
                    />
                  </div>
                </div>
                <ButtonCustom
                  canClick={!isSubmitting}
                  actionText={t('Create Phone Bet')}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
