import { AxiosResponse } from 'axios';
import { apiClient } from '../apiConfig';

export interface IDummyUserRes {
  user_id: string;
  range_from: number;
  range_to: number;
  range_step: number;
  ref: number;
}

export const DUMMY_USER_GET_QUERY = async () => {
  try {
    const response: AxiosResponse = await apiClient.get(
      'api/dummy/get_supporter'
    );
    return response;
  } catch (err) {
    return err;
  }
};
