import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import {
  ITipsResponse,
  ITipsSearchForm,
  TIPS_GET_QUERY,
} from '../../../common/api/queries/tips_query';
import {
  IJunketRes,
  JUNKET_GET_QUERY,
} from '../../../common/api/queries/junket_query';
import {
  ITableResponse,
  TABLES_GET_QUERY,
} from '../../../common/api/queries/table_query';
import {
  gameTableList,
  isUpdatedVar,
  junketList,
  langVar,
  routeTitleVar,
} from '../../../common/apollo';

import { ButtonCustom } from '../../../components/button';
import DatePicker from 'react-datepicker';
import type { TablePaginationConfig } from 'antd/es/table';
import { Button, Space } from 'antd';
import SpeedTableList from './speedTipList';

export interface TableParams {
  pagination: TablePaginationConfig;
}

export const SpeedTipSearch = () => {
  const { register, getValues, handleSubmit } = useForm<ITipsSearchForm>({
    mode: 'onChange',
  });
  const { t } = useTranslation(['page']);
  const [data, setData] = useState<ITipsResponse[]>();
  const [loading, setLoading] = useState(false);
  const selectedLang = useReactiveVar(langVar);
  const gameTable = useReactiveVar(gameTableList);
  const junketLists = useReactiveVar(junketList);
  const [selectedJunket, setSelectedJunket] = useState<string>('');
  const [selectedGameTable, setSelectedGameTable] = useState<string>('');
  const [filteredGameTableList, setFilteredGameTableList] = useState<
    ITableResponse[]
  >([]);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const isUpdated = useReactiveVar(isUpdatedVar);

  const convertToEpochTime = (date: Date | null): number | null => {
    if (date === null) {
      return null;
    }
    const milliseconds = date.getTime();
    const seconds = Math.floor(milliseconds / 1000);

    return seconds;
  };

  useEffect(() => {
    if (selectedJunket) {
      const filteredTables = gameTable.filter(
        (table) => table.category_code === selectedJunket
      );
      setFilteredGameTableList(filteredTables);
    } else {
      setFilteredGameTableList(gameTable);
    }
  }, [selectedJunket, gameTable]);

  useEffect(() => {
    if (selectedGameTable) {
      const matchingJunket = gameTable.find(
        (table) => table.table_code === selectedGameTable
      );
      if (matchingJunket) {
        setSelectedJunket(matchingJunket.category_code);
      }
    }
  }, [selectedGameTable, gameTable]);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setTableParams({
      pagination,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const onSubmit = () => {
    const { categoryCode, tableCode, userId, avatarId, proposalNum, currency } =
      getValues();

    const from = convertToEpochTime(startDate);
    const to = convertToEpochTime(endDate);

    const { current: cur_page, pageSize: unit_page } =
      tableParams.pagination || {};

    const data = {
      userId: userId,
      avatarId: avatarId,
      proposalNum: proposalNum,
      tipType: 'E',
      currency: currency,
      categoryCode: selectedJunket,
      tableCode: selectedGameTable,
      from: from,
      to: to,
      feedBackData1: '',
      curPage: cur_page,
      unitPage: unit_page,
    };
    setLoading(true);
    TIPS_GET_QUERY(data)
      .then((res: AxiosResponse | any) => {
        if (res.data.data.code === 0) {
          setData(res.data.data.content);
          setLoading(false);
          setTableParams((prevTableParams) => ({
            ...prevTableParams,
            pagination: {
              ...prevTableParams.pagination,
              total: res.data.data.totalElements,
            },
          }));
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
        setLoading(false);
      });
  };

  useEffect(() => {
    onSubmit();
  }, [
    tableParams.pagination.current,
    tableParams.pagination.pageSize,
    isUpdated,
  ]);

  useEffect(() => {
    routeTitleVar(String(t('Speed Tip List')));
  }, [selectedLang]);

  return (
    <>
      <div className='p-6 rounded-lg shadow-lg bg-white'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-wrap justify-end items-center gap-3 mb-5'>
            <div className='flex flex-row gap-3 w-full sm:w-auto justify-between'>
              <div className='form-group w-full'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Junket')}
                </div>
                <select
                  className='forminput'
                  value={selectedJunket}
                  onChange={(e) => {
                    setSelectedJunket(e.target.value);
                    setSelectedGameTable('');
                  }}
                >
                  <option value=''>{t('Entire')}</option>
                  {junketLists.map((junket) => (
                    <option key={junket.id} value={junket.id}>
                      {junket.name.charAt(0).toUpperCase() +
                        junket.name.slice(1).toLowerCase()}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group w-full'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Table Number')}
                </div>
                <select
                  className='forminput'
                  value={selectedGameTable}
                  onChange={(e) => setSelectedGameTable(e.target.value)}
                >
                  <option value=''>{t('Entire')}</option>
                  {filteredGameTableList.map((table) => (
                    <option key={table.id} value={table.table_code}>
                      {table.table_code}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group w-full'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Currency')}
                </div>
                <select {...register('currency')} className='forminput'>
                  <option value='PHP'>PHP</option>
                </select>
              </div>
            </div>

            <div className='flex flex-row gap-3 w-full sm:w-auto'>
              <div className='form-group w-full'>
                <div className='mb-2 text-gray-500'>{t('User') + ' ID'}</div>
                <input
                  {...register('userId')}
                  type='text'
                  className='forminput'
                  placeholder={String(t('회원') + ' ID ' + t('입력'))}
                />
              </div>
              <div className='form-group w-full'>
                <div className='mb-2 text-gray-500'>{t('Proposal Number')}</div>
                <input
                  {...register('proposalNum')}
                  type='text'
                  className='forminput'
                  placeholder={String(t('Enter Proposal Number'))}
                />
              </div>
              <div className='form-group w-full'>
                <div className='mb-2 text-gray-500'>{t('Avatar ID')}</div>
                <input
                  {...register('avatarId')}
                  type='text'
                  className='forminput'
                  placeholder={String(t('Avatar ID'))}
                />
              </div>
            </div>

            <div className='flex w-full sm:w-auto flex-row md:flex-col items-center md:items-start pt-6 md:pt-0'>
              <span className='mb-2 w-12'>{t('From')}:</span>
              <div className='customDatePickerWidth'>
                <DatePicker
                  className='forminput pr-7'
                  selected={startDate}
                  onChange={(date: Date) => setStartDate(date)}
                  isClearable
                  showTimeSelect
                  timeFormat='HH:mm'
                  timeIntervals={60}
                  timeCaption='Hour'
                  dateFormat={'yyyy/MM/dd HH:mm'}
                  placeholderText={String(t('Starting Point'))}
                />
              </div>
            </div>

            <div className='flex w-full sm:w-auto flex-row md:flex-col items-center md:items-start pt-6 md:pt-0'>
              <span className='mb-2 w-12'>{t('To')}:</span>
              <div className='customDatePickerWidth'>
                <DatePicker
                  className='forminput pr-7'
                  selected={endDate}
                  onChange={(date: Date) => setEndDate(date)}
                  isClearable
                  showTimeSelect
                  timeFormat='HH:mm'
                  timeIntervals={60}
                  timeCaption='Hour'
                  dateFormat={'yyyy/MM/dd HH:mm'}
                  placeholderText={String(t('End Point'))}
                />
              </div>
            </div>

            <div className='flex flex-row gap-3 w-full sm:w-auto pt-6'>
              <div className='flex'>
                <ButtonCustom canClick={true} actionText={t('Search')} />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className='border-l border-r rounded-md mt-5 shadow-lg'>
        <div className='flex justify-between p-4 rounded-md'>
          <div>
            <h1 className=' text-lg font-semibold'> {t('Speed Tip List')}</h1>
            <p className=' text-xs'>{t('Add & Edit')}</p>
          </div>
          {/* <div className='px-6'>
            <button className='text-white w-full rounded text-lg px-6 py-1.5 focus:outline-none font-semibold whitespace-nowrap transition-colors bg-gray-500 hover:bg-gray-600'>
              {t('Add Tip')}
            </button>
          </div> */}
        </div>
        <SpeedTableList
          data={data}
          tableParams={tableParams}
          handleTableChange={handleTableChange}
          loading={loading}
        />
      </div>
    </>
  );
};
