import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';

import { IBetHistoryResponse } from '../../../common/api/queries/bethistory_query';
import { betData, isUpdatedVar } from '../../../common/apollo';

import { Button, Space, Table, Tag, Popconfirm } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';

import {
  IBET_FORM_CANCEL_PATCH,
  IBET_FORM_PATCH,
} from '../../../common/api/mutations/bet_mutation';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { betImageMapping } from '../../../components/betComponents/bet-images';
import { betStatsMultiLang } from '../../../components/betComponents/betStatus-combination';
import { SoGamePopConfirm } from './modal/soGamePopConfirm';

interface TableListProps {
  data: IBetHistoryResponse[] | undefined;
  tableParams: {
    pagination: TablePaginationConfig;
  };
  handleTableChange: (pagination: TablePaginationConfig) => void;
  loading: boolean;
}

const SoGameBetList: React.FC<TableListProps> = ({
  data,
  tableParams,
  handleTableChange,
  loading,
}) => {
  const { t } = useTranslation(['page']);

  const toStayPage = () => {
    const toSubmit: TablePaginationConfig = {
      ...tableParams.pagination,
    };
    toOnChangeTable(toSubmit);
  };

  const toOnChangeTable = (pagination: TablePaginationConfig) => {
    handleTableChange(pagination);
  };

  const columns: ColumnsType<IBetHistoryResponse> = [
    {
      key: 'bet_index',
      title: 'No.',
      dataIndex: 'bet_index',
      fixed: 'left',
      ellipsis: true,
      onCell: (record, index) => ({
        style: {
          background:
            record.edit_type === 'C' ||
            (record.edit_type === 'M' && record.stat === 'CR')
              ? '#fee2e2'
              : // : (index as number) % 2 === 0
                // ? '#f8f9fa'
                '',
        },
      }),
      render: (_, record) => (
        <SoGamePopConfirm
          title='Update Record'
          description='Are you sure you want to update this record?'
          record={record}
          toReqCurPage={toStayPage}
        />
      ),
    },
    {
      title: String(t('User ID')),
      dataIndex: 'user_id',
      ellipsis: true,
    },
    {
      title: String(t('Game Number')),
      dataIndex: 'game_no',
      ellipsis: true,
    },
    {
      title: String(t('Proposal Number')),
      dataIndex: 'proposal_num',
      ellipsis: true,
    },
    {
      title: String(t('Table Number')),
      dataIndex: 'table_code',
      ellipsis: true,
      align: 'center',
    },
    {
      title: String(t('Bet Currency')),
      dataIndex: 'bet_currency',
      ellipsis: true,
      align: 'center',
    },
    {
      title: String(t('Status')),
      dataIndex: 'stat',
      ellipsis: true,
      render: (stat: string) => <p>{t(betStatsMultiLang[stat])}</p>,
    },
    {
      key: 'bet_type',
      title: String(t('Bet Type')),
      dataIndex: 'bet_type',
      ellipsis: true,
      align: 'center',
      render: (bet_type: string) => (
        <div className='flex items-center justify-center'>
          <img
            style={{ height: '20px', opacity: '0.8' }}
            src={betImageMapping[bet_type]}
          />
        </div>
      ),
      // render: (bet: string) => (
      //   <Tag
      //     color={
      //       bet === 'P' || bet === 'P.P'
      //         ? 'blue'
      //         : bet === 'B' || bet === 'B.P'
      //         ? 'red'
      //         : bet === 'T'
      //         ? 'green'
      //         : 'pink'
      //     }
      //     style={{ width: '35px', textAlign: 'center' }}
      //     key={bet}
      //   >
      //     {bet}
      //     {!bet && 'N/A'}
      //   </Tag>
      // ),
    },
    {
      title: String(t('Balance Before')),
      dataIndex: 'balance_before',
      ellipsis: true,
      align: 'right',
      render: (balancBefore: number) => (
        <>{balancBefore ? balancBefore.toLocaleString() : 0}</>
      ),
    },
    {
      title: String(t('Bet Amount')),
      dataIndex: 'bet_amount',
      ellipsis: true,
      align: 'right',
      render: (amountBet: number) => <>{amountBet.toLocaleString()}</>,
    },
    {
      title: String(t('P Score')),
      dataIndex: 'pscore',
      ellipsis: true,
      align: 'right',
    },
    {
      title: String(t('B Score')),
      dataIndex: 'bscore',
      ellipsis: true,
      align: 'right',
    },
    {
      title: String(t('Balance After')),
      dataIndex: 'balance_after',
      ellipsis: true,
      align: 'right',
      render: (balanceAfter: number) => (
        <>{balanceAfter ? balanceAfter.toLocaleString() : 0}</>
      ),
    },
    {
      title: String(t('Result Type')),
      dataIndex: 'ret_type',
      ellipsis: true,
      align: 'center',
      render: (ret_type: string, record) => (
        <div className='flex items-center justify-center'>
          <img
            style={{ height: '20px', opacity: '0.8' }}
            src={
              betImageMapping[
                `${ret_type} ${record.ppair} ${record.bpair}`.replace(
                  /\s+/g,
                  ''
                )
              ]
            }
          />
        </div>
      ),
      // render: (ret: string) => (
      //   <Tag
      //     color={
      //       ret === 'P' || ret === 'P.P'
      //         ? 'blue'
      //         : ret === 'B' || ret === 'B.P'
      //         ? 'red'
      //         : ret === 'T'
      //         ? 'green'
      //         : 'pink'
      //     }
      //     style={{ width: '35px', textAlign: 'center' }}
      //     key={ret}
      //   >
      //     {ret}
      //     {!ret && 'N/A'}
      //   </Tag>
      // ),
    },
    {
      title: String(t('Win/Lose')),
      dataIndex: 'winlose',
      ellipsis: true,
      align: 'right',
      render: (wl: number) => (
        <p className={`${wl < 0 ? 'text-red-400' : 'text-black'}`}>
          {wl.toLocaleString()}
        </p>
      ),
    },
    {
      title: String(t('Edit Type')),
      dataIndex: 'edit_type',
      ellipsis: true,
      align: 'center',
    },
    {
      title: String(t('Bet Date')),
      dataIndex: 'bet_date',
      ellipsis: true,
    },
    {
      title: String(t('Start Date')),
      dataIndex: 'start_date',
      ellipsis: true,
    },
    {
      title: String(t('End Date')),
      dataIndex: 'end_date',
      ellipsis: true,
    },
  ];

  return (
    <>
      <Table
        rowClassName={(record, index) =>
          record.edit_type === 'C' ||
          (record.edit_type === 'M' && record.stat === 'CR')
            ? 'bg-[#fee2e2]'
            : // : (index as number) % 2 === 0
              // ? 'bg-[#f8f9fa]'
              ''
        }
        columns={columns}
        rowKey={(record) => record.bet_index}
        dataSource={data}
        size='small'
        bordered={true}
        pagination={{
          ...tableParams.pagination,
          position: ['bottomCenter'],
        }}
        loading={loading}
        onChange={toOnChangeTable}
        scroll={{ x: true }}
      />
    </>
  );
};

export default SoGameBetList;
