import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  currencyList,
  isUpdatedVar,
  langVar,
  routeTitleVar,
} from '../../../common/apollo';
import { AiFillEdit } from 'react-icons/ai';
import { CurrencyModModal } from './modal/currencyModModal';
import { CurrencyRegisterModal } from './modal/currencyRegisterModal';
import {
  CURRENCY_GET_QUERY,
  ICurrencyResponse,
} from '../../../common/api/queries/currency_query';

export const CurrencySetupList = () => {
  const { t } = useTranslation(['page']);
  const selectedLang = useReactiveVar(langVar);
  const isUpdated = useReactiveVar(isUpdatedVar);
  const data = useReactiveVar(currencyList);
  const [openSetup, setOpenSetup] = useState(false);
  const [openReg, setOpenReg] = useState(false);
  const [selectData, setSelectData] = useState<ICurrencyResponse | null>(null);

  const onClickUpdateModal = (data: ICurrencyResponse) => {
    setOpenSetup(!openSetup);
    setSelectData(data);
  };

  const getCurrencyList = () => {
    CURRENCY_GET_QUERY().then((res: AxiosResponse | any) => {
      if (res.data) {
        currencyList(res.data.data.data.list);
      }
    });
  };

  useEffect(() => {
    getCurrencyList();
  }, [isUpdated]);

  const columnHelper = createColumnHelper<ICurrencyResponse>();
  const columns = [
    columnHelper.accessor('currency', {
      header: String(t('Currency')),
    }),
    columnHelper.accessor('rate', {
      header: String(t('Currency Rate')),
    }),
  ];

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    routeTitleVar(String(t('Currency Setup')));
  }, [selectedLang]);
  return (
    <div className='border-r border-l rounded-md shadow-lg mt-5'>
      <div className='flex flex-row justify-between items-center border-b'>
        <div className='py-3 px-6'>
          <div className='text-gray-500 font-semibold text-lg'>
            {t('Currency Setup')}
          </div>
          <div className='text-gray-400'>{t('Inquiry & Settings')}</div>
        </div>
        <div className='py-3 px-6'>
          <button
            className='text-white w-full rounded text-lg px-6 py-1.5 focus:outline-none font-semibold whitespace-nowrap transition-colors bg-gray-500 hover:bg-gray-600'
            onClick={() => setOpenReg(!openReg)}
          >
            {t('Create Currency')}
          </button>
        </div>
      </div>
      <div className='flex flex-col bg-white'>
        <div className='overflow-x-auto'>
          <div className='min-w-full'>
            <table className='w-full'>
              <thead className='border-b bg-[#245c81]'>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className=''>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className='text-sm font-bold text-white px-2 py-2 text-left whitespace-nowrap'
                      >
                        <div className='text-center'>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </div>
                      </th>
                    ))}
                    <th
                      style={{ cursor: 'default' }}
                      className='text-sm font-bold text-white px-2 py-2 text-center'
                    >
                      {t('Edit')}
                    </th>
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row, index) => (
                  <tr
                    key={row.id}
                    className={`bg-white border-b transition duration-300 ease-in-out hover:bg-gray-300 whitespace-nowrap`}
                  >
                    {row.getVisibleCells().map((cell) => {
                      if (cell.column.columnDef.header === t('Currency Rate')) {
                        return (
                          <td key={cell.id} className='text-center text-xs'>
                            <p>{row.original.rate.toLocaleString()}</p>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            key={cell.id}
                            className='px-4 py-2 text-xs text-center font-base text-gray-900'
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      }
                    })}
                    <td className='p-2 flex items-center justify-center'>
                      <div className='p-2 hover:bg-gray-100 rounded cursor-pointer'>
                        <AiFillEdit
                          className='text-base'
                          onClick={() => onClickUpdateModal(row.original)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {openReg ? <CurrencyRegisterModal setOpenReg={setOpenReg} /> : null}
      {openSetup ? (
        <CurrencyModModal
          setOpenSetup={setOpenSetup}
          currencySetupData={selectData}
        />
      ) : null}
    </div>
  );
};
