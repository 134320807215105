import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { INoticeResponse } from '../../../../common/api/queries/notice_query';
import { SCROLL_NOTICE_PATCH } from '../../../../common/api/mutations/notice_mutation';
import { isUpdatedVar } from '../../../../common/apollo';
import { useReactiveVar } from '@apollo/client';
import { GrClose } from 'react-icons/gr';
import { ButtonCustom } from '../../../../components/button';

interface IModalSetupProps {
  setOpenReg: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChipRegisterModal: React.FC<IModalSetupProps> = ({
  setOpenReg,
}) => {
  const { register, getValues, handleSubmit, control } =
    useForm<INoticeResponse>({
      mode: 'onChange',
    });
  const { isSubmitting } = useFormState({ control });
  const { t } = useTranslation(['page']);
  const isUpdated = useReactiveVar(isUpdatedVar);
  const [ratioVal, setRatioVal] = useState(1);
  const [checkedValues, setCheckedValues] = useState<number[]>([]);

  const handleCheckboxChange = (option: number) => {
    const updatedValues = checkedValues.includes(option)
      ? checkedValues.filter((value) => value !== option)
      : [...checkedValues, option];

    setCheckedValues(updatedValues);
  };

  const clickModalClose = () => {
    setOpenReg((current) => !current);
  };

  const onSubmit = () => {
    // const { lang, disp_yn, text } = getValues();
    const multipliedValues = checkedValues.map((value) =>
      ratioVal >= 1 ? value * ratioVal : value
    );
    // const data = {
    //   lang: lang,
    //   disp_yn: ratioVal,
    //   text: multipliedValues,
    // };
    // console.log(data);
  };

  return (
    <div
      className='box-border fixed top-0 right-0 bottom-0 left-0 z-40 overflow-auto outline-0'
      style={{ zIndex: '910' }}
    >
      <div className='fixed w-full h-full bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center overflow-y-auto'>
        <div className='border w-4/5 bg-white rounded-md lg:col-span-2 p-5'>
          <div className='font-medium text-lg px-6 py-3 border-b flex flex-row justify-between'>
            <div className='text-blue-500'>{t('Currency Setup')}</div>
            <GrClose
              className='mt-1 cursor-pointer'
              onClick={clickModalClose}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-wrap gap-4 my-10'>
              <div className='form-group w-1/5'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Currency')}
                </div>
                <input className='forminput' />
              </div>
              <div className='form-group w-1/5'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Ratio')}
                </div>
                <input
                  type='number'
                  className='forminput'
                  min='1'
                  maxLength={6}
                  placeholder={String(ratioVal)}
                  onChange={(e) => setRatioVal(Number(e.target.value))}
                />
              </div>

              <ButtonCustom
                canClick={!isSubmitting}
                actionText={t('화폐 등록')}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

// import React from 'react';

// export const ChipRegisterModal = () => {
//   return <div>ChipRegisterModal</div>;
// };
