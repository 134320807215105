import { AxiosResponse } from 'axios';
import { apiClient, apiDetailClient } from '../apiConfig';

export interface ICardListPatch {
  id: number;
  type: string;
  p1card: string;
  p2card: string;
  p3card: string | null | undefined;
  b1card: string;
  b2card: string;
  b3card: string | null | undefined;
  game_no: string | undefined;
}

// BULK UPDATE INTERFACE
// export interface IGameResultEditRes {
//   data: IGameResultEditData;
// }
// export interface IGameResultEditData {
//   code: number;
//   data: IGameResultEditDataDetails;
// }
// export interface IGameResultEditDataDetails {
//   bet_result_id: number;
//   pcards: string[];
//   bcards: string[];
//   bet: string;
//   ppair: number;
//   bpair: number;
//   bscore: number;
//   pscore: number;
//   list: IGameResultEditBetResultDetails[];
// }
// export interface IGameResultEditBetResultDetails {
//   bet: string;
//   bet_index: number;
//   amount: number;
//   winlose: number;
//   stat: string;
// }
// export interface IBetBulkUpdate {
//   data: IBetBulkUpdateData[];
// }
// export interface IBetBulkUpdatePost {
//   data: IBetBulkUpdateData[];
// }
// export interface IBetBulkUpdateData {
//   bet_index: number;
//   bet_type: string;
//   bet_amount: number;
//   winlose: number;
//   stat: string;
//   pcards: string;
//   bcards: string;
//   pscore: number;
//   bscore: number;
// }

// BULK CANCEL INTERFACE
// export interface IGameResultCancelRes {
//   data: IGameResultCancelData;
// }
// export interface IGameResultCancelData {
//   code: number;
//   data: IGameResultCancelDataDetails;
// }
// export interface IGameResultCancelDataDetails {
//   bet_result_id: number;
//   type: string;
//   list: IGameResultCancelBetResultDetails[];
// }
// export interface IGameResultCancelBetResultDetails {
//   bet: string;
//   bet_index: number;
//   amount: number;
//   winlose: number;
//   type: string;
// }
// //
// export interface IBetBulkClosed {
//   data: IBetBulkClosedData[];
// }
// export interface IBetBulkClosedPost {
//   data: IBetBulkClosedData[];
// }
// export interface IBetBulkClosedData {
//   bet_index: number;
//   bet_type: string;
//   stat: string;
// }

export const GAME_RESULT_DETAIL_PATCH = async (data: ICardListPatch) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.patch(
      '/api/game-result-detail',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

// INTERNAL SERVER BULK UPDATE

// export const BULK_BET_RESULT_UPDATE = async (
//   data: IGameResultEditDataDetails
// ) => {
//   const payloadData: IBetBulkUpdateData[] = data.list.map((elem) => {
//     return {
//       bet_index: elem.bet_index,
//       bet_type: elem.bet,
//       bet_amount: elem.amount,
//       winlose: elem.winlose,
//       stat: elem.stat,
//       pcards: data.pcards.join(),
//       bcards: data.bcards.join(),
//       pscore: data.pscore,
//       bscore: data.bscore,
//     };
//   });
//   try {
//     const payload: IBetBulkUpdatePost = { data: payloadData };
//     const response: AxiosResponse = await apiDetailClient.post(
//       '/bac-api/v1/bet-update-bulk',
//       payload
//     );
//     return response;
//   } catch (err) {
//     return err;
//   }
// };
// export const BULK_BET_RESULT_CANCEL = async (
//   data: IGameResultCancelDataDetails
// ) => {
//   const payloadData: IBetBulkClosedData[] = data.list.map((elem) => {
//     return {
//       bet_index: elem.bet_index,
//       bet_type: data.type,
//       stat: data.type,
//     };
//   });
//   try {
//     const payload: IBetBulkClosedPost = { data: payloadData };
//     const response: AxiosResponse = await apiDetailClient.post(
//       '/bac-api/v1/bet-update-bulk',
//       payload
//     );
//     return response;
//   } catch (err) {
//     return err;
//   }
// };
