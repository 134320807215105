import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { GrClose } from 'react-icons/gr';
import {
  IGameResponse,
  IGameResultRes,
} from '../../../common/api/queries/game_query';
import {
  ICardListPatch,
  GAME_RESULT_DETAIL_PATCH,
} from '../../../common/api/mutations/playCard_mutation';
import { gameResultData, isUpdatedVar } from '../../../common/apollo';
import {
  NplayCardListArr,
  NplayCardListArrNum,
} from '../../../common/assets/playCardList';

import {
  PLAYCARD_LIST_GET_QUERY,
  IPlayerCardListRes,
} from '../../../common/api/queries/playCardList_query';
import { Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ButtonCustom } from '../../../components/button';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

interface IModalProps {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  gameNumber: number;
}

export const GameResultModModal: React.FC<IModalProps> = ({
  setModal,
  gameNumber,
}) => {
  const { register, getValues, handleSubmit, control } =
    useForm<ICardListPatch>({ mode: 'onChange' });
  const { t } = useTranslation(['page']);
  const { isSubmitting } = useFormState({ control });
  const selectedGameResultData = useReactiveVar(gameResultData);
  const isUpdated = useReactiveVar(isUpdatedVar);
  const [gameHisData, setGameHisData] = useState<IPlayerCardListRes[]>();

  // CARDS
  const [player1Shape, setplayer1Shape] = useState('');
  const [player1Number, setPlayer1Number] = useState('');
  const [player2Shape, setPlayer2Shape] = useState('');
  const [player2Number, setPlayer2Number] = useState('');
  const [player3Shape, setPlayer3Shape] = useState('');
  const [player3Number, setPlayer3Number] = useState('');
  const [banker1Shape, setBanker1Shape] = useState('');
  const [banker1Number, setBanker1Number] = useState('');
  const [banker2Shape, setBanker2Shape] = useState('');
  const [banker2Number, setBanker2Number] = useState('');
  const [banker3Shape, setBanker3Shape] = useState('');
  const [banker3Number, setBanker3Number] = useState('');

  const clickModalClose = () => {
    setModal((current) => !current);
  };

  const getPlayerCard = () => {
    const id = gameNumber;
    PLAYCARD_LIST_GET_QUERY(id).then((res: AxiosResponse | any) => {
      if (res.data.data.code === 1) {
        setGameHisData(res.data.data.data);
      }
    });
  };

  useEffect(() => {
    getPlayerCard();
  }, []);

  const columns: ColumnsType<IPlayerCardListRes> = [
    {
      key: 'seq',
      title: 'No.',
      dataIndex: 'seq',
      ellipsis: true,
    },
    {
      title: String(t('Created At')),
      dataIndex: 'crt_date',
      ellipsis: true,
    },
    {
      title: String(t('Player Card 1')),
      dataIndex: 'p1card',
      ellipsis: true,
    },
    {
      title: String(t('Player Card 2')),
      dataIndex: 'p2card',
      ellipsis: true,
    },
    {
      title: String(t('Player Card 3')),
      dataIndex: 'p3card',
      ellipsis: true,
    },
    {
      title: String(t('Banker Card 1')),
      dataIndex: 'b1card',
      ellipsis: true,
    },
    {
      title: String(t('Banker Card 2')),
      dataIndex: 'b2card',
      ellipsis: true,
    },
    {
      title: String(t('Banker Card 3')),
      dataIndex: 'b3card',
      ellipsis: true,
    },
    {
      title: String(t('Status')),
      dataIndex: 'stat',
      ellipsis: true,
    },
    {
      key: 'bet',
      title: String(t('Result')),
      dataIndex: 'bet',
      ellipsis: true,
      render: (bet: string) => (
        <Tag
          color={
            bet === 'T'
              ? 'green'
              : bet === 'B' || bet === 'B.P'
              ? 'pink'
              : 'blue'
          }
          style={{ width: '30px', textAlign: 'center' }}
          key={bet}
        >
          {bet}
        </Tag>
      ),
    },
  ];

  const onSubmit = async () => {
    const type = 'EDIT';

    if (
      (player1Shape && !player1Number) ||
      (!player1Shape && player1Number) ||
      (player2Shape && !player2Number) ||
      (!player2Shape && player2Number) ||
      (player3Shape && !player3Number) ||
      (!player3Shape && player3Number) ||
      (banker1Shape && !banker1Number) ||
      (!banker1Shape && banker1Number) ||
      (banker2Shape && !banker2Number) ||
      (!banker2Shape && banker2Number) ||
      (banker3Shape && !banker3Number) ||
      (!banker3Shape && banker3Number)
    ) {
      alert('Please select both shape and number for each card');
      return;
    }

    if (
      player1Shape &&
      player1Number &&
      player2Shape &&
      player2Number &&
      banker1Shape &&
      banker1Number &&
      banker2Shape &&
      banker2Number
    ) {
      const data = {
        id: gameNumber,
        type: type,
        p1card: player1Shape + player1Number,
        p2card: player2Shape + player2Number,
        p3card:
          player3Shape && player3Number ? player3Shape + player3Number : null,
        b1card: banker1Shape + banker1Number,
        b2card: banker2Shape + banker2Number,
        b3card:
          banker3Shape && banker3Number ? banker3Shape + banker3Number : null,
        game_no: selectedGameResultData?.game_no,
      };

      await GAME_RESULT_DETAIL_PATCH(data)
        .then(async (res: AxiosResponse | any) => {
          if (res.data) {
            // const resData: IGameResultEditRes = res.data;
            if (res.data.data.code === 1) {
              // if (resData.data.data.list.length > 0) {
              //   await BULK_BET_RESULT_UPDATE(resData.data.data);
              // }
              alert(`Updated`);
              isUpdatedVar(!isUpdated);
              setModal((current) => !current);
            } else {
              alert(`Request Failed Please Try Again`);
              setModal((current) => !current);
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
      return;
    } else {
      alert('Please select at least 1st and 2nd card');
      return;
    }
  };

  return (
    <div className='fixed h-auto top-0 right-0 bottom-0 left-0 z-10 backdrop-blur-sm'>
      <div className='box-border block shadow-lg m-5 md:m-24 bg-white top-12'>
        <div className='border rounded-md shadow-lg'>
          <div className='font-medium text-lg px-6 py-3 border-b flex flex-row justify-between'>
            <div className='text-blue-500'>{t('Game Result Change')}</div>
            <GrClose
              className='mt-1 cursor-pointer'
              onClick={clickModalClose}
            />
          </div>
          <div className='p-6 max-h-[800px] rounded-lg shadow-md bg-white overflow-y-scroll'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 mb-6'>
                <div className='form-group'>
                  <div className='flex flex-row'>
                    <div className='mb-2 text-gray-500'>{t('Junket')}</div>
                  </div>
                  <input
                    type='text'
                    className='forminput'
                    disabled
                    defaultValue={selectedGameResultData?.category_code}
                  />
                </div>

                <div className='form-group'>
                  <div className='flex flex-row'>
                    <div className='mb-2 text-gray-500'>
                      {t('Table Number')}
                    </div>
                  </div>
                  <input
                    type='text'
                    className='forminput'
                    disabled
                    defaultValue={selectedGameResultData?.table_code}
                  />
                </div>
                <div className='form-group'>
                  <div className='flex flex-row'>
                    <div className='mb-2 text-gray-500'>
                      {t('Proposal Number')}
                    </div>
                  </div>
                  <input
                    type='text'
                    className='forminput'
                    disabled
                    defaultValue={selectedGameResultData?.game_no}
                  />
                </div>
                <div className='form-group'>
                  <div className='flex flex-row'>
                    <div className='mb-2 text-gray-500'>{t('Game Number')}</div>
                  </div>
                  <input
                    type='text'
                    className='forminput'
                    disabled
                    defaultValue={selectedGameResultData?.id}
                  />
                </div>
              </div>
              <div className='grid grid-cols-1 gap-4 mb-10'>
                <Table
                  columns={columns}
                  rowKey={(record) => record.seq}
                  dataSource={gameHisData}
                  size='small'
                  bordered={true}
                  pagination={{ pageSize: 5 }}
                  scroll={{ x: true }}
                />
                <div className='bg-[#FF6B6B] grid grid-cols-4 gap-4 rounded p-3 items-center text-white'>
                  <div className='col-span-4 sm:col-span-1 font-semibold text-center text-xl'>
                    {t('Player')}
                  </div>
                  <div className='col-span-4 sm:col-span-3 flex flex-wrap'>
                    <div className='flex w-full lg:w-1/2 xl:w-1/3 px-2 mb-4 xl:mb-0 gap-2'>
                      <div className='font-medium m-2 w-20'>1st</div>
                      <select
                        className='forminput'
                        value={player1Shape}
                        onChange={(e) => setplayer1Shape(e.target.value)}
                      >
                        {NplayCardListArr.map((option) => (
                          <option key={option.key} value={option.value}>
                            {t(option.textKey)}
                          </option>
                        ))}
                      </select>
                      <select
                        className='forminput'
                        value={player1Number}
                        onChange={(e) => setPlayer1Number(e.target.value)}
                      >
                        {NplayCardListArrNum.map((option) => (
                          <option key={option.key} value={option.value}>
                            {t(option.textKey)}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='flex w-full lg:w-1/2 xl:w-1/3 px-2 mb-4 xl:mb-0 gap-2'>
                      <div className='font-medium m-2 w-20'>2nd</div>
                      <select
                        className='forminput'
                        value={player2Shape}
                        onChange={(e) => setPlayer2Shape(e.target.value)}
                      >
                        {NplayCardListArr.map((option) => (
                          <option key={option.key} value={option.value}>
                            {t(option.textKey)}
                          </option>
                        ))}
                      </select>
                      <select
                        className='forminput'
                        value={player2Number}
                        onChange={(e) => setPlayer2Number(e.target.value)}
                      >
                        {NplayCardListArrNum.map((option) => (
                          <option key={option.key} value={option.value}>
                            {t(option.textKey)}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='flex w-full lg:w-1/2 xl:w-1/3 px-2 xl:mb-0 gap-2'>
                      <div className='font-medium m-2 w-20'>3rd</div>
                      <select
                        className='forminput'
                        value={player3Shape}
                        onChange={(e) => setPlayer3Shape(e.target.value)}
                      >
                        {NplayCardListArr.map((option) => (
                          <option key={option.key} value={option.value}>
                            {t(option.textKey)}
                          </option>
                        ))}
                      </select>
                      <select
                        className='forminput'
                        value={player3Number}
                        onChange={(e) => setPlayer3Number(e.target.value)}
                      >
                        {NplayCardListArrNum.map((option) => (
                          <option key={option.key} value={option.value}>
                            {t(option.textKey)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className='bg-[#2E99B0] grid grid-cols-4 gap-4 rounded p-3 items-center text-white'>
                  <div className='col-span-4 sm:col-span-1 font-semibold text-center text-xl'>
                    {t('Banker')}
                  </div>
                  <div className='col-span-4 sm:col-span-3 flex flex-wrap'>
                    <div className='flex w-full lg:w-1/2 xl:w-1/3 px-2 mb-4 xl:mb-0 gap-2'>
                      <div className='font-medium m-2 w-20'>1st</div>
                      <select
                        className='forminput'
                        value={banker1Shape}
                        onChange={(e) => setBanker1Shape(e.target.value)}
                      >
                        {NplayCardListArr.map((option) => (
                          <option key={option.key} value={option.value}>
                            {t(option.textKey)}
                          </option>
                        ))}
                      </select>
                      <select
                        className='forminput'
                        value={banker1Number}
                        onChange={(e) => setBanker1Number(e.target.value)}
                      >
                        {NplayCardListArrNum.map((option) => (
                          <option key={option.key} value={option.value}>
                            {t(option.textKey)}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='flex w-full lg:w-1/2 xl:w-1/3 px-2 mb-4 xl:mb-0 gap-2'>
                      <div className='font-medium m-2 w-20'>2nd</div>
                      <select
                        className='forminput'
                        value={banker2Shape}
                        onChange={(e) => setBanker2Shape(e.target.value)}
                      >
                        {NplayCardListArr.map((option) => (
                          <option key={option.key} value={option.value}>
                            {t(option.textKey)}
                          </option>
                        ))}
                      </select>
                      <select
                        className='forminput'
                        value={banker2Number}
                        onChange={(e) => setBanker2Number(e.target.value)}
                      >
                        {NplayCardListArrNum.map((option) => (
                          <option key={option.key} value={option.value}>
                            {t(option.textKey)}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='flex w-full lg:w-1/2 xl:w-1/3 px-2 xl:mb-0 gap-2'>
                      <div className='font-medium m-2 w-20'>3rd</div>
                      <select
                        className='forminput'
                        value={banker3Shape}
                        onChange={(e) => setBanker3Shape(e.target.value)}
                      >
                        {NplayCardListArr.map((option) => (
                          <option key={option.key} value={option.value}>
                            {t(option.textKey)}
                          </option>
                        ))}
                      </select>
                      <select
                        className='forminput'
                        value={banker3Number}
                        onChange={(e) => setBanker3Number(e.target.value)}
                      >
                        {NplayCardListArrNum.map((option) => (
                          <option key={option.key} value={option.value}>
                            {t(option.textKey)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className='border border-gray-500 rounded-md py-2 px-3 font-bold text-md text-gray-600 flex items-center justify-center w-full bg-gray-100'
                disabled={isSubmitting}
                type='submit'
              >
                SUBMIT
              </button>
              {/* <Button canClick={isSubmitting} actionText={'한도수정 등록'} /> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
