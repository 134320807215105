import { AxiosResponse } from 'axios';
import { apiClient, apiDetailClient } from '../apiConfig';

export interface ITipPatchForm {
  id: number | undefined;
  amount: number;
  proposal_num: string;
}

// export interface ITipsUpdate {
//   data: ITipsUpdateForm[];
// }
// export interface ITipsUpdateForm {
//   id: number | undefined;
//   user_id: string | undefined;
//   parent_id: string | undefined;
//   avatar_id: string | undefined;
//   crt_dt: string | undefined;
//   upd_dt: string | undefined;
//   tip_type: string | undefined;
//   currency: string | undefined;
//   amount: number | undefined;
//   category_code: string | undefined;
//   table_code: string | undefined;
//   tip_currency: string | undefined;
//   proposal_num: string | undefined;
//   feedBack_data1: string | undefined;
//   feedBack_data2: string | undefined;
// }

export const ITIPS_FORM_PATCH = async (data: ITipPatchForm) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.patch(
      '/api/v2/edit-tips',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

// Internal Server Update
// export const ITIPS_FORM_UPDATE = async (data: ITipsUpdate) => {
//   try {
//     const payload = data;
//     const response: AxiosResponse = await apiDetailClient.post(
//       '/bac-api/v1/tip-update',
//       payload
//     );
//     return response;
//   } catch (err) {
//     return err;
//   }
// };
