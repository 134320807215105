import { AxiosResponse } from 'axios';
import { apiClient, apiDetailClient } from '../apiConfig';
import qs from 'qs';

export interface ITipsResponse {
  id: number;
  userId: string;
  parentId: string;
  avatarId: string;
  crtDt: string;
  updDt: string;
  tipType: string;
  currency: string;
  amount: number;
  categoryCode: string;
  tableCode: string;
  tipCurrency: string;
  proposalNum: string;
  feedBackData1: string;
  feedBackData2: string;
}

export interface ITipsSearchForm {
  userId: string;
  avatarId: string;
  proposalNum: string;
  tipType: string;
  currency: string;
  categoryCode: string;
  tableCode: string;
  from: number | null | undefined;
  to: number | null | undefined;
  curPage: number | undefined;
  unitPage: number | undefined;
  feedBackData1: string;
}

export interface IAvatarTipsMutationPayload {
  proposal_num?: string | null;
  disp_id?: string | null;
  disp_ids?: string | null;
  tip_type?: string | null;
  table_code?: string | null;
  table_codes?: string | null;
  feedback_data1?: string | null;
  feedback_data2?: string | null;
  start_date?: string | null;
  end_date?: string | null;
}

export interface IAvatarTipsMutationHistory {
  seq: string;
  crt_dt: string;
  amount: number;
}

export const TIPS_GET_QUERY = async (data: ITipsSearchForm) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiDetailClient.post(
      `bac-tip/search`,
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const TIPS_EDIT_HISTORY_QUERY = async (id: number | undefined) => {
  try {
    const response: AxiosResponse = await apiDetailClient.get(
      `api/v1/bac/get-edit-tips-history?id=${id}`
    );
    return response;
  } catch (err) {
    return err;
  }
};
