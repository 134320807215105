import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useReactiveVar } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { langVar, routeTitleVar } from '../../../common/apollo';
import { INoticeSearchForm } from '../../../common/api/queries/notice_query';
import { useTranslation } from 'react-i18next';
import { ButtonCustom } from '../../../components/button';

export const NewScrollNoticeSearch = () => {
  const { t } = useTranslation(['page']);
  const selectedLang = useReactiveVar(langVar);
  const { register, getValues, handleSubmit } = useForm<INoticeSearchForm>({
    mode: 'onChange',
  });

  useReactiveVar(routeTitleVar);

  useEffect(() => {
    routeTitleVar(String(t('Scroll Notice')));
  }, [selectedLang]);

  const listType = [
    { key: '', value: '', label: t('Select') },
    { key: 'N', value: 'N', label: t('Normal') },
    { key: 'A', value: 'A', label: t('Urgent') },
  ];

  const listStatus = [
    { key: '', value: '', label: t('Select') },
    { key: 'Open', value: 'Open', label: t('Open') },
    { key: 'Close', value: 'Close', label: t('Close') },
    { key: 'Reservation', value: 'Reservation', label: t('Reservation') },
  ];

  const onSubmit = () => {};

  return (
    <div className='p-6 rounded-lg shadow-lg bg-white'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-wrap justify-end items-center gap-3 mb-5'>
          <div className='flex flex-row gap-3 w-full sm:w-auto justify-between'>
            <div className='form-group w-full'>
              <div className='mb-2 text-gray-500 whitespace-nowrap'>
                {t('Type')}
              </div>
              <select {...register('type')} className='forminput'>
                {listType.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className='form-group w-full'>
              <div className='mb-2 text-gray-500 whitespace-nowrap'>
                {t('Status')}
              </div>
              <select {...register('status')} className='forminput'>
                {listStatus.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='flex flex-row gap-3 w-full sm:w-auto pt-7'>
            <div className='flex'>
              <ButtonCustom canClick={true} actionText={t('Search')} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
