import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { INoticeResponse } from '../../../../common/api/queries/notice_query';
import { currencyList, isUpdatedVar } from '../../../../common/apollo';
import { useReactiveVar } from '@apollo/client';
import { GrClose } from 'react-icons/gr';
import { ButtonCustom } from '../../../../components/button';
import {
  CURRENCY_SETUP_POST,
  ICurrencySearchForm,
} from '../../../../common/api/mutations/currency_mutation';

interface IModalSetupProps {
  setOpenReg: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CurrencyRegisterModal: React.FC<IModalSetupProps> = ({
  setOpenReg,
}) => {
  const { register, getValues, handleSubmit, control } =
    useForm<ICurrencySearchForm>({
      mode: 'onChange',
    });
  const { isSubmitting } = useFormState({ control });
  const { t } = useTranslation(['page']);
  const isUpdated = useReactiveVar(isUpdatedVar);
  const currentData = useReactiveVar(currencyList);
  const [ratioVal, setRatioVal] = useState<string>('');
  const handleRatio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    const sanitizedValue = value.replace(/\D/g, '');
    const truncatedValue = sanitizedValue.slice(0, 6);

    if (truncatedValue.length > 0 && truncatedValue[0] === '0') {
      setRatioVal(truncatedValue.slice(1));
    } else {
      setRatioVal(truncatedValue || '1');
    }
  };

  const clickModalClose = () => {
    setOpenReg((current) => !current);
  };

  const onSubmit = () => {
    const { currency, rate } = getValues();
    const inputValueAsNumber = parseInt(ratioVal, 10);

    const currencyExists = currentData.find(
      (item) => item.currency === currency
    );

    const data = {
      currency: currency,
      rate: inputValueAsNumber,
    };
    let isValid = true;
    if (currencyExists) {
      isValid = false;
    }

    if (!isValid) {
      alert(t('Currency Already Exists.'));
    } else {
      console.log(data);

      // CURRENCY_SETUP_POST(data)
      //   .then((res: AxiosResponse | any) => {
      //     if (res.data.data.code === 1) {
      //       alert('Currency Registered');
      //       isUpdatedVar(!isUpdated);
      //       setOpenReg((current) => !current);
      //     } else {
      //       alert('Request Failed');
      //     }
      //   })
      //   .catch((err) => {
      //     alert('Something went wrong');
      //   });
    }
  };

  return (
    <div
      className='box-border fixed top-0 right-0 bottom-0 left-0 z-40 overflow-auto outline-0'
      style={{ zIndex: '910' }}
    >
      <div className='fixed w-full h-full bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center overflow-y-auto'>
        <div className='border w-1/2 bg-white rounded-md lg:col-span-2 p-5'>
          <div className='font-medium text-lg px-6 py-3 border-b flex flex-row justify-between'>
            <div className='text-blue-500'>{t('Currency Register')}</div>
            <GrClose
              className='mt-1 cursor-pointer'
              onClick={clickModalClose}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-4 my-10'>
              <div className='form-group w-full'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Currency')}
                </div>
                <input
                  {...register('currency')}
                  required
                  className='forminput'
                  type='text'
                />
              </div>
              {/* <div className='form-group w-full'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Currency')}
                </div>
                <input
                  {...register('rate')}
                  className='forminput'
                  type='text'
                />
              </div> */}
              <div className='form-group w-full'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Currency Rate')}
                </div>
                <input
                  className='forminput'
                  type='text'
                  required
                  value={ratioVal}
                  onChange={handleRatio}
                  placeholder='Enter up to 6 digits (1-9)'
                />
              </div>
            </div>
            <ButtonCustom
              canClick={!isSubmitting}
              actionText={t('화폐 등록')}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

// import React from 'react';

// export const CurrencyRegisterModal = () => {
//   return <div>CurrencyRegisterModal</div>;
// };
