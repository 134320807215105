import React from 'react';
import { NewScrollNoticeSearch } from './newScrollNoticeSearch';
import { NewScrollNoticeList } from './newScrollNoticeList';

export const NewScrollNotice = () => {
  return (
    <div>
      <NewScrollNoticeSearch />
      <NewScrollNoticeList />
    </div>
  );
};
