import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import bg from '../common/assets/gameLoginbg.png';
import loginbg from '../common/assets/loginCardbg.png';
// import sysLogo from '../common/assets/agentPage1.png';
import userIcon from '../common/assets/loginUserIcon.png';
import passwordIcon from '../common/assets/loginPassIcon.png';
import krflag from '../common/assets/agentPagekr.png';
import krflagh from '../common/assets/agentPagekr1.png';
import chflag from '../common/assets/agentPagecn.png';
import chflagh from '../common/assets/agentPagecn1.png';
import enflag from '../common/assets/agentPageen.png';
import enflagh from '../common/assets/agentPageen1.png';
import {
	belongedJunket,
	isLoggedInVar,
	langVar,
	myAuthority,
} from '../common/apollo';
import companyLogo from '../common/assets/agentPagedwlogo.png';
import { AxiosResponse } from 'axios';
import { useReactiveVar } from '@apollo/client';
import {
	SESSIONSTORAGE_AUTH,
	SESSIONSTORAGE_JUNKET,
	SESSIONSTORAGE_LANGUAGE,
	SESSIONSTORAGE_TOKEN,
} from '../common/constants';
import { LOGIN_MUTATION } from '../common/api/mutations/login_mutation';

interface IloginForm {
	user_id: string;
	password: string;
}

export const Login = () => {
	const [requested, SetRequested] = useState(true);
	const [loginId, setLoginId] = useState('');
	const [isValid, setIsValid] = useState(true);
	const [krh, setKrh] = useState(false);
	const [chh, setChh] = useState(false);
	const [enh, setEnh] = useState(false);
	const [selectedLan, setSelectedLan] = useState('');

	useReactiveVar(isLoggedInVar);
	useReactiveVar(myAuthority);
	useReactiveVar(belongedJunket);
	useReactiveVar(langVar);

	const { register, getValues, handleSubmit } = useForm<IloginForm>({
		mode: 'onChange',
	});

	const loginIdOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		SetRequested(false);
		setIsValid(true);
		setLoginId(e.target.value);
	};

	const onClickLanguage = (lan: string) => {
		setSelectedLan(lan);
	};

	const onSubmit = () => {
		// isLoggedInVar(true);
		// setIsValid(true);

		// if (selectedLan === '') {
		//   sessionStorage.setItem(SESSIONSTORAGE_LANGUAGE, String('한국어'));
		//   langVar('한국어');
		// } else {
		//   sessionStorage.setItem(SESSIONSTORAGE_LANGUAGE, String(selectedLan));
		//   langVar(selectedLan);
		// }

		const { user_id, password } = getValues();

		const data = {
			user_id: user_id,
			password: password,
		};

		if (selectedLan === '') {
			sessionStorage.setItem(SESSIONSTORAGE_LANGUAGE, String('한국어'));
			langVar('한국어');
		} else {
			sessionStorage.setItem(SESSIONSTORAGE_LANGUAGE, String(selectedLan));
			langVar(selectedLan);
		}

		LOGIN_MUTATION(data)
			.then((res: AxiosResponse | any) => {
				SetRequested(true);
				if (res.data) {
					if (
						res.data.isAuthenticated === true &&
						res.data.user_id === user_id
					) {
						myAuthority(String(res.data.role));
						belongedJunket(String(res.data.junket_code));
						isLoggedInVar(true);
						setIsValid(true);
						sessionStorage.setItem(SESSIONSTORAGE_TOKEN, 'dwtokenIsHere!');
						sessionStorage.setItem(SESSIONSTORAGE_AUTH, String(res.data.role));
						sessionStorage.setItem(
							SESSIONSTORAGE_JUNKET,
							String(res.data.junket_code)
						);
					}
				}
				if (res.response) {
					if (res.response.status === 401) {
						alert('Invalid ID or Password');
						SetRequested(true);
						setIsValid(false);
					} else {
						alert('login request failed');
						SetRequested(true);
						setIsValid(false);
					}
				}
				if (res.request) {
					if (res.request.response === '') {
						alert('Request Failed');
					}
				}
			})
			.catch((err) => {
				console.log(err);
				SetRequested(true);
				setIsValid(false);
			});
	};

	return (
		<div
			className='fixed top-0 right-0 bottom-0 left-0 h-screen w-full min-h-fit bg-no-repeat bg-cover bg-center'
			style={{ backgroundImage: `url(${bg})` }}
		>
			<Helmet>
				<title>Login | DW AGENT</title>
			</Helmet>
			<div className='fixed top-0 right-0 bottom-0 left-0 flex flex-col justify-center items-center'>
				<div className='w-full flex flex-col justify-center items-center'>
					<div
						className='mt-5 flex flex-col items-center bg-no-repeat bg-center m-5 px-2'
						style={{ backgroundImage: `url(${loginbg})` }}
					>
						<div className='2xl:py-12'>
							<div className='p-5 sm:mb-11 md:pt-6 sm:ml-6 md:ml-6'>
								<img src={companyLogo} alt={companyLogo} className='' />
							</div>
							<div className='flex flex-col gap-2 justify-center items-center text-white mt-10 2xl:mt-12 mb-6 2xl:mb-10'>
								<h1 className='text-6xl 2xl:text-7xl font-bold'>GMS</h1>
								<p className='text-2xl text-center 2xl:text-3xl'>
									Game Management <br /> System
								</p>
								{/* <img src={sysLogo} alt={sysLogo} className='mt-5 w-80' /> */}
							</div>
							<div className='px-8 xl:px-20 lg:px-20'>
								<form
									onSubmit={handleSubmit(onSubmit)}
									className='grid gap-3 mt-5 w-full mb-5'
								>
									<input
										type='text'
										name='username'
										className='w-0 h-0 border-0 block'
									/>
									<input type='password' className='w-0 h-0 border-0 block' />
									<div className=' flex flex-row items-center bg-white bg-opacity-30 rounded-full px-5 mb-5'>
										<img className='h-5' src={String(userIcon)} />
										<input
											{...register('user_id')}
											required
											placeholder='Username'
											pattern='^[a-zA-Z0-9]*$'
											className='block w-full input bg-transparent border-none text-white placeholder:text-white placeholder:italic focus:placeholder:opacity-0'
											onChange={loginIdOnChange}
											autoFocus
										></input>
									</div>
									<div className='flex flex-row items-center bg-white bg-opacity-30 rounded-full px-5 mb-5 2xl:mb-10'>
										<img className='h-5' src={String(passwordIcon)} />
										<input
											{...register('password', {
												required: 'password is required',
											})}
											required
											type='password'
											pattern='^[a-zA-Z\\d`~!@#$%^&*()-_=+]*$'
											minLength={5}
											placeholder='Password'
											className='block w-full input bg-transparent border-none text-white placeholder:text-white placeholder:italic focus:placeholder:opacity-0'
										></input>
									</div>
									<div className='flex flex-row justify-center'>
										<div className='bg-black bg-opacity-40 w-full rounded-full px-5 mb-5 hover:bg-opacity-30'>
											<button
												role='button'
												className={`text-white w-full rounded text-2xl px-6 py-3 focus:outline-none font-semibold transition-colors`}
											>
												LOGIN
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className='flex flex-row gap-6 sm:gap-6 md:gap-6 xl:gap-10'>
						<img
							onMouseEnter={() => setKrh(true)}
							onMouseLeave={() => setKrh(false)}
							onClick={() => onClickLanguage('한국어')}
							className='h-12 sm:h-12 md:h-14 2xl:h-16 cursor-pointer'
							src={
								krh || selectedLan === '한국어'
									? String(krflagh)
									: String(krflag)
							}
						></img>
						<img
							onMouseEnter={() => setChh(true)}
							onMouseLeave={() => setChh(false)}
							onClick={() => onClickLanguage('中文')}
							className='h-12 sm:h-12 md:h-14 2xl:h-16 cursor-pointer'
							src={
								chh || selectedLan === '中文' ? String(chflagh) : String(chflag)
							}
						></img>
						<img
							onMouseEnter={() => setEnh(true)}
							onMouseLeave={() => setEnh(false)}
							onClick={() => onClickLanguage('English')}
							className='h-12 sm:h-12 md:h-14 2xl:h-16 cursor-pointer'
							src={
								enh || selectedLan === 'English'
									? String(enflagh)
									: String(enflag)
							}
						></img>
					</div>
					<div className='mt-3 md:mt-3 2xl:mt-4 text-white text-lg'>
						Version 0.1.2
					</div>
				</div>
			</div>
		</div>
	);
};
