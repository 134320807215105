/* eslint-disable react/react-in-jsx-scope */
export const playCardListArr = [
  <option key='S' value='S'>
    스페이드
  </option>,
  <option key='D' value='D'>
    다이아
  </option>,
  <option key='C' value='C'>
    클로버
  </option>,
  <option key='H' value='H'>
    하트
  </option>,
];

export const playCardListArrNum = [
  <option key='1' value='1'>
    1
  </option>,
  <option key='2' value='2'>
    2
  </option>,
  <option key='3' value='3'>
    3
  </option>,
  <option key='4' value='4'>
    4
  </option>,
  <option key='5' value='5'>
    5
  </option>,
  <option key='6' value='6'>
    6
  </option>,
  <option key='7' value='7'>
    7
  </option>,
  <option key='8' value='8'>
    8
  </option>,
  <option key='9' value='9'>
    9
  </option>,
  <option key='10' value='10'>
    10
  </option>,
  <option key='11' value='11'>
    J
  </option>,
  <option key='12' value='12'>
    Q
  </option>,
  <option key='13' value='13'>
    K
  </option>,
];

export const SplayCardListArr = [
  <option key='' value=''>
    모양 선택
  </option>,
  <option key='S' value='S'>
    스페이드
  </option>,
  <option key='D' value='D'>
    다이아
  </option>,
  <option key='C' value='C'>
    클로버
  </option>,
  <option key='H' value='H'>
    하트
  </option>,
];

export const SplayCardListArrNum = [
  <option key='' value=''>
    선택 숫자
  </option>,
  <option key='1' value='1'>
    1
  </option>,
  <option key='2' value='2'>
    2
  </option>,
  <option key='3' value='3'>
    3
  </option>,
  <option key='4' value='4'>
    4
  </option>,
  <option key='5' value='5'>
    5
  </option>,
  <option key='6' value='6'>
    6
  </option>,
  <option key='7' value='7'>
    7
  </option>,
  <option key='8' value='8'>
    8
  </option>,
  <option key='9' value='9'>
    9
  </option>,
  <option key='10' value='10'>
    10
  </option>,
  <option key='11' value='11'>
    J
  </option>,
  <option key='12' value='12'>
    Q
  </option>,
  <option key='13' value='13'>
    K
  </option>,
];

export const NplayCardListArr = [
  { key: '', value: '', textKey: 'Select Shape' },
  { key: 'S', value: 'S', textKey: 'Spades' },
  { key: 'D', value: 'D', textKey: 'Diamonds' },
  { key: 'C', value: 'C', textKey: 'Clovers' },
  { key: 'H', value: 'H', textKey: 'Hearts' },
];

export const NplayCardListArrNum = [
  { key: '', value: '', textKey: 'Select Number' },
  { key: '1', value: '1', textKey: '1' },
  { key: '2', value: '2', textKey: '2' },
  { key: '3', value: '3', textKey: '3' },
  { key: '4', value: '4', textKey: '4' },
  { key: '5', value: '5', textKey: '5' },
  { key: '6', value: '6', textKey: '6' },
  { key: '7', value: '7', textKey: '7' },
  { key: '8', value: '8', textKey: '8' },
  { key: '9', value: '9', textKey: '9' },
  { key: '10', value: '10', textKey: '10' },
  { key: '11', value: '11', textKey: 'J' },
  { key: '12', value: '12', textKey: 'Q' },
  { key: '13', value: '13', textKey: 'K' },
];
