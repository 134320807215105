import { AxiosResponse } from 'axios';
import { apiClient } from '../apiConfig';

export interface IChipForm {
  currency: string | undefined;
  vals: number[];
}
export const CHIP_EDIT_POST = async (data: IChipForm) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'api/add-default-chips',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const CHIP_DELETE_POST = async (data: IChipForm) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'api/delete-default-chips',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};
