import React from 'react';
import { CurrencySetupList } from './currencySetupList';

export const CurrencySetup = () => {
  return (
    <div>
      <CurrencySetupList />
    </div>
  );
};
