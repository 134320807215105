import { AxiosResponse } from 'axios';
import { apiClient, apiDetailClient } from '../apiConfig';
export interface IBetRegisterForm {
  category_code: string;
  table_code: string;
  game_no: string;
  bet_result_id: number;
  disp_id: string;
  bet_type: string;
  bet_amount: number;
  proposal_num: string;
  feedback_data1: string;
  feedback_data2?: string;
}
export interface IBetPostForm {
  junket_code: string;
  table_code: string;
  proposal_num: string;
  game_num: string;
  gamer_id: string;
  bet_type: string;
  bet_amount: number;
}
export interface IBetPatchForm {
  type: string;
  bet_index: string;
  bet_type: string;
  bet_amount: number;
  game_no: string;
}
export interface IBetCancelForm {
  type: string;
  bet_index: string;
  game_no: string;
}

// internal server update
// export interface IBetUpdate {
//   data: IBetUpdateForm[];
// }
// export interface IBetUpdateForm {
//   bet_index: string | undefined;
//   start_date: string | undefined;
//   bet_date: string | undefined;
//   end_date: string | undefined;
//   bet_type: string | undefined;
//   bet_currency: string | undefined;
//   bet_amount: number | undefined;
//   winlose: number | undefined;
//   stat: string | undefined;
//   ret_type: string | undefined;
//   pcards: string | undefined;
//   bcards: string | undefined;
//   pscore: string | undefined;
//   bscore: string | undefined;
//   category_code: string | undefined;
//   game_no: string | undefined;
//   user_type: string | undefined;
//   user_id: string | undefined;
//   parent_id: string | undefined;
//   table_code: string | undefined;
//   proposal_num: string | undefined;
//   edit_type: string | undefined;
//   feedback_data1: string | undefined;
//   feedback_data2: string | undefined;
// }

// BET UPDATE

export interface IBetUpdateReq {
  data: IBetUpdateForm[];
}

export interface IBetUpdateForm {
  game_no: string;
  table_code: string;
  user_id: string;
  parent_id: string;
  feedback_data1: string;
  feedback_data2: string;
  bet_index: string;
  start_date: string;
  bet_date: string;
  end_date: string;
  bet_type: string;
  bet_currency: string;
  bet_amount: number;
  stat: string;
  pcards: string;
  bcards: string;
  category_code: string;
  user_type: string;
  proposal_num: string;
  winlose: number;
  ret_type: string;
  pscore: string;
  bscore: string;
  ppair: number;
  bpair: number;
  balance_before: number;
  balance_after: number;
  edit_type: string;
}

// BET AVA UPDATE

export interface IBetAvaUpdateReq {
  data: IBetAvaUpdateForm[];
}

export interface IBetAvaUpdateForm {
  bet_index: number;
  table_code: string;
  end_date: string;
  bet_type: string;
  bet_amount: number;
  winlose: number;
  stat: string;
  category_code: string;
  avatar_id: string;
  proposal_num: string;
  game_no: string;
}

export const IBET_FORM_REGISTER_POST = async (data: IBetRegisterForm) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      '/api/add-user-bet',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const IBET_FORM_PATCH = async (data: IBetPatchForm) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.patch(
      '/api/ngame-bet-result-detail',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};
export const IBET_FORM_CANCEL_PATCH = async (data: IBetCancelForm) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.patch(
      '/api/ngame-bet-result-detail',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const IBET_UPDATE_POST = async (data: IBetUpdateReq) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      '/api/update-betngame',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const IBET_AVA_UPDATE_POST = async (data: IBetAvaUpdateReq) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      '/api/update-betngame',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

// INTERNAL SERVER REQUEST
// export const IBET_BULK_UPDATE = async (data: IBetUpdate) => {
//   try {
//     const payload = data;
//     const response: AxiosResponse = await apiDetailClient.post(
//       '/bac-api/v1/bet-update',
//       payload
//     );
//     return response;
//   } catch (err) {
//     return err;
//   }
// };
