import React from 'react';
import { RGameBetSearch } from './rGameBetSearch';

export const RGameBet = () => {
  return (
    <div>
      <RGameBetSearch />
    </div>
  );
};
