import { AxiosResponse } from 'axios';
import { apiClient } from '../apiConfig';
import qs from 'qs';

export const CHIP_DEFAULT_GET_QUERY = async (currency: string | undefined) => {
  try {
    const response: AxiosResponse = await apiClient.get(
      `api/get-default-chips?currency=${currency}`
    );
    return response;
  } catch (err) {
    return err;
  }
};
