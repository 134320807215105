import BPPair from '../../common/assets/betpair_dot/banker_lowdot.png';
import BBPair from '../../common/assets/betpair_dot/banker_updot.png';
import BDPair from '../../common/assets/betpair_dot/banker_dw.png';
import BNPair from '../../common/assets/betpair_dot/banker_nodot.png';

import PPPair from '../../common/assets/betpair_dot/player_lowdot.png';
import PBPair from '../../common/assets/betpair_dot/player_updot.png';
import PDPair from '../../common/assets/betpair_dot/player_dw.png';
import PNPair from '../../common/assets/betpair_dot/player_nodot.png';

import TPPair from '../../common/assets/betpair_dot/tie_lowdot.png';
import TBPair from '../../common/assets/betpair_dot/tie_updot.png';
import TDPair from '../../common/assets/betpair_dot/tie_dw.png';
import TNPair from '../../common/assets/betpair_dot/tie_nodot.png';

export const cardListImage = {
  BPPair,
  BBPair,
  BDPair,
  BNPair,

  PPPair,
  PBPair,
  PDPair,
  PNPair,

  TPPair,
  TBPair,
  TDPair,
  TNPair,
};

export const betImageMapping: Record<string, any> = {
  'B10': cardListImage.BPPair,
  'B01': cardListImage.BBPair,
  'B11': cardListImage.BDPair,
  'B00': cardListImage.BNPair,

  'P10': cardListImage.PPPair,
  'P01': cardListImage.PBPair,
  'P11': cardListImage.PDPair,
  'P00': cardListImage.PNPair,

  'T10': cardListImage.TPPair,
  'T01': cardListImage.TBPair,
  'T11': cardListImage.TDPair,
  'T00': cardListImage.TNPair,

  'P': cardListImage.PNPair,
  'B': cardListImage.BNPair,
  'T': cardListImage.TNPair,
  'P.P': cardListImage.PPPair,
  'B.P': cardListImage.BPPair,
};
