import React from 'react';
// import { SoGameBetSearch } from './soGameBetSearch';
import { SoGameBetSearch } from './soGameBetSearch';

export const SoGameBet = () => {
  return (
    <div>
      <SoGameBetSearch />
    </div>
  );
};
