import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useForm, useFormState } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  INewNoticePost,
  NEW_SCROLL_NOTICE_POST,
} from '../../../../common/api/mutations/notice_mutation';
import { isUpdatedVar } from '../../../../common/apollo';

import { GrClose } from 'react-icons/gr';
import { ButtonCustom } from '../../../../components/button';

interface IModalRegProps {
  setOpenReg: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NewScrollRegModal: React.FC<IModalRegProps> = ({ setOpenReg }) => {
  const { register, getValues, handleSubmit, control } =
    useForm<INewNoticePost>({
      mode: 'onChange',
    });
  const { isSubmitting } = useFormState({ control });
  const { t } = useTranslation(['page']);
  const isUpdated = useReactiveVar(isUpdatedVar);

  const clickModalClose = () => {
    setOpenReg((current) => !current);
  };

  const onSubmit = () => {
    const { lang, data } = getValues();
    const dataToSend = {
      lang: lang,
      data: data,
    };

    NEW_SCROLL_NOTICE_POST(dataToSend)
      .then((res: AxiosResponse | any) => {
        if (res.data) {
          alert(t('Registration Success'));
          isUpdatedVar(!isUpdated);
          setOpenReg(false);
        } else if (res.response.data.error.status === 409) {
          alert(res.response.data.error.message);
        } else {
          alert(t('Registration Failed'));
          setOpenReg(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      className='box-border fixed top-0 right-0 bottom-0 left-0 overflow-auto outline-0'
      style={{ zIndex: '910' }}
    >
      <div className='fixed w-full h-full bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center'>
        <div className='border w-full max-w-lg bg-white rounded-md p-5 m-5'>
          <div className='font-medium text-lg px-6 py-3 border-b flex flex-row justify-between'>
            <div className='text-blue-500'>{t('Create Notice')}</div>
            <GrClose
              className='mt-1 cursor-pointer'
              onClick={clickModalClose}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-4 py-4'>
              <div className='w-full form-group'>
                <div className='flex flex-row'>
                  <div className='mb-2 text-gray-500'>{t('Currency')}</div>
                </div>
                <select {...register('lang')} className='forminput'>
                  <option value='KO'>KO</option>
                  <option value='CN'>CN</option>
                  <option value='EN'>EN</option>
                  <option value='JP'>JP</option>
                </select>
              </div>
              <div className='w-full form-group'>
                <div className='flex flex-row'>
                  <div className='mb-2 text-gray-500'>{t('Data')}</div>
                </div>
                <textarea
                  {...register('data')}
                  style={{ resize: 'none' }}
                  required
                  maxLength={200}
                  className='forminput'
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className='w-full'>
                <ButtonCustom
                  canClick={!isSubmitting}
                  actionText={t('Create Notice')}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
