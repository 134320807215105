import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isUpdatedVar } from '../../../../common/apollo';
import { useReactiveVar } from '@apollo/client';
import { GrClose } from 'react-icons/gr';
import { ButtonCustom } from '../../../../components/button';
import {
  DUMMY_USER_REG_POST,
  IDummyUserForm,
} from '../../../../common/api/mutations/dmmyuser_mutation';

interface IModalRegProps {
  setOpenReg: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DummyUserReg: React.FC<IModalRegProps> = ({ setOpenReg }) => {
  const { register, getValues, handleSubmit, control } =
    useForm<IDummyUserForm>({
      mode: 'onChange',
    });
  const { isSubmitting } = useFormState({ control });
  const { t } = useTranslation(['page']);
  const isUpdated = useReactiveVar(isUpdatedVar);

  const clickModalClose = () => {
    setOpenReg((current) => !current);
  };

  const onSubmit = async () => {
    const { user_id } = getValues();
    const data = {
      user_id: user_id,
    };
    await DUMMY_USER_REG_POST(data)
      .then((res: AxiosResponse | any) => {
        if (res.data) {
          if (res.data.data.code === 1) {
            alert(t('Registration Success'));
            isUpdatedVar(!isUpdated);
            setOpenReg(false);
          } else {
            alert(t('Registration Failed'));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      className='box-border fixed top-0 right-0 bottom-0 left-0 overflow-auto outline-0'
      style={{ zIndex: '910' }}
    >
      <div className='fixed w-full h-full bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center'>
        <div className='border w-full max-w-lg bg-white rounded-md p-5 m-5'>
          <div className='font-medium text-lg px-6 py-3 border-b flex flex-row justify-between'>
            <div className='text-blue-500'>{t('Create Dummy')}</div>
            <GrClose
              className='mt-1 cursor-pointer'
              onClick={clickModalClose}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-4 py-4'>
              <div className='w-full form-group'>
                <div className='flex flex-row'>
                  <div className='mb-2 text-gray-500'>{t('User ID')}</div>
                </div>
                <input
                  {...register('user_id')}
                  type='text'
                  className='forminput'
                />
              </div>
              <div className='w-full'>
                <ButtonCustom
                  canClick={!isSubmitting}
                  actionText={t('Create Notice')}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
