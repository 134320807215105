import { AxiosResponse } from 'axios';
import { apiClient } from '../apiConfig';

export interface IDummyUserMod {
  user_id: string;
  range_from: number;
  range_to: number;
  range_step: number;
  ref: number;
}

export interface IDummyUserForm {
  user_id: string;
}

export const DUMMY_USER_REG_POST = async (data: IDummyUserForm) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'api/dummy/add_supporter',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const DUMMY_USER_MOD_PATCH = async (data: IDummyUserMod) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.patch(
      'api/dummy/edit_supporter',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const DUMMY_USER_DEL_POST = async (data: IDummyUserForm) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'api/dummy/delete_supporter',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};
