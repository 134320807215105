import React from 'react';
import { GameResultSearch } from './gameResultSearch';

export const GameResult = () => {
  return (
    <div>
      <GameResultSearch />
    </div>
  );
};
