import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isUpdatedVar } from '../../../../common/apollo';
import { useReactiveVar } from '@apollo/client';
import { GrClose } from 'react-icons/gr';
import { ButtonCustom } from '../../../../components/button';
import { ICurrencyResponse } from '../../../../common/api/queries/currency_query';
import {
  CURRENCY_SETUP_PATCH,
  ICurrencySearchForm,
} from '../../../../common/api/mutations/currency_mutation';

interface IModalSetupProps {
  setOpenSetup: React.Dispatch<React.SetStateAction<boolean>>;
  currencySetupData: ICurrencyResponse | null;
}

export const CurrencyModModal: React.FC<IModalSetupProps> = ({
  setOpenSetup,
  currencySetupData: setupData,
}) => {
  const { register, getValues, handleSubmit, control } =
    useForm<ICurrencySearchForm>({
      mode: 'onChange',
    });
  const { isSubmitting } = useFormState({ control });
  const { t } = useTranslation(['page']);
  const isUpdated = useReactiveVar(isUpdatedVar);

  const clickModalClose = () => {
    setOpenSetup((current) => !current);
  };

  const onSubmit = () => {
    const { rate } = getValues();
    const currency = setupData?.currency;
    let isValid = true;

    const data = {
      currency: currency,
      rate: rate,
    };

    if (rate <= 0) {
      isValid = false;
    }

    if (!isValid) {
      alert(t('Required Fields Are Not Filled In.'));
    } else {
      CURRENCY_SETUP_PATCH(data)
        .then((res: AxiosResponse | any) => {
          if (res.data.data.code === 1) {
            alert('Currency Registered');
            isUpdatedVar(!isUpdated);
            setOpenSetup((current) => !current);
          } else {
            alert('Request Failed Please Try again');
          }
        })
        .catch((err) => {
          alert('Something went wrong');
        });
    }
  };

  return (
    <div
      className='box-border fixed top-0 right-0 bottom-0 left-0 z-40 overflow-auto outline-0'
      style={{ zIndex: '910' }}
    >
      <div className='fixed w-full h-full bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center overflow-y-auto'>
        <div className='border w-1/2 bg-white rounded-md lg:col-span-2 p-5'>
          <div className='font-medium text-lg px-6 py-3 border-b flex flex-row justify-between'>
            <div className='text-blue-500'>{t('Currency Setup')}</div>
            <GrClose
              className='mt-1 cursor-pointer'
              onClick={clickModalClose}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-4 my-10'>
              <div className='form-group w-full'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Currency')}
                </div>
                <input
                  {...register('currency')}
                  disabled
                  className='forminput bg-gray-200'
                  value={setupData?.currency}
                />
              </div>
              <div className='form-group w-full'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Ratio')}
                </div>
                {/* <input
                  {...register('rate')}
                  type='number'
                  className='forminput'
                  defaultValue={data?.rate}
                /> */}
                <input
                  {...register('rate')}
                  type='text'
                  pattern='[0-9]{1,6}'
                  className='forminput'
                  defaultValue={setupData?.rate}
                  onInput={(e) => {
                    const inputValue = e.currentTarget.value;
                    let sanitizedValue = '';

                    if (inputValue === '') {
                      sanitizedValue = '1';
                    } else if (inputValue.match(/^\d{1,6}$/)) {
                      sanitizedValue = inputValue;
                    } else {
                      const matchedValue =
                        e.currentTarget.value.match(/\d{0,6}/);
                      sanitizedValue = matchedValue ? matchedValue[0] : '';
                    }

                    if (sanitizedValue !== '') {
                      e.currentTarget.value = sanitizedValue;
                    } else {
                      e.currentTarget.value = '1';
                    }
                  }}
                />
              </div>
            </div>
            <ButtonCustom
              canClick={!isSubmitting}
              actionText={t('화폐 등록')}
            />
          </form>
        </div>
      </div>
    </div>
  );
};
