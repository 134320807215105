import { Popconfirm } from 'antd';
import React, { useState } from 'react';
import { IBetHistoryResponse } from '../../../../common/api/queries/bethistory_query';
import { useTranslation } from 'react-i18next';
import {
  IBET_UPDATE_POST,
  IBetUpdateReq,
} from '../../../../common/api/mutations/bet_mutation';
import { AxiosResponse } from 'axios';

interface PopConfirmProps {
  title: string;
  description: string;
  record: IBetHistoryResponse;
  toReqCurPage: () => void;
}

export const CGamePopConfirm: React.FC<PopConfirmProps> = ({
  title,
  description,
  record,
  toReqCurPage,
}) => {
  const { t } = useTranslation(['page']);

  const popConfirm = async (record: IBetHistoryResponse) => {
    const data: IBetUpdateReq = {
      data: [record],
    };

    await IBET_UPDATE_POST(data)
      .then((res: AxiosResponse | any) => {
        if (res.data) {
          if (res.data.data.data.code === 0) {
            alert('Updated');
            toReqCurPage();
          } else {
            alert(`Error Occur Please Try Again`);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Popconfirm
      title={String(t(title))}
      description={String(t(description))}
      onConfirm={() => popConfirm(record)}
      okText='Yes'
      cancelText='No'
    >
      <p className='cursor-pointer underline'>{record.bet_index}</p>
    </Popconfirm>
  );
};
