import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useForm, useFormState } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  INewNoticePatch,
  NEW_SCROLL_NOTICE_PATCH,
  SCROLL_NOTICE_EDIT,
} from '../../../../common/api/mutations/notice_mutation';
import { isUpdatedVar } from '../../../../common/apollo';

import { Switch } from 'antd';
import { GrClose } from 'react-icons/gr';
import { ButtonCustom } from '../../../../components/button';
import { INewNoticeResponse } from '../../../../common/api/queries/notice_query';

interface IModalSetupProps {
  setOpenSetup: React.Dispatch<React.SetStateAction<boolean>>;
  rowData: INewNoticeResponse | null;
}

export const NewScrollSetupModal: React.FC<IModalSetupProps> = ({
  setOpenSetup,
  rowData,
}) => {
  const { register, getValues, handleSubmit, control } =
    useForm<INewNoticePatch>({
      mode: 'onChange',
    });
  const { isSubmitting } = useFormState({ control });
  const { t } = useTranslation(['page']);
  const [isDisplay, setIsDisplay] = useState<boolean>(
    rowData?.disp_yn === 'Y' ? true : false
  );

  const isUpdated = useReactiveVar(isUpdatedVar);

  const clickModalClose = () => {
    setOpenSetup((current) => !current);
  };

  const onChange = (checked: boolean) => {
    setIsDisplay(checked);
  };

  const onSubmit = () => {
    const { text } = getValues();
    const dataToSend = {
      lang: String(rowData?.lang),
      text: text,
      disp_yn: isDisplay ? 'Y' : 'N',
    };
    SCROLL_NOTICE_EDIT(dataToSend)
      .then((res: AxiosResponse | any) => {
        if (res.data) {
          if (res.data.data.code === 1) {
            alert(t('Registration Success'));
            isUpdatedVar(!isUpdated);
            setOpenSetup(false);
          } else {
            alert(t('Registration Failed'));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      className='box-border fixed top-0 right-0 bottom-0 left-0 overflow-auto outline-0'
      style={{ zIndex: '910' }}
    >
      <div className='fixed w-full h-full bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center'>
        <div className='border w-full max-w-lg bg-white rounded-md p-5 m-5'>
          <div className='font-medium text-lg px-6 py-3 border-b flex flex-row justify-between'>
            <div className='text-blue-500'>{t('Create Notice')}</div>
            <GrClose
              className='mt-1 cursor-pointer'
              onClick={clickModalClose}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-4 py-4'>
              <div className='w-full form-group'>
                <div className='flex flex-row'>
                  <div className='mb-2 text-gray-500'>{t('Currency')}</div>
                </div>
                <div className='flex items-center gap-2'>
                  <input
                    {...register('lang')}
                    defaultValue={rowData?.lang}
                    disabled
                    type='text'
                    className='forminput flex-grow bg-gray-100'
                  />
                  <Switch
                    className='bg-gray-500'
                    defaultChecked={rowData?.disp_yn === 'Y' ? true : false}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className='w-full form-group'>
                <div className='flex flex-row'>
                  <div className='mb-2 text-gray-500'>{t('Data')}</div>
                </div>
                <textarea
                  {...register('text')}
                  style={{ resize: 'none' }}
                  defaultValue={rowData?.text}
                  required
                  maxLength={200}
                  className='forminput'
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className='w-full'>
                <ButtonCustom
                  canClick={!isSubmitting}
                  actionText={t('Edit Notice')}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
