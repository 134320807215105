import React, { useState } from 'react';
import { Button, Space, Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { ITipsResponse } from '../../../common/api/queries/tips_query';
import { useTranslation } from 'react-i18next';
import { SpeedTipSetupModal } from './modal/speedTipSetupModal';

interface TableListProps {
  data: ITipsResponse[] | undefined;
  tableParams: {
    pagination: TablePaginationConfig;
  };
  handleTableChange: (pagination: TablePaginationConfig) => void;
  loading: boolean;
}

const SpeedTableList: React.FC<TableListProps> = ({
  data,
  tableParams,
  handleTableChange,
  loading,
}) => {
  const { t } = useTranslation(['page']);

  const [modModal, setModModal] = useState(false);
  const [selectedSpeedTipData, setSelectedSpeedTipData] =
    useState<ITipsResponse | null>(null);

  const onClickModModal = (data: ITipsResponse) => {
    setModModal(!modModal);
    setSelectedSpeedTipData(data);
  };

  const columns: ColumnsType<ITipsResponse> = [
    {
      title: 'No.',
      dataIndex: 'id',
      fixed: 'left',
      ellipsis: true,
    },
    {
      title: String(t('User ID')),
      dataIndex: 'userId',
      ellipsis: true,
    },
    {
      title: String(t('Parent ID')),
      dataIndex: 'parentId',
      ellipsis: true,
    },
    {
      title: String(t('Avatar ID')),
      dataIndex: 'avatarId',
      ellipsis: true,
    },
    {
      title: String(t('Create At')),
      dataIndex: 'crtDt',
      ellipsis: true,
    },
    {
      title: String(t('Updated At')),
      dataIndex: 'updDt',
      ellipsis: true,
    },
    {
      title: String(t('Currency')),
      dataIndex: 'currency',
      ellipsis: true,
    },
    {
      title: String(t('Amount')),
      dataIndex: 'amount',
      ellipsis: true,
      render: (amount) => <p>{amount.toLocaleString()}</p>,
    },
    {
      title: String(t('Junket Name')),
      dataIndex: 'categoryCode',
      ellipsis: true,
    },
    {
      title: String(t('Table Number')),
      dataIndex: 'tableCode',
      ellipsis: true,
    },
    {
      title: String(t('Proposal Number')),
      dataIndex: 'proposalNum',
      ellipsis: true,
    },
    {
      title: String(t('Feedback Data 1')),
      dataIndex: 'feedBackData1',
      ellipsis: true,
    },
    {
      title: String(t('Feedback Data 2')),
      dataIndex: 'feedBackData2',
      ellipsis: true,
    },
    {
      title: String(t('Action')),
      dataIndex: '',
      key: 'x',
      ellipsis: true,
      render: (record) => (
        <Space>
          <Button
            type='primary'
            onClick={() => onClickModModal(record)}
            style={{ padding: '0px 5px', height: '28px' }}
          >
            {t('Edit')}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        size='small'
        bordered={true}
        pagination={{
          ...tableParams.pagination,
          position: ['bottomCenter'],
        }}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: true }}
      />
      {modModal ? (
        <SpeedTipSetupModal
          setModModal={setModModal}
          selSpeedData={selectedSpeedTipData}
        />
      ) : null}
    </>
  );
};

export default SpeedTableList;
