import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { GrClose } from 'react-icons/gr';
import { IBetLimitPost } from '../../../../common/api/mutations/betlimit_mutation';
import {
  IBetPatchForm,
  IBET_FORM_PATCH,
} from '../../../../common/api/mutations/bet_mutation';
import { IJunketResponse } from '../../../../common/api/queries/junket_query';
import { ITableResponse } from '../../../../common/api/queries/table_query';
import { betData, isUpdatedVar } from '../../../../common/apollo';
import { ButtonCustom } from '../../../../components/button';

import { Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AxiosResponse } from 'axios';
import {
  BET_HISTORY_SEARCH_GET,
  IBetHistoryResponse,
  IBetHistorySearchFormSample,
} from '../../../../common/api/queries/bethistory_query';
import { useTranslation } from 'react-i18next';

interface IModalProps {
  setModModal: React.Dispatch<React.SetStateAction<boolean>>;
  phoneBetData: IBetHistoryResponse | undefined;
}

export const RGameBetModModal: React.FC<IModalProps> = ({
  setModModal,
  phoneBetData,
}) => {
  const { register, getValues, handleSubmit, control } = useForm<IBetPatchForm>(
    {
      mode: 'onChange',
    }
  );

  const { t } = useTranslation(['page']);
  const { isSubmitting } = useFormState({ control });
  const [selectedOption, setSelectedOption] = useState<String>();
  const [betSearchHistoryList, setBetSearchHistoryList] =
    useState<IBetHistorySearchFormSample[]>();

  // const [junketList, setJunketList] = useState(Array<IJunketResponse>);
  // const [tableList, setTableList] = useState(Array<ITableResponse>);

  const isUpdated = useReactiveVar(isUpdatedVar);
  const selectedBetData = useReactiveVar(betData);

  const clickModalClose = () => {
    setModModal((current) => !current);
  };

  const getBetSearchHistoryList = () => {
    const bet_index = phoneBetData?.bet_index ?? '';
    BET_HISTORY_SEARCH_GET(bet_index).then((res: AxiosResponse | any) => {
      if (res.data.data.code === 1) {
        setBetSearchHistoryList(res.data.data.data);
      } else {
        console.log('DATA IS NOT GETTING');
      }
    });
  };

  const columns: ColumnsType<IBetHistorySearchFormSample> = [
    {
      key: 'seq',
      title: 'No.',
      dataIndex: 'seq',
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'stat',
      ellipsis: true,
    },
    {
      title: 'Bet Amount',
      dataIndex: 'bet_amount',
      ellipsis: true,
    },
    {
      title: 'Date Created',
      dataIndex: 'crt_date',
      ellipsis: true,
    },
    {
      key: 'bet',
      title: 'Bet Type',
      dataIndex: 'bet_type',
      ellipsis: true,
      render: (bet: string) => (
        <Tag
          color={
            bet === 'P' || bet === 'P.P'
              ? 'blue'
              : bet === 'B' || bet === 'B.P'
              ? 'red'
              : bet === 'T'
              ? 'green'
              : 'pink'
          }
          style={{ width: 'auto', textAlign: 'center' }}
          key={bet}
        >
          {bet}
          {!bet && 'CANCELED'}
        </Tag>
      ),
    },
  ];

  useEffect(() => {
    getBetSearchHistoryList();
  }, []);

  const onSubmit = async () => {
    const { bet_type, bet_amount } = getValues();
    const type = 'EDIT';

    let alrtPatchRes = false;
    let alrtPostRes = false;

    const data = {
      type: type,
      bet_index: phoneBetData?.bet_index ?? '',
      game_no: phoneBetData?.game_no ?? '',
      bet_type: bet_type,
      bet_amount: bet_amount,
    };

    if (bet_type === 'Select') {
      alert('Please select an option');
    } else if (!bet_amount) {
      alert('Please Input Amount');
    } else {
      await IBET_FORM_PATCH(data)
        .then((res: AxiosResponse | any) => {
          if (res.data) {
            if (res.data.data.code === 1) {
              alrtPatchRes = true;
            } else {
              alrtPatchRes = false;
            }
          }
        })
        .catch((err) => {
          alert(err);
        });

      // if (alrtPatchRes !== false) {
      //   const { ...rest } = selectedBetData;
      //   const dataToSend: IBetUpdate = {
      //     data: [
      //       {
      //         ...rest,
      //         bet_index: dataIndex,
      //         bet_amount: bet_amount,
      //         bet_type: bet_type,
      //       },
      //     ],
      //   };
      //   await IBET_BULK_UPDATE(dataToSend)
      //     .then((res: AxiosResponse | any) => {
      //       if (res.data) {
      //         if (res.data.data.data.code === 0) {
      //           alrtPostRes = true;
      //         }
      //       }
      //     })
      //     .catch((err) => {
      //       alert(err);
      //     });
      // }
      if (alrtPatchRes) {
        alert(`Updated`);
        isUpdatedVar(!isUpdated);
      } else {
        alert(`Request Failed Please Try Again`);
      }
      setModModal((current) => !current);
    }
  };

  return (
    <div className='fixed h-auto top-0 right-0 bottom-0 left-0 z-10 backdrop-blur-sm'>
      <div className='box-border block shadow-lg m-5 md:m-24 bg-white top-12'>
        <div className='border rounded-md lg:col-span-2'>
          <div className='font-medium text-lg px-6 py-3 border-b flex flex-row justify-between'>
            <div className='text-blue-500'>{t('Edit Order')}</div>
            <GrClose
              className='mt-1 cursor-pointer'
              onClick={clickModalClose}
            />
          </div>
          <div className='p-6 max-h-[700px] rounded-lg shadow-md bg-white overflow-y-scroll'>
            <Table
              columns={columns}
              rowKey={(record) => record.seq}
              dataSource={betSearchHistoryList}
              size='small'
              bordered={true}
              pagination={{ pageSize: 5 }}
              scroll={{ x: true }}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='flex flex-wrap justify-end items-center gap-3 mb-5'>
                <div className='grid gap-4 md:grid-cols-2 lg:grid-cols-4 w-full'>
                  <div className='form-group w-full'>
                    <div className='mb-2 text-gray-500 whitespace-nowrap'>
                      {t('ID')}
                    </div>
                    <input
                      type='text'
                      className='forminput'
                      disabled
                      defaultValue={selectedBetData?.bet_index}
                    />
                  </div>
                  <div className='form-group w-full'>
                    <div className='mb-2 text-gray-500 whitespace-nowrap'>
                      {t('Table Number')}
                    </div>
                    <input
                      type='text'
                      className='forminput'
                      disabled
                      defaultValue={selectedBetData?.table_code}
                    />
                  </div>
                  <div className='form-group w-full'>
                    <div className='mb-2 text-gray-500 whitespace-nowrap'>
                      {t('게임 번호')}
                    </div>
                    <input
                      type='text'
                      className='forminput'
                      disabled
                      defaultValue={selectedBetData?.game_no}
                    />
                  </div>
                  <div className='form-group w-full'>
                    <div className='mb-2 text-gray-500 whitespace-nowrap'>
                      {t('Offer Number')}
                    </div>
                    <input
                      type='text'
                      className='forminput'
                      disabled
                      defaultValue={selectedBetData?.proposal_num}
                    />
                  </div>
                </div>
              </div>
              <div className='grid md:grid-cols-2 gap-4 mb-6'>
                <div className='form-group'>
                  <div className='flex flex-row'>
                    <div className='mb-2 text-gray-500 whitespace-nowrap'>
                      {t('Bet Type')}
                    </div>
                  </div>
                  <select {...register('bet_type')} className='forminput'>
                    <option value='Select'>선택</option>
                    <option value='P'>P</option>
                    <option value='B'>B</option>
                    <option value='T'>T</option>
                    <option value='PP'>PP</option>
                    <option value='BP'>BP</option>
                  </select>
                </div>
                <div className='form-group'>
                  <div className='flex flex-row'>
                    <div className='mb-2 text-gray-500 whitespace-nowrap'>
                      {t('Bet Amount')}
                    </div>
                  </div>
                  <input
                    {...register('bet_amount', { valueAsNumber: true })}
                    type='number'
                    pattern='[0-9]*'
                    className='forminput'
                    placeholder='Bet Amount'
                  />
                </div>
              </div>
              <ButtonCustom
                canClick={!isSubmitting}
                actionText={t('Order Registration')}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
