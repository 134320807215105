import { AxiosResponse } from 'axios';
import React, { useState, useEffect } from 'react';
import {
  GAME_RESULT_GET_QUERY,
  GAME_RESULT_SEARCH_GET_QUERY,
  IGameResultRes,
  IGameSearchForm,
} from '../../common/api/queries/game_query';
import {
  gameResultList,
  gameResultData,
  gameTableList,
  junketList,
  langVar,
  routeTitleVar,
  isUpdatedVar,
} from '../../common/apollo';
import 'react-datepicker/dist/react-datepicker.css';
import type { TablePaginationConfig } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import GameResultList from './gameResultList';
import { ITableResponse } from '../../common/api/queries/table_query';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateValidationAndConverter } from '../../common/functions/dateValidationAndConverter';
import { ButtonCustom } from '../../components/button';

interface TableParams {
  pagination: TablePaginationConfig;
}

export const GameResultSearch = () => {
  const { register, getValues, handleSubmit, control } =
    useForm<IGameSearchForm>({
      mode: 'onChange',
    });
  const { isSubmitting } = useFormState({ control });
  const { t } = useTranslation(['page']);
  const [data, setData] = useState<IGameResultRes[]>();
  const [isHandlePage, setIsHandlePage] = useState(false);
  const [selectedJunket, setSelectedJunket] = useState<string>('');
  const [selectedGameTable, setSelectedGameTable] = useState<string>('');
  const [filteredGameTableList, setFilteredGameTableList] = useState<
    ITableResponse[]
  >([]);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const selectedLang = useReactiveVar(langVar);
  const isUpdated = useReactiveVar(isUpdatedVar);
  const gameTable = useReactiveVar(gameTableList);
  const junketLists = useReactiveVar(junketList);

  // Junket & Table Number Validation
  useEffect(() => {
    if (selectedJunket) {
      const filteredTables = gameTable.filter(
        (table) => table.category_code === selectedJunket
      );
      setFilteredGameTableList(filteredTables);
    } else {
      setFilteredGameTableList(gameTable);
    }
  }, [selectedJunket, gameTable]);

  useEffect(() => {
    if (selectedGameTable) {
      const matchingJunket = gameTable.find(
        (table) => table.table_code === selectedGameTable
      );
      if (matchingJunket) {
        setSelectedJunket(matchingJunket.category_code);
      }
    }
  }, [selectedGameTable, gameTable]);
  // End Junket & Table Number Validation

  const convertToEpochTime = (date: Date | null): number | null => {
    if (date === null) {
      return null;
    }
    const milliseconds = date.getTime();
    const seconds = Math.floor(milliseconds / 1000);

    return seconds;
  };

  const onSubmit = async (isSearch: boolean) => {
    const { game_no, edit_stat } = getValues();
    const { current: curPage, pageSize: unitPage } =
      tableParams.pagination || {};
    const category_code = selectedJunket;
    const table_code = selectedGameTable;
    const belongJunket = game_no.slice(0, 2);

    const maxDateSearch = 14;
    const convertedDate = DateValidationAndConverter(
      startDate,
      endDate,
      maxDateSearch
    );

    if (game_no.length !== 15 && game_no.length !== 0) {
      alert('Please input correct game number');
      return;
    }

    if (
      category_code !== belongJunket &&
      category_code !== '' &&
      game_no !== ''
    ) {
      alert('Junket Should be same with Game Number');
      return;
    }

    if (convertedDate === 'Invalid Date Range') {
      alert(
        `Invalid Search Date Range, Only Accept ${maxDateSearch} Days Range`
      );
    } else if (convertedDate === 'Invalid Search') {
      alert(t('Search start time cannot later than end.'));
    } else {
      const convertStartDate = convertToEpochTime(startDate);
      const convertEndDate = convertToEpochTime(endDate);

      await GAME_RESULT_SEARCH_GET_QUERY(
        isSearch ? 1 : curPage,
        unitPage,
        edit_stat,
        category_code,
        table_code,
        game_no,
        convertStartDate,
        convertEndDate
      )
        .then((res: AxiosResponse | any) => {
          if (res.data) {
            if (res.data.data.code === 1) {
              setData(res.data.data.data.list);
              setTableParams((prevTableParams) => ({
                ...prevTableParams,
                pagination: {
                  ...prevTableParams.pagination,
                  current: isSearch ? 1 : prevTableParams.pagination?.current,
                  total: res.data.data.data.tot_page * Number(unitPage),
                },
              }));
            } else {
              setData([]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setIsHandlePage(!isHandlePage);
    setTableParams((prevTableParams) => {
      const newTableParams = {
        ...prevTableParams,
        pagination,
      };

      if (pagination.pageSize !== prevTableParams.pagination?.pageSize) {
        setData([]);
      }

      return newTableParams;
    });
  };

  useEffect(() => {
    handleSubmit(() => onSubmit(false))();
  }, [isHandlePage, isUpdated]);

  useEffect(() => {
    routeTitleVar(String(t('Game Result Change')));
  }, [selectedLang]);

  return (
    <>
      <div className='p-6 rounded-lg shadow-lg bg-white'>
        <form onSubmit={handleSubmit(() => onSubmit(true))}>
          <div className='flex flex-wrap justify-end items-center gap-3 mb-5'>
            <div className='flex flex-row gap-3 w-full sm:w-auto justify-between'>
              <div className='form-group w-full'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Junket')}
                </div>
                <select
                  className='forminput'
                  value={selectedJunket}
                  onChange={(e) => {
                    setSelectedJunket(e.target.value);
                    setSelectedGameTable('');
                  }}
                >
                  <option value=''>{t('Entire')}</option>
                  {junketLists.map((junket) => (
                    <option key={junket.id} value={junket.id}>
                      {junket.name.charAt(0).toUpperCase() +
                        junket.name.slice(1).toLowerCase()}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group w-full'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Table Number')}
                </div>
                <select
                  className='forminput'
                  value={selectedGameTable}
                  onChange={(e) => setSelectedGameTable(e.target.value)}
                >
                  <option value=''>{t('Entire')}</option>
                  {filteredGameTableList.map((table) => (
                    <option key={table.id} value={table.table_code}>
                      {table.table_code}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-group w-full'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Status')}
                </div>
                <select
                  {...register('edit_stat')}
                  className='forminput'
                  placeholder='어카운트 입력'
                >
                  <option value=''>{t('Entire')}</option>
                  <option value='N'>{t('Normal')}</option>
                  <option value='M'>{t('Modified')}</option>
                  <option value='C'>{t('Cancel')}</option>
                </select>
              </div>
            </div>

            <div className='flex flex-col sm:flex-row gap-3 w-full sm:w-auto'>
              {/* <div className='form-group w-full'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Proposal Number')}
                </div>
                <input
                  {...register('proposal_num')}
                  type='text'
                  className='forminput'
                  placeholder={String(t('Enter Proposal Number'))}
                />
              </div> */}
              <div className='form-group w-full'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Game Number')}
                </div>
                <input
                  {...register('game_no')}
                  type='text'
                  className='forminput'
                  placeholder={String(t('Enter Game Number'))}
                />
              </div>
              <div className='flex w-full flex-col items-start md:pt-0'>
                <span className='mb-2'>{t('From')}:</span>
                <div className='customDatePickerWidth'>
                  <DatePicker
                    className='forminput pr-7'
                    selected={startDate}
                    onChange={(date: Date) => setStartDate(date)}
                    isClearable
                    showTimeSelect
                    timeFormat='HH:mm'
                    timeIntervals={60}
                    timeCaption='Hour'
                    dateFormat={'yyyy/MM/dd HH:mm'}
                    placeholderText={String(t('Starting Point'))}
                  />
                </div>
              </div>

              <div className='flex w-full flex-col items-start md:pt-0'>
                <span className='mb-2'>{t('To')}:</span>
                <div className='customDatePickerWidth'>
                  <DatePicker
                    className='forminput pr-7'
                    selected={endDate}
                    onChange={(date: Date) => setEndDate(date)}
                    isClearable
                    showTimeSelect
                    timeFormat='HH:mm'
                    timeIntervals={60}
                    timeCaption='Hour'
                    dateFormat={'yyyy/MM/dd HH:mm'}
                    placeholderText={String(t('End Point'))}
                  />
                </div>
              </div>
            </div>
            <div className='flex flex-row gap-3 w-full sm:w-auto pt-6'>
              {/* <div className='flex w-full sm:w-auto'>
                <select {...register('date_type')} className='forminput'>
                  <option value='E'>{t('Settlement Base')}</option>
                  <option value='S'>{t('Starting Base')}</option>
                </select>
              </div> */}

              <div className='flex'>
                <ButtonCustom
                  canClick={!isSubmitting}
                  actionText={t('Search')}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className='border-l border-r rounded-md mt-5 shadow-lg'>
        <div className='flex justify-between p-4 rounded-md'>
          <div>
            <h1 className=' text-lg font-semibold'>{t('Game Result')}</h1>
            <p className=' text-xs'>{t('List & Change')}</p>
          </div>
        </div>

        <GameResultList
          data={data}
          tableParams={tableParams}
          handleTableChange={handleTableChange}
          loading={isSubmitting}
        />
      </div>
    </>
  );
};
