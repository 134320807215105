import { useReactiveVar } from '@apollo/client';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import {
  IBetLimitResponse,
  LIMITS_GET_QUERY,
  USER_TLIMITS_GET_QUERY,
} from '../../../../common/api/queries/betlimit_query';
import {
  isUpdatedVar,
  langVar,
  limitData,
  routeTitleVar,
  tBetLimitList,
  uTBetLimitList,
} from '../../../../common/apollo';
import { TableBetLimitAddModal } from './modal/tbetLimitAddModal';
import { TableBetLimitModModal } from './modal/tbetlimitModModal';
import { useTranslation } from 'react-i18next';

export const TableBetLimitList = () => {
  const { t } = useTranslation(['page']);
  const selectedLang = useReactiveVar(langVar);
  const [regModal, setRegModal] = useState(false);
  const [modModal, setModModal] = useState(false);
  const [data, setData] = useState<(IBetLimitResponse | undefined)[]>([]);

  const tLimitData = useReactiveVar(tBetLimitList);
  const userTLimitData = useReactiveVar(uTBetLimitList);
  const isUpdated = useReactiveVar(isUpdatedVar);

  useReactiveVar(limitData);
  useReactiveVar(routeTitleVar);

  const onClickRegModal = () => {
    setRegModal(!regModal);
  };

  const onClickUpdateModal = (index: number) => {
    setModModal(!modModal);
    limitData(data[index]);
  };

  const getLimitList = () => {
    LIMITS_GET_QUERY()
      .then((res: AxiosResponse | any) => {
        if (res.data.data[0].code === 1) {
          tBetLimitList(res.data.data[0].data.list);
          const fetchedTLimitData: IBetLimitResponse[] =
            res.data.data[0].data.list;
          return fetchedTLimitData;
        }
      })
      .then((fetchedTLimitData) => {
        if (fetchedTLimitData && fetchedTLimitData.length > 0) {
          USER_TLIMITS_GET_QUERY()
            .then((res: AxiosResponse | any) => {
              if (res.data.data[0].code === 1) {
                uTBetLimitList(res.data.data[0].data.list);
                const fetchedUTLimitData: IBetLimitResponse[] =
                  res.data.data[0].data.list;
                setData(fetchedTLimitData);
                return { fetchedUTLimitData, fetchedTLimitData };
              }
            })
            .then((fetchedData) => {
              if (
                fetchedData?.fetchedUTLimitData &&
                fetchedData?.fetchedUTLimitData.length > 0
              ) {
                const processedTBetLimistList =
                  fetchedData.fetchedTLimitData.map((item) => {
                    for (let elem of fetchedData.fetchedUTLimitData) {
                      if (elem.id === item.id) {
                        item.ut_bp_min = elem.bp_min;
                        item.ut_pair_min = elem.pair_min;
                        item.ut_tie_min = elem.tie_min;
                        return item;
                      }
                    }
                  });
                if (processedTBetLimistList.length > 0) {
                  setData(processedTBetLimistList);
                }
              }
            });
        }
      });
  };

  const columnHelper = createColumnHelper<IBetLimitResponse | undefined>();
  const columns = [
    columnHelper.accessor('id', {
      header: String(t('Limit ID')),
    }),
    columnHelper.accessor('name', {
      header: String(t('Limit Name')),
    }),
    columnHelper.accessor('bp_min', {
      header: String(t('User B.P Min')),
    }),
    columnHelper.accessor('bp_max', {
      header: String(t('B.P Max')),
    }),
    columnHelper.accessor('pair_min', {
      header: String(t('User Pair Min')),
    }),
    columnHelper.accessor('pair_max', {
      header: String(t('Pair Max')),
    }),
    columnHelper.accessor('tie_min', {
      header: String(t('User Tie Min')),
    }),
    columnHelper.accessor('tie_max', {
      header: String(t('Tie Up')),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    routeTitleVar(String(t('Limit Setting (Table)')));
  }, [selectedLang]);

  useEffect(() => {
    setModModal(false);
    setRegModal(false);
    getLimitList();
  }, [isUpdated]);

  return (
    <div>
      <div className='border-r border-l rounded-md shadow-lg mt-5'>
        <div className='flex flex-row justify-between items-center border-b'>
          <div className='py-3 px-6'>
            <div className='text-gray-500 font-semibold text-lg'>
              {t('Limit List')}
            </div>
            <div className='text-gray-400'>{t('Add & Edit')}</div>
          </div>
          <button className='btnmodal mr-3' onClick={onClickRegModal}>
            {t('Add A Limit')}
          </button>
        </div>
        <div className='flex flex-col bg-white'>
          <div className='overflow-x-auto'>
            <div className='min-w-full'>
              <table className='w-full'>
                <thead className='border-b bg-[#245c81]'>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className=''>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          className='text-sm font-bold text-white px-2 py-2 text-left whitespace-nowrap'
                        >
                          <div className='text-center'>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </div>
                        </th>
                      ))}
                      <th
                        style={{ cursor: 'default' }}
                        className='text-sm font-bold text-white px-2 py-2 text-center'
                      >
                        {t('Edit')}
                      </th>
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row, index) => (
                    <tr
                      key={row.id}
                      className={`bg-white border-b transition duration-300 ease-in-out hover:bg-gray-300 whitespace-nowrap`}
                    >
                      {row.getVisibleCells().map((cell) => {
                        if (
                          cell.column.columnDef.header === t('User B.P Min')
                        ) {
                          return (
                            <td
                              key={cell.id}
                              className='px-4 py-4 text-xs text-center font-medium text-gray-900'
                            >
                              {row.original?.ut_bp_min.toLocaleString() +
                                ' / ' +
                                row.original?.bp_min.toLocaleString()}
                            </td>
                          );
                        } else if (
                          cell.column.columnDef.header === t('B.P Max')
                        ) {
                          return (
                            <td
                              key={cell.id}
                              className='px-4 py-4 text-xs text-center font-medium text-gray-900'
                            >
                              {cell.row.original?.bp_max.toLocaleString()}
                            </td>
                          );
                        } else if (
                          cell.column.columnDef.header === t('User Pair Min')
                        ) {
                          return (
                            <td
                              key={cell.id}
                              className='px-4 py-4 text-xs text-center font-medium text-gray-900'
                            >
                              {
                                // row.original?.ut_pair_min +
                                //   ' / ' +
                                row.original?.pair_min.toLocaleString()
                              }
                            </td>
                          );
                        } else if (
                          cell.column.columnDef.header === t('Pair Max')
                        ) {
                          return (
                            <td
                              key={cell.id}
                              className='px-4 py-4 text-xs text-center font-medium text-gray-900'
                            >
                              {cell.row.original?.pair_max.toLocaleString()}
                            </td>
                          );
                        } else if (
                          cell.column.columnDef.header === t('User Tie Min')
                        ) {
                          return (
                            <td
                              key={cell.id}
                              className='px-4 py-4 text-xs text-center font-medium text-gray-900'
                            >
                              {
                                // row.original?.ut_tie_min +
                                //   ' / ' +
                                row.original?.tie_min.toLocaleString()
                              }
                            </td>
                          );
                        } else if (
                          cell.column.columnDef.header === t('Tie Up')
                        ) {
                          return (
                            <td
                              key={cell.id}
                              className='px-4 py-4 text-xs text-center font-medium text-gray-900'
                            >
                              {cell.row.original?.tie_max.toLocaleString()}
                            </td>
                          );
                        } else {
                          return (
                            <td
                              key={cell.id}
                              className='px-4 py-4 text-xs text-center font-medium text-gray-900'
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        }
                      })}
                      <td className='p-2 flex items-center justify-center'>
                        <div className='p-2 hover:bg-gray-100 rounded cursor-pointer'>
                          <AiFillEdit
                            className='text-base'
                            onClick={() => onClickUpdateModal(index)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {regModal ? <TableBetLimitAddModal setRegModal={setRegModal} /> : null}
      {modModal ? <TableBetLimitModModal setModModal={setModModal} /> : null}
    </div>
  );
};
