import React from 'react';
import { ScGameBetSearch } from './scGameBetSearch';

export const ScGameBet = () => {
  return (
    <div>
      <ScGameBetSearch />
    </div>
  );
};
