import React from 'react';
import { DummyUserList } from './dmmyUserSetList';

export const DummyUser = () => {
  return (
    <div>
      <DummyUserList />
    </div>
  );
};
