import dayjs from 'dayjs';

export const DateValidationAndConverter = (
  dateFrom: Date | null,
  dateTo: Date | null,
  dayMaxSearch: number
) => {
  let initFromDate = new Date();
  let initToDate = new Date();

  if (dateFrom !== null) {
    initFromDate = new Date(dateFrom);
  }
  if (dateTo !== null) {
    initToDate = new Date(dateTo);
  }
  if (dateFrom !== null && dateTo === null) {
    initToDate = dayjs(dateFrom).add(dayMaxSearch, 'day').toDate();
  }
  if (dateTo !== null && dateFrom === null) {
    initFromDate = dayjs(dateTo).subtract(dayMaxSearch, 'day').toDate();
  }
  if (dateFrom === null && dateTo === null) {
    return { dateFrom, dateTo };
  }

  if (dayMaxSearch !== null && dayMaxSearch !== undefined) {
    const fromDate = new Date(initFromDate);
    const toDate = new Date(initToDate);
    const timeDiff = toDate.getTime() - fromDate.getTime();
    const dayDifference = Math.floor(timeDiff / (1000 * 3600 * 24));
    const hourDifference = Math.floor(
      (timeDiff % (1000 * 3600 * 24)) / (1000 * 60 * 60)
    );
    const minuteDifference = Math.floor(
      (timeDiff % (1000 * 60 * 60)) / (1000 * 60)
    );

    if (
      dayDifference > dayMaxSearch ||
      (dayDifference === dayMaxSearch &&
        (hourDifference > 0 || minuteDifference > 0))
    ) {
      return 'Invalid Date Range';
    }
  }

  if (dateFrom !== null && dateTo !== null && dateFrom > dateTo) {
    return 'Invalid Search';
  }

  const convertToEpochTime = (date: Date | null): number | null => {
    if (date === null) {
      return null;
    }
    const milliseconds = date.getTime();
    const newMilliseconds = milliseconds - (milliseconds % 60000);
    const newSeconds = Math.floor(newMilliseconds / 1000);

    return newSeconds;
  };

  const fromDate = new Date(initFromDate);
  const toDate = new Date(initToDate);

  const fromEpoch = convertToEpochTime(fromDate);
  const toEpoch = convertToEpochTime(toDate);

  return { fromEpoch, toEpoch };
};
