import React from 'react';
import { UserBetLimitList } from './userBetLimitList';

export const UserBetLimit = () => {
  return (
    <div>
      <UserBetLimitList />
    </div>
  );
};
