import React from 'react';
import { TableBetLimitList } from './tableBetLimitList';

export const TableBetLimit = () => {
  return (
    <div>
      <TableBetLimitList />
    </div>
  );
};
