import { AxiosResponse } from 'axios';
import { apiClient } from '../apiConfig';
import qs from 'qs';

export interface IPlayerCardListRes {
  seq: number;
  bet: string;
  bscore: number;
  pscore: string;
  bet_result_id: number;
  crt_date: number;
  p1card: string;
  p2card: number;
  p3card: number;
  b1card: string;
  b2card: number;
  b3card: number;
  stat: string;
}

export const PLAYCARD_LIST_GET_QUERY = async (id: number) => {
  try {
    const queryParams = qs.stringify({
      id: id,
    });
    const response: AxiosResponse = await apiClient.get(
      `api/game-result-detail?${queryParams}`
    );
    return response;
  } catch (err) {
    return err;
  }
};
