import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isUpdatedVar } from '../../../../common/apollo';
import { useReactiveVar } from '@apollo/client';
import { GrClose } from 'react-icons/gr';
import { ButtonCustom } from '../../../../components/button';
import { ICurrencyResponse } from '../../../../common/api/queries/currency_query';
import {
  CHIP_DELETE_POST,
  CHIP_EDIT_POST,
  IChipForm,
} from '../../../../common/api/mutations/chip_mutation';
import { CHIP_DEFAULT_GET_QUERY } from '../../../../common/api/queries/chip_query';

interface IModalSetupProps {
  setOpenSetup: React.Dispatch<React.SetStateAction<boolean>>;
  chipData: ICurrencyResponse | null;
}

export const ChipModModal: React.FC<IModalSetupProps> = ({
  setOpenSetup,
  chipData,
}) => {
  const { register, getValues, handleSubmit, control } = useForm<IChipForm>({
    mode: 'onChange',
  });
  const { isSubmitting } = useFormState({ control });
  const { t } = useTranslation(['page']);
  const isUpdated = useReactiveVar(isUpdatedVar);
  const [chipVal, setChipVal] = useState<number[]>([]);
  const [chipUpdate, setChipUpdate] = useState<number[]>([]);
  const [ratioVal, setRatioVal] = useState(chipData?.rate ? chipData?.rate : 1);
  const [checkedValues, setCheckedValues] = useState<number[]>(chipVal);

  const clickModalClose = () => {
    setOpenSetup((current) => !current);
  };

  const getChipValues = () => {
    CHIP_DEFAULT_GET_QUERY(chipData?.currency)
      .then((res: AxiosResponse | any) => {
        if (res.data.data.code === 1) {
          setChipVal(res.data.data.data.vals);
          setChipUpdate(res.data.data.data.vals);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getChipValues();
  }, []);

  const handleCheckboxChange = (option: number) => {
    const updatedChipVal = chipVal.includes(option)
      ? chipVal.filter((value) => value !== option)
      : [...chipVal, option];

    setChipVal(updatedChipVal);
  };

  const checkboxOptions = [
    5 * ratioVal,
    25 * ratioVal,
    50 * ratioVal,
    100 * ratioVal,
    250 * ratioVal,
    500 * ratioVal,
    1000 * ratioVal,
    2500 * ratioVal,
    5000 * ratioVal,
    10000 * ratioVal,
    25000 * ratioVal,
    50000 * ratioVal,
    100000 * ratioVal,
    250000 * ratioVal,
    500000 * ratioVal,
    1000000 * ratioVal,
    5000000 * ratioVal,
  ];

  const onSubmit = async () => {
    let alertMsg = false;

    const currency = chipData?.currency;
    const data = {
      currency: currency,
      vals: chipVal.filter((option) => !chipUpdate.includes(option)),
    };
    if (data.vals.length > 0) {
      await CHIP_EDIT_POST(data).then((res: AxiosResponse | any) => {
        if (res.data.data.code === 1) {
          alertMsg = true;
        }
      });
    }

    const missingValues = chipUpdate.filter(
      (option) => !chipVal.includes(option)
    );
    const dataDel = {
      currency: currency,
      vals: missingValues,
    };

    if (missingValues.length > 0) {
      await CHIP_DELETE_POST(dataDel).then((res: AxiosResponse | any) => {
        if (res.data.data.code === 1) {
          alertMsg = true;
        }
      });
    }

    if (alertMsg) {
      alert('Currency Registered');
      isUpdatedVar(!isUpdated);
      setOpenSetup(false);
    } else {
      alert('No changes detected. Please make some changes to update.');
    }
  };

  return (
    <div
      className='box-border fixed top-0 right-0 bottom-0 left-0 z-40 overflow-auto outline-0'
      style={{ zIndex: '910' }}
    >
      <div className='fixed w-full h-full bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center overflow-y-auto'>
        <div className='border w-4/5 bg-white rounded-md lg:col-span-2 p-5'>
          <div className='font-medium text-lg px-6 py-3 border-b flex flex-row justify-between'>
            <div className='text-blue-500'>{t('Chip Setup')}</div>
            <GrClose
              className='mt-1 cursor-pointer'
              onClick={clickModalClose}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-4 my-10'>
              <div className='flex flex-wrap gap-5'>
                <div className='form-group w-1/4'>
                  <div className='mb-2 text-gray-500  whitespace-nowrap'>
                    {t('Currency')}
                  </div>
                  <input
                    type='text'
                    readOnly
                    className='forminput bg-gray-100 focus:bg-gray-100 focus:border-gray-300'
                    defaultValue={chipData?.currency}
                  />
                </div>
                <div className='form-group w-1/4'>
                  <div className='mb-2 text-gray-500 whitespace-nowrap'>
                    {t('Ratio')}
                  </div>
                  <input
                    type='text'
                    readOnly
                    className='forminput bg-gray-100 focus:bg-gray-100 focus:border-gray-300'
                    defaultValue={chipData?.rate}
                  />
                </div>
              </div>
              <div>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Chips List')}
                </div>
                <div className='flex flex-wrap w-full'>
                  {checkboxOptions.map((option) => (
                    <div key={option} className='w-full md:w-1/5 pr-2 pb-2'>
                      <label className='forminput'>
                        <input
                          type='checkbox'
                          className='mr-2'
                          checked={chipVal.includes(option)}
                          onChange={() => handleCheckboxChange(option)}
                        />
                        {ratioVal >= 1
                          ? option.toLocaleString()
                          : option.toLocaleString()}
                      </label>
                    </div>
                  ))}

                  {/* {checkboxOptions.map((option) => (
                    <div key={option} className='w-full md:w-1/5 pr-2 pb-2'>
                      <label className='forminput'>
                        <input
                          type='checkbox'
                          className='mr-2'
                          checked={
                            checkedValues.includes(option) ||
                            chipVal.includes(option)
                          }
                          onChange={() => handleCheckboxChange(option)}
                        />
                        {ratioVal >= 1
                          ? (option * ratioVal).toLocaleString()
                          : option.toLocaleString()}
                      </label>
                    </div>
                  ))} */}

                  {/* OLD WORKING CODE */}
                  {/* {checkboxOptions.map((option) => (
                    <div key={option} className='w-full md:w-1/5 pr-2 pb-2'>
                      <label className='forminput'>
                        <input
                          type='checkbox'
                          className='mr-2'
                          checked={checkedValues.includes(option)}
                          onChange={() => handleCheckboxChange(option)}
                        />
                        {ratioVal >= 1
                          ? (option * ratioVal).toLocaleString()
                          : option.toLocaleString()}
                      </label>
                    </div>
                  ))} */}

                  {/* USING USEFORM */}
                  {/* {checkboxOptions.map((option) => (
                    <div key={option} className='w-full md:w-1/5 pr-2 pb-2'>
                      <label className='forminput'>
                        <input
                          type='checkbox'
                          className='mr-2'
                          {...register('rate', {
                            required: 'Please select at least one option.',
                          })}
                          value={ratioVal !== 0 ? option * ratioVal : option}
                        />
                        {ratioVal !== 0 ? option * ratioVal : option}
                      </label>
                    </div>
                  ))} */}
                </div>
              </div>
              <ButtonCustom
                canClick={!isSubmitting}
                actionText={t('화폐 등록')}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
