import * as dotenv from 'dotenv';

export const appConfig = {
  development: {
    apiUrl: process.env.REACT_APP_URL,
    apiDetailUrl: process.env.REACT_APP_DETAILS_URL,
    apiDetailKey: process.env.REACT_APP_DETAILS_AUTH_KEY,
    apiDetailId: process.env.REACT_APP_DETAILS_AUTH_ID,
    apiLocalUrl: process.env.REACT_APP_LOCAL_URL,
  },
};
