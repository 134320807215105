import { gql } from '@apollo/client';
import { AxiosResponse } from 'axios';
import { apiClient } from '../apiConfig';

export interface ICurrencyRes {
  currency_code: string;
  currency_name: string;
}

export interface ICurrencyResponse {
  currency: string;
  rate: number;
}

export const CURRENCIES_GET_QUERY = gql`
  query CurrenciesGetQuery {
    currenciesGet @rest(type: "data", path: "/account/currency") {
      data
    }
  }
`;

export const CURRENCY_GET_QUERY = async () => {
  try {
    const response: AxiosResponse = await apiClient.get('api/get-exchange');
    return response;
  } catch (err) {
    return err;
  }
};
