import React, { useState } from 'react';
import { Button, Popconfirm, Space, Table, Tag } from 'antd'; // Import any necessary dependencies
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { GameResultModModal } from './modal/gameResultModModal';
import { IGameResultRes } from '../../common/api/queries/game_query';
import { gameResultData, isUpdatedVar } from '../../common/apollo';
import { GAME_RESULT_DETAIL_PATCH } from '../../common/api/mutations/playCard_mutation';
import { AxiosResponse } from 'axios';
import { useReactiveVar } from '@apollo/client';
import { betImageMapping } from '../../components/betComponents/bet-images';

interface TableListProps {
  data: IGameResultRes[] | undefined;
  tableParams: {
    pagination: TablePaginationConfig;
  };
  handleTableChange: (pagination: TablePaginationConfig) => void;
  loading: boolean;
}

const GameResultList: React.FC<TableListProps> = ({
  data,
  tableParams,
  handleTableChange,
  loading,
}) => {
  const { t } = useTranslation(['page']);

  const [modModal, setModModal] = useState(false);
  const [gameNum, setGameNum] = useState(0);
  const isUpdated = useReactiveVar(isUpdatedVar);

  const onClickModModal = (data: IGameResultRes) => {
    setModModal(!modModal);
    setGameNum(data.id);
    gameResultData(data);
  };

  const columns: ColumnsType<IGameResultRes> = [
    {
      title: String(t('No.')),
      dataIndex: 'id',
      fixed: 'left',
      ellipsis: true,
      onCell: (record, index) => ({
        style: {
          background:
            record.stat === 'C' || record.stat === 'CR'
              ? '#fee2e2'
              : // : (index as number) % 2 === 0
                // ? '#f8f9fa'
                '',
        },
      }),
    },
    {
      title: String(t('Game Number')),
      dataIndex: 'game_no',
      ellipsis: true,
    },
    {
      title: String(t('Junket Name')),
      dataIndex: 'category_code',
      ellipsis: true,
    },
    {
      title: String(t('Table Number')),
      dataIndex: 'table_code',
      ellipsis: true,
    },
    {
      title: String(t('Game Type')),
      dataIndex: 'game_type',
      ellipsis: true,
    },
    {
      title: String(t('Start Time')),
      dataIndex: 'start_date',
      ellipsis: true,
    },
    {
      title: String(t('Settlement Time')),
      dataIndex: 'end_date',
      ellipsis: true,
    },
    {
      title: String(t('Change Type')),
      dataIndex: 'edit_stat',
      ellipsis: true,
    },
    {
      title: String(t('Result')),
      dataIndex: 'bet',
      ellipsis: true,
      render: (bet: string) => (
        <div className='flex items-center justify-center'>
          <img
            style={{ height: '20px', opacity: '0.8' }}
            src={
              bet === '1'
                ? betImageMapping['P']
                : bet === '2'
                ? betImageMapping['B']
                : bet === '3'
                ? betImageMapping['T']
                : ''
            }
          />
        </div>
      ),
      // render: (bet: string) => (
      //   <div className='flex justify-center items-center'>
      //     <Tag
      //       color={bet === '1' ? 'blue' : bet === '2' ? 'red' : 'green'}
      //       style={{ width: '35px', textAlign: 'center' }}
      //       key={bet}
      //     >
      //       {bet === '1' ? 'P' : bet === '2' ? 'B' : bet === '3' ? 'T' : null}
      //     </Tag>
      //   </div>
      // ),
    },
    {
      title: String(t('Action')),
      dataIndex: '',
      key: 'x',
      ellipsis: true,
      render: (record) => (
        <Space>
          <Button
            type='primary'
            onClick={() => onClickModModal(record)}
            style={{ padding: '0px 5px', height: '28px' }}
          >
            {t('Edit')}
          </Button>

          <Popconfirm
            title={String(t('History Cancellation'))}
            description={String(
              t('Are You Sure You Want To Cancel Your Game History?')
            )}
            onConfirm={() => cancelHistory(record)}
            okText='Yes'
            cancelText='No'
          >
            <Button danger style={{ padding: '0px 5px', height: '28px' }}>
              {t('Cancel')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const cancelHistory = async (selData: IGameResultRes) => {
    const type = 'CANCEL';
    const p1card = '';
    const p2card = '';
    const p3card = null;
    const b1card = '';
    const b2card = '';
    const b3card = null;

    const data = {
      id: Number(selData.id),
      type: type,
      p1card: p1card,
      p2card: p2card,
      p3card: p3card,
      b1card: b1card,
      b2card: b2card,
      b3card: b3card,
      game_no: selData.game_no,
    };

    GAME_RESULT_DETAIL_PATCH(data)
      .then(async (res: AxiosResponse | any) => {
        if (res.data) {
          // const resData: IGameResultCancelRes = res.data;
          if (res.data.data.code === 1) {
            // if (res.data.data.data.list.length > 0) {
            //   await BULK_BET_RESULT_CANCEL(resData.data.data);
            // }
            alert(`UPDATE TYPE CANCEL`);
            isUpdatedVar(!isUpdated);
          } else {
            alert(`Request Failed Please Try Again`);
          }
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <>
      <Table
        rowClassName={(record, index) =>
          record.stat === 'C' || record.stat === 'CR'
            ? 'bg-[#fee2e2]'
            : // : (index as number) % 2 === 0
              // ? 'bg-[#f8f9fa]'
              ''
        }
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        size='small'
        bordered={true}
        pagination={{
          ...tableParams.pagination,
          position: ['bottomCenter'],
        }}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: true }}
      />
      {modModal ? (
        <GameResultModModal setModal={setModModal} gameNumber={gameNum} />
      ) : null}
    </>
  );
};

export default GameResultList;
