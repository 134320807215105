import { useReactiveVar } from '@apollo/client';
import axios, { AxiosInstance } from 'axios';
import { error } from 'console';
import { isLoggedInVar } from '../apollo';
import { appConfig } from '../appConfig';

const env = 'development';
const { apiUrl, apiDetailUrl, apiLocalUrl, apiDetailId, apiDetailKey } =
  appConfig[env];

export const apiClient: AxiosInstance = axios.create({
  baseURL: apiUrl,
});

export const apiDetailClient: AxiosInstance = axios.create({
  baseURL: apiDetailUrl,
  headers: {
    'X-Auth-Key': `${apiDetailKey}`,
    'X-Auth-Id': `${apiDetailId}`,
  },
});

export const apiLocalClient: AxiosInstance = axios.create({
  baseURL: apiLocalUrl,
});

apiClient.defaults.headers.common['X-CSRF-TOKEN'];
apiDetailClient.defaults.headers.common['X-CSRF-TOKEN'];

// apiClient.interceptors.request.use(
//   (response) => {
//     console.log(response);
//     return response;
//   },
//   (error) => {
//     console.log('REQUEST ERROR OCCURED');
//     console.log(error);
//     return Promise.reject(error);
//   }
// );

// apiClient.interceptors.response.use(
//   (response) => {
//     console.log(response);
//     return response;
//   },
//   (error) => {
//     console.log('RESPONSE ERROR OCCURED');
//     if (error.response.data.message === 'Unauthorized') {
//       useReactiveVar(isLoggedInVar);
//       isLoggedInVar(false);
//     }
//     return Promise.reject(error);
//   }
// );

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 408 ||
      error.response.status === 403 ||
      error.response.data.message === 'Forbidden' ||
      error.response.data.message === 'Unauthorized'
    ) {
      sessionStorage.clear();
      window.location.href = '/';
    }
    return error;
  }
);

apiDetailClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 408 ||
      error.response.status === 403 ||
      error.response.data.message === 'Forbidden' ||
      error.response.data.message === 'Unauthorized'
    ) {
      sessionStorage.clear();
      window.location.href = '/';
    }
    return error;
  }
);

apiClient.defaults.withCredentials = true;
apiClient.defaults.xsrfCookieName = 'Cookie';
apiClient.defaults.headers.common['Cookie'];

apiDetailClient.defaults.withCredentials = true;
apiDetailClient.defaults.xsrfCookieName = 'Cookie';
apiDetailClient.defaults.headers.common['Cookie'];
