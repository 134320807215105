export const betStatsCombi = [
  'DR',
  'CA',
  'CU',
  'CR',
  'CS',
  'LO',
  'WI',
  'WP',
  'WA',
  'WS',
];

export const betStatsMultiLang: Record<string, string> = {
  'DR': 'TIE',
  'CA': 'ADMIN CANCEL',
  'CU': 'USER CANCEL',
  'CR': 'RESULT CANCEL',
  'CS': 'SYSTEM CANCEL',
  'LO': 'LOSE',
  'WI': 'WIN',
  'WP': 'PHONE BET',
  'WA': 'BEFORE PROCESS',
  'WS': 'SPEED BET',
};
